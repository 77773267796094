import React from 'react';
import {Container} from '@mui/material';
import {useEffect} from 'react';
import DropFooterNav from '../components/nav/Drop_footer_nav';


const Home = () => {
    
    useEffect(() => {
        window.location.replace('https://web.drop.fish');
    },[]);
    
}

export default Home;