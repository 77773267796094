import React, {useEffect, useState} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {Card, CardActions, CardContent, CardMedia, Typography, Button, MenuItem, Select} from '@mui/material';
import axios from 'axios';
import moment, { utc } from 'moment';
import NavStyle from '../nav/Nav_style.module.scss';
import SuccessModal from '../modal/Modal_success';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DropImg from '../../assets/Image-77.png'
import animIcon from '../../assets/drop-load.svg';
import copy from 'copy-to-clipboard';

const RewardCard = (props) =>{
    const [openAction, setOpenAction] = useState(false);
    const navigate     = useNavigate();
    const user_id      = localStorage.getItem('nift_user_id');
    const temp_user_id = localStorage.getItem('lh_trmp_user_id');
    const [dropLoad, setDropLoad] = useState(false);
    const reader       = new FileReader();
    const location     = useLocation();

    let utcStartDate = moment.utc(props.drop.start);
    let utcEndDate   = moment.utc(props.drop.end);

    if(utcStartDate.isValid()){
        props.drop.start = utcStartDate.local().format('MM/DD/YYYY h:mm A');
    }

    if(utcEndDate.isValid())
        props.drop.end = utcEndDate.local().format('MM/DD/YYYY h:mm A');

    const handleClose = () => {
        setOpenAction(false);
      };
    
      const handleOpen = () => {
        setOpenAction(true);
      };

    const handleChange = (e,rewardId) => {
        
        if(e.target.value === 1){
            copy('https://www.drop.fish/'+props.drop.id);
        }

        if(e.target.value === 2){
            props.setOpenSuccess(true);
            getEditDrop(rewardId)
            //navigate('/reward',{state:{rewardId:rewardId}});
        }

        if(e.target.value === 3){
            //console.log('View')
            navigate('/tank');
        }

        if(e.target.value === 4){
            //console.log('View')
            props.setEndDrop(rewardId);
            props.setOpenWarning(true);
        }
    }

    const getDropStatus = async (drop_id) => {
        const access_token = localStorage.getItem("lh_access_token");
        const url = 'https://api.drop.fish/api-flow/drop-status?dt='+drop_id+'&access_token='+access_token;
        //const url = 'https://api.drop.fish/api-flow/drop-status?dt='+drop_id;
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.get(url, config).then(function (response) {
            if(response.data.message == 'success'){
                props.drop.status = 'success';
                setDropLoad(true);
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    getDropStatus(drop_id);
                });
            }
            else if(location.pathname === '/drop') {
                setTimeout(()=>{
                    getDropStatus(drop_id);
                },10000);
            }
        });
    }

    const getEditDrop = async (drop_text) => {
        const access_token = localStorage.getItem("lh_access_token");
        //const url = 'http://localhost/lighthouseapi/api-flow/my-edit-drop?dt='+drop_text+'&user_id='+user_id+'&temp_user_id='+temp_user_id+'&access_token='+access_token;
        const url = 'https://api.drop.fish/api-flow/my-edit-drop?dt='+drop_text+'&user_id='+user_id+'&temp_user_id='+temp_user_id+'&access_token='+access_token;
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.get(url, config).then(function (response) {
            
            if(response.data.data.setup_data){

                if(response.data.message == 'success'){
                    localStorage.removeItem("lh_unique_csv");
                    localStorage.removeItem("lh_setupform_data");
                    localStorage.removeItem("lh_logo_image");
                    localStorage.removeItem("lh_background_image");
                    localStorage.removeItem("lh_emails_csv");
                    localStorage.removeItem("lh_form_rewardImage");
                    localStorage.removeItem("lh_bg_size");
                    localStorage.removeItem("lh_criteriaform_data");
                    localStorage.removeItem("lh_bg_name");
                    localStorage.removeItem("lh_edit_drop");

                    let rData  = response.data.data.setup_data;
                    localStorage.setItem('lh_edit_drop',drop_text);
                    
                    if(rData) {

                        if(rData.liveDate){
                            const utcLiveDate = moment.utc(rData.liveDate,'MM/DD/YYYY h:mm A');
                            rData.liveDate = utcLiveDate.local().format('MM/DD/YYYY h:mm A');
                        }
        
                        if(rData.liveTime) {
                            const utcLiveTime   = moment.utc(rData.liveTime,'MM/DD/YYYY h:mm A');
                            rData.liveTime = utcLiveTime.local().format('MM/DD/YYYY h:mm A');
                        }
                        
                        localStorage.setItem('lh_setupform_data',JSON.stringify(rData));
                    }

                    if(response.data.data.criteria_data)
                        localStorage.setItem('lh_criteriaform_data',JSON.stringify(response.data.data.criteria_data));

                    if(response.data.data.rewardImage)
                        localStorage.setItem('lh_form_rewardImage', response.data.data.rewardImage);

                    if(response.data.data.backgroundImage) {
                        localStorage.setItem('lh_background_image', response.data.data.backgroundImage);
                        localStorage.setItem('lh_bg_name', response.data.data.bgi_name);
                        localStorage.setItem('lh_bg_size', response.data.data.bgi_size);
                    }

                    if(response.data.data.countdown_time)
                        localStorage.setItem('lh_countdown_time', response.data.data.countdown_time);

                    if(response.data.data.display_time)
                        localStorage.setItem('lh_display_time', response.data.data.display_time);

                    /* if(response.data.data.lh_unique_csv_file)
                        localStorage.setItem('lh_unique_csv_file',response.data.data.lh_unique_csv_file); */
                        
                    if(response.data.data.lh_unique_csv)
                        localStorage.setItem('lh_unique_csv',response.data.data.lh_unique_csv);
                        
                    if(response.data.data.lh_emails_csv)
                        localStorage.setItem('lh_emails_csv',response.data.data.lh_emails_csv);
                     
                    navigate('/reward',{state:{'action':'back-and-next'}});
                }
                else if(response.data.message == 'invalid_token'){
                    props.getOAuthToken().then(res => {
                        getEditDrop(drop_text)
                    });
                }
            }
        });
    }

    useEffect(()=>{
       if(props.drop.status == 'draft'){
          getDropStatus(props.drop.id);
       }
       else {
          setDropLoad(true);
       }

    },[props.drop]);

    return(
        <>
        <Card className='rounded-3xl bg-darckGray shadow-none'>
            <CardMedia
                className='md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem] bg-primary object-top'
                component="img"
                alt="test"
                image={props.drop.img}
            />
        <CardContent className='bg-darckGray min-h-[11.438rem] max-h-[11.438rem]'>
            <Typography gutterBottom variant="subHeader1" align='center' className='my-8 line-clamp-1' component="div">
                {(props.drop.status !== 'success')?'['+props.drop.status+'] ':''} {props.drop.title}
            </Typography>
            <Typography variant="text20" align='center' component="div" className='line-clamp-3 my-5'>
                {props.drop.text}
            </Typography>
            <Typography variant="textBase" align='center' component="div" className='line-clamp-3 my-2'>
                Start: {props.drop.start}
            </Typography>
            {utcEndDate.isValid() ?(
                <Typography variant="textBase" align='center' component="div" className='line-clamp-3'>
                    End: {utcEndDate.local().format('MM/DD/YYYY h:mm A')}
                </Typography>
            ):('')}

        </CardContent>
        <CardActions  className='bg-darckGray justify-center px-11 pb-4 pt-10'>
            <div className='w-full'>
                {dropLoad ? (
                <>
                    <Button id="menu-action" className='text-white flex rounded-xl w-full py-4 bg-primary text-base font-medium' onClick={handleOpen} >
                        <div className=' w-full h-full capitalize text-center mr-3 border-0 border-r border-r-darckGray border-solid'>Actions</div>
                        <ArrowDropDownIcon className='text-white'/>
                    </Button>
                    
                    <Select
                        labelId="menu-action-label"
                        id="menu-action"
                        label="Action"
                        aria-label='Action'
                        sx={{width:'100%',visibility:'hidden', height:0, pointerEvents:'none',top:'-0.875rem'}}
                        onChange={ e => handleChange(e,props.drop.id)}
                        value={''}
                        open={openAction}
                        onClose={handleClose}
                        data-reward-id={props.drop.id}
                    >
                        <MenuItem className={NavStyle['admin-nav-item-home']} value={1}>Copy Campaign Link</MenuItem>
                        <MenuItem className={NavStyle['admin-nav-item-home']} value={2}>Edit Drop</MenuItem>
                        {/* <MenuItem className={NavStyle['admin-nav-item-home']} value={3}>View Performance</MenuItem> */}
                        {(props.drop.status !== 'inactive')?(
                            <MenuItem className={NavStyle['admin-nav-item-home']} value={4}>End Drop</MenuItem>
                        ):('')}
                        
                    </Select>
                </>
                ):(
                <div className='flex justify-center items-center px-3 pt-3 pb-2 bg-primary rounded-xl mb-6'>
                    <div className='pr-4 text-white text-base font-medium mb-3'>Deploying Drop</div>
                    <div>
                        <img src={animIcon} className='max-w-[2rem]'/>
                    </div>
                </div>
                )}
            </div>    
        </CardActions>
        </Card>
        </>
    )
}

export default RewardCard;