import React, {useEffect, useState} from 'react';
import {AppBar, Container} from '@mui/material';
import { useLocation,useNavigate } from 'react-router-dom';
import ButtonMain from '../button/Button_main';
import SwitchInput from '../input/switch'

const FooterNav = (props) => {
    const location = useLocation();
    const [switchEnable, setSwitchEnable] = useState(false)
    const [checkCriteria,setCheckCriteria] = useState()
    const [displayCountdown,setDisplayCountdown] = useState()
    const [displayRemaining,setDisplayRemaining] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        setCheckCriteria(props.displayCheks.dec);
        setDisplayCountdown(props.displayCheks.dlc);
        setDisplayRemaining(props.displayCheks.dtr);
    },[props.displayCheks]);

    useEffect(() => {
        switch (location.pathname) {
            case "/page":
                setSwitchEnable(true);
            break;
            default:
                setSwitchEnable(false);
              break;
          }
    }, [location]);


    useEffect(() => {
       
        if(props.setDisplayChecks && (displayCountdown==true || displayCountdown==false)) {
            props.setDisplayChecks(state => ({
                ...state,
                [ 'dlc' ]: displayCountdown
            }))
        }
        
    },[displayCountdown]);    

    useEffect(() => {
        
        if(props.setDisplayChecks && (checkCriteria==true || checkCriteria==false)) {
            props.setDisplayChecks(state => ({
                ...state,
                [ 'dec' ]: checkCriteria
            }))
        }
    },[checkCriteria]);

    useEffect(() => {
        
        if(props.setDisplayChecks && (displayRemaining==true || displayRemaining==false)) {
            props.setDisplayChecks(state => ({
                ...state,
                [ 'dtr' ]: displayRemaining
            }))
        }
    },[displayRemaining]);

    return(
        <AppBar position="fixed" className='bg-darkGrey top-auto bottom-0'>
            <Container maxWidth="xl">
                <div className='py-5 flex justify-between items-center'>
                    <div className='flex'>
                    {switchEnable? (
                        <>
                            <div>
                                <SwitchInput name="eligibility_criteria" checked={checkCriteria==true?'true':'false'} setCheck={setCheckCriteria} text='Display Eligibility Criteria'/>
                            </div>
                            <div className='mx-4'>
                                <SwitchInput name="disaplay_countdown" checked={displayCountdown==true?'true':'false'} setCheck={setDisplayCountdown} text='Display Launch Countdown'/>
                            </div>
                            <div>
                                <SwitchInput name="disaplay_time" checked={displayRemaining==true?'true':'false'} setCheck={setDisplayRemaining} text='Display Time Remaining'/>
                            </div>
                        </>
                    ):''}
                    </div>
                    <div className='flex '>
                        {location.pathname != '/reward'?(
                            <div>
                                <ButtonMain text="Cancel" disabled={props.disableNext} variant={'outlineBtnWhite'} classes="mr-5" type="submit" onClick={(e) => {props.btnAction('cancel')}}/>
                                <ButtonMain text="Back" disabled={props.disableNext} variant={'outlineBtnWhite'} classes="mr-5" type="button" /*type="submit"*/ onClick={props.back}/>
                            </div>
                        ):(
                            <div>
                                <ButtonMain text="Cancel" disabled={props.disableNext} variant={'outlineBtnWhite'} classes="mr-5"  type="button" onClick={props.back}/>
                            </div>
                        )}
                        
                        {switchEnable?(
                            <ButtonMain text="PUBLISH" disabled={props.disableNext} variant={'goGreenBtn'} type="submit" onClick={(e) => {props.btnAction('next')}}/>
                        ):(
                            <ButtonMain text="Next" disabled={props.disableNext} variant={'goGreenBtn'} type="submit" onClick={(e) => {props.btnAction('next')}}/>
                        )}
                        
                    </div>
                </div>
            </Container>
        </AppBar>
    )
}

export default FooterNav;