import React, {useState, useEffect} from 'react';
import {ThemeProvider, Container, Typography} from '@mui/material';
import { useLocation,useNavigate } from "react-router-dom"
import axios from 'axios';
import { ThemeCustom} from "../styles/Theme";
import ButtonMain from '../components/button/Button_main';
import Logo from '../assets/logo-lg.svg';
import bg from '../assets/bg-2.jpg';
import LoadingModal from '../components/modal/Modal_loading';

const msg_1 = {
    'title': "This drop has ended 😿",
    'text':"Follow <a href='https://twitter.com/dropfish_dao' target='blank' class='drop_link'>@dropfish_dao</a> on Twitter to get updates about upcoming drops",
    'btnLink': '#',
    'bg_img':bg,
    'btn_text': 'Follow on Twitter'
}

const msg_2 = {
    'title':'That was fast 😳',
    'text': "We're all out of spots for this drop. Follow <a href='https://twitter.com/dropfish_dao' target='blank' class='drop_link'>@dropfish_dao</a> on Twitter to get updates about upcoming drops.",
    'btnLink': '#',
    'bg_img':bg,
    'btn_text': 'Follow on Twitter'
}

const msg_3 = {
    'title':"You're not eligible for this drop yet 😿",
    'text':"Complete all the unlock criteria to claim the drop.",
    'btnLink': '#',
    'bg_img':bg,
    'btn_text': 'View Criteria Again'
}

const msg_4 = {
    'title':"This drop is under construction or has ended 😿",
    'text':"",
    'btnLink': '#',
    'bg_img':bg,
    'btn_text': 'View Criteria Again'
}

const msg_5 = {
    'title':"This magic link has expired.😿",
    'text':"",
    'btnLink': '#',
    'bg_img':bg,
    'btn_text': 'View Criteria Again'
}

const msg_6 = {
    'title':"This drop has already been claimed.😿",
    'text':"",
    'btnLink': '#',
    'bg_img':bg,
    'btn_text': 'View Criteria Again'
}

const msg_9 = {
    'title':"Too many people are unlocking this drop right now 👀",
    'text':"@dropfish_dao will DM you as soon as we’ve checked your account against the unlock criteria.",
    'btnLink': '#',
    'bg_img':bg,
    'btn_text': 'Follow on Twitter'
}

const Messages = (props) => {
    const navigate   = useNavigate();
    const location   = useLocation();
    const [message_id, setMessage_id] = useState(location && location.state ? location.state : 0);

    const [dropId, setDropId] = useState(0);
    const [userId, setUserId] = useState(0);
    const [token, setToken]    = useState(0);
    
    const [openSuccess, setOpenSuccess] = useState(0);
    const [msg, setMsg] = useState(msg_1);

    if(message_id.id){
        setDropId(message_id.drop);
        setUserId(message_id.uid);
        setToken(message_id.token);
        setMessage_id(message_id.id);
    }

    useEffect(() => {
        if(message_id == '#1')
            setMsg(msg_1);
        else if(message_id == '#2')
            setMsg(msg_2);
        else if(message_id == '#4')
            setMsg(msg_4);
        else if(message_id == '#5') 
            setMsg(msg_5);
        else if(message_id == '#6') 
            setMsg(msg_6);
        else if(message_id == '#9')
            setMsg(msg_9);
        else
            setMsg(msg_3);

        document.body.style.background = `url(${msg.bg_img}), #000 no-repeat`;
      },[message_id]);

    const msgBtn = () => {
        if(message_id == '#1' || message_id == '#2' || message_id == '#9'){
            window.open('https://twitter.com/dropfish_dao', '_blank');
        }
        else{
            navigate("/"+message_id);
        }
    }

    const resendLink = () => {
        setOpenSuccess(1);
        const url = 'https://api.drop.fish/api-flow/resend-link';
        //const url = 'http://localhost/lighthouseapi/api-flow/resend-link';
        const formData = new FormData();

        formData.append('user_id',userId);
        formData.append('drop_id',dropId);
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };

        formData.append('access_token', localStorage.getItem("lh_access_token"));
        
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
                setOpenSuccess(0);
                navigate("/tank");
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    resendLink();
                });
            }
        });
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <LoadingModal setOpenSuccess={setOpenSuccess} openSuccess={openSuccess}/>
            <Container maxWidth="xl" className='flex flex-col items-center min-h-[100vh] py-6'>
                <img src={Logo} alt='logo' className='w-full max-w-[15rem] mt-40'/>
                <Typography variant='header48Bold' component={'div'} className='mb-10 font-bold mt-20 sm:mt-[13.75rem] text-center'>{msg.title}</Typography>
                <Typography variant='text36' component={'div'} className='mt-6 text-center max-w-[60vw] leading-normal' dangerouslySetInnerHTML={{__html:msg.text}}/>
                {(message_id != '#4' && message_id != '#5')?(
                    <div className=' mb-10 mt-20 text-center'>
                        <ButtonMain variant='whiteBtn' text={msg.btn_text} classes='' onClick={msgBtn} type='button'/>
                    </div>
                ):('')}

                {(message_id == '#5')?(
                    <div className=' mb-10 mt-20 text-center'>
                        <ButtonMain variant='whiteBtn' text='Resend link' classes='' onClick={resendLink} type='button'/>
                    </div>
                ):('')}

            </Container>
        </ThemeProvider>
        
    )
}

export default Messages;