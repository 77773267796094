import {Card, CardActions, CardContent, CardMedia, Typography, Button} from '@mui/material';

import ButtonCard from '../button/Btn_card';

import { useNavigate } from 'react-router-dom';

import logo from '../../assets/logo-sm.svg';

import fishIcon from '../../assets/fish-icon.svg';

import dropload from '../../assets/drop-load.svg';

const DropCard = (props) =>{
    
    const navigate = useNavigate();

    const button = {text:props.cardDetails.status, variant:(props.cardDetails.status == 'UNLOCK')?'blueBtn':'secondaryBtn'};

    const onClick = () => {
        
        if(button.text == 'UNLOCK') {
            navigate("/"+props.cardDetails.drop_text);
        }
        else 
        {
            navigate("/utility",{
                state: props.cardDetails.drop_text
            });
        }
    }

    return(
        <Card className='rounded-3xl bg-darckGray shadow-none'>
        {props.cardType ? (
         <div className='relative'>
            <div className='md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem]'>   
                <CardMedia
                    className='md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem] bg-primary object-top'
                    component="img"
                    alt="test"
                    image={props.cardDetails.img}
                />
            </div>
            <div className='h-full absolute bottom-0 left-1/2 translate-x-1/2M w-full bg-gradient flex flex-col justify-between'>
                <div className='pt-5 w-full flex justify-between px-4 items-start'>
                    <Typography variant="text14Code" align='left' className='line-clamp-2 w-1/2 pr-1' component="div">
                        {props.cardDetails.brand}
                    </Typography>
                    <Typography variant="text14Code" align='right' className='line-clamp-2 w-1/2 pl-1' component="div">
                        {props.cardDetails.title}
                    </Typography>
                </div>
                
                <div className=' pb-5 w-full flex flex-col justify-between px-4'>
                    <div className='flex justify-between pb-2 items-end'>
                        <Typography variant="text14TwitterTag" align='left' className='line-clamp-2 w-5/12' component="div">
                        {(props.cardDetails.tw_name != 'XXX')?(
                             '@'+props.cardDetails.tw_name
                        ):('')}
                        </Typography>
                        <Typography variant="text14Code" align='right' className='line-clamp-2 w-5/12 flex justify-end' component="div">
                            {(props.cardDetails.serial_number=='...')?(
                                <img src={dropload} alt='loading' className='max-w-[1.5rem]'/>
                            ):(props.cardDetails.serial_number)}
                            
                        </Typography>
                    </div>
                    <div className='flex justify-between items-center'>
                        <Typography variant="text14Code" align='left' className='line-clamp-2 w-full flex items-center' component="div">
                        <img src={fishIcon} alt='fish' className='w-full max-w-[0.5rem] mr-2' /> {props.cardDetails.user_number}
                        </Typography>
                        <img src={logo} alt='logo' className='w-full max-w-[6rem] opacity-50' />
                    </div>
                </div>
                
            </div>
        </div>
        ):(
            <CardMedia
                className='md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem] bg-primary object-top'
                component="img"
                alt="test"
                image={props.cardDetails.img}
            />
        )}

        <CardContent className='bg-darckGray min-h-[11.438rem] max-h-[11.438rem]'>
            <Typography gutterBottom variant="subHeader1" align='center' className='my-8 line-clamp-1' component="div">
                {props.cardDetails.title}
            </Typography>
            <Typography variant="text20" align='center' component="div" className='line-clamp-3'>
                {props.cardDetails.sub_title}
            </Typography>
        </CardContent>
        <CardActions  className='bg-darckGray mb-10 mt-9 justify-center'>
            <ButtonCard type={'button'} onClick={onClick} btnProp={button}/>
        </CardActions>
        </Card>
    )
}

export default DropCard;