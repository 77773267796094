import React, {useState, useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import moment, { utc } from 'moment';
import axios from 'axios';
import {ThemeProvider, Typography, Container, FormControl, InputLabel, OutlinedInput, Select, MenuItem, CardContent, CardMedia, useRadioGroup} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FooterNav from '../components/nav/Nav-footer';
import CreateImg from '../assets/add-fill.svg';
import DeleteImg from '../assets/delete-fill.svg';
import NavBar from '../components/nav/Nav';
import { ThemeCustom} from "../styles/Theme";
import NavStyle from '../components/nav/Nav_style.module.scss';
import { useForm } from "react-hook-form";
import AddInputReward from '../components/input-add/Add_input_reward';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { FileUploader } from "react-drag-drop-files";
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';

import SuccessModal from '../components/modal/Modal_loading';
import WarningModal from '../components/modal/Modal_warning';


const SetUpRewards = (props) => {

    const {register,handleSubmit, formState: {errors}} = useForm();
    const [duration, setDuration]           = useState(0);
    const [isValidUrl, setIsValidUrl]       = useState(true);
    const [properties, setProperties]       = useState('');
    const [propertyName, setPropertyName]   = useState('');
    const [inputName, setInputName]         = useState('');
    const [addLink, setAddLink]             = useState('');
    const [valueDate, setValueDate]         = useState(moment());
    const [valueTime, setValueTime]         = useState(moment());
    const [selectedFile, setSelectedFile]   = useState();
    const [filesize, setFilesSize]          = useState();
    const navigate                          = useNavigate();
    const reader                            = new FileReader();
    const [rewardImage, setRewardImage]     = useState();
    const [rewardesc, setRewardesc]         = useState();
    const [dcd,setDcd]                      = useState(0);
    const [dc,setDc]                        = useState(1);
    const [dtr,setDtr]                      = useState(0);
    const [csvLength, setCsvLength]         = useState(0);
    const [csvValidation, setCsvValidation] = useState(true);
    const [maxAvailable, setMaxAvailable]   = useState("");
    const location                          = useLocation();
    const [drop, setDrop]                   = useState(null);
    const [openSuccess, setOpenSuccess]     = useState(0);
    const [openWarning, setOpenWarning]     = useState(false);
    const [submitAction, setSubmitAction]   = useState('next');
    const [displayChecks, setDisplayChecks] = useState({'dec':true,'dlc':false,'dtr':false});

    const Papa         = require('papaparse');
    const state        = location && location.state?location.state:'';
   // const rewardId     = (state.rewardId)?state.rewardId:'';
    const rewardData   = (state.action == 'back-and-next')?JSON.parse(localStorage.getItem('lh_setupform_data')):[];
    const user_id      = localStorage.getItem('nift_user_id');
    const temp_user_id = localStorage.getItem('lh_trmp_user_id');

    const handleDateChange = (newValue) => {
        setValueDate(newValue);
    };
    
    const handleTimeChange = (newValue) => {
        setValueTime(newValue);
    };

    useEffect(()=>{
        
        if(state.action == 'back-and-next'){
            setRewardImage(localStorage.getItem('lh_form_rewardImage'));
            const base64Data  = localStorage.getItem('lh_unique_csv');

            if(rewardData){
                if(rewardData.rewardDescription)
                    setRewardesc(rewardData.rewardDescription);

                if(rewardData.display_criteria)
                    setDc(rewardData.display_criteria)  
                
                if(rewardData.display_countdown)
                    setDcd(rewardData.display_countdown)  
                
                if(rewardData.display_time_remaining)
                    setDtr(rewardData.display_time_remaining)  

                if(rewardData.reward_properties)
                    setProperties(rewardData.reward_properties);
                
                if(rewardData.addLink) {
                    setAddLink(rewardData.addLink);
                }
                
                if(rewardData.liveDate){
                    setValueDate(moment(rewardData.liveDate));
                }

                if(rewardData.liveTime) {
                    setValueTime(moment(rewardData.liveTime));
                }

                if(rewardData.maxAvailable)
                    setMaxAvailable(rewardData.maxAvailable);

                if(rewardData.duration)
                    setDuration(rewardData.duration);

                if(base64Data){
                    const base64CSV   = base64Data.split(',')[1];
                    const decodedData = atob(base64CSV);
                    
                    Papa.parse(decodedData, {
                        header: false,
                        skipEmptyLines: true,
                        complete: function (results) {
                            setCsvLength(results.data.length)
                        },
                    });
                    
                    const file   = new Blob([base64Data], { type: 'text/csv' });
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    
                    reader.addEventListener('load', () => {
                        setSelectedFile(file);
                        let fileSize = (file.size / (1024 * 1024)).toFixed(2);
                        if (fileSize > 1)
                            setFilesSize(fileSize + ' MB');
                        else
                            setFilesSize((file.size / 1024).toFixed(2) + ' KB');
                    });
                }
            }
        }
        else {
            localStorage.removeItem("lh_unique_csv");
            localStorage.removeItem("lh_setupform_data");
            localStorage.removeItem("lh_logo_image");
            localStorage.removeItem("lh_background_image");
            localStorage.removeItem("lh_emails_csv");
            localStorage.removeItem("lh_form_rewardImage");
            localStorage.removeItem("lh_bg_size");
            localStorage.removeItem("lh_criteriaform_data");
            localStorage.removeItem("lh_bg_name");
            localStorage.removeItem("lh_edit_drop");
        }
    },[]);

    const handleChangeDuration = (e) => {
        setDuration(e.target.value);

        if(e.target.value === 0){
           document.getElementById('duration-input').value = '';
        }
    };

    const handleChangeProperty = (e) => {
        if(e.target.value == '')
            setAddLink('');

        setProperties(e.target.value);
    };

    const handleRewardProperty = (e) => {
        setProperties(e.target.value);
        setAddLink('');

        switch(e.target.value){
            case 1:
                setInputName('link');
                break;
            case 2:
                setInputName('qr');
                break;
            case 3:
                setInputName('promo');
                break;
            default:
                setInputName('');
                break;
        }
    };

    const handleLinkProperty = (e) => {
        setAddLink(e.target.value);
    };

    const handleUrlValidation = (e) => {
        if(properties==1){
            const inputUrl = e.target.value;
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            const isValid = urlRegex.test(inputUrl);
            setIsValidUrl(isValid);
        }
    };

    const removeRewardImage = (e) => {
        e.target.value = '';
        localStorage.setItem('lh_form_rewardImage','');
        setRewardImage('');
    }

    const removeFile = (e) => {
        setSelectedFile();
    }

    const loadRewardImage = (e) => {
        let image = e;
        reader.readAsDataURL(image);
        reader.addEventListener('load', () => {
            localStorage.setItem('lh_form_rewardImage', reader.result);
            setRewardImage(localStorage.getItem('lh_form_rewardImage'));
        });
    };

    useEffect(()=>{
        if(addLink == 2){
            if(csvLength < maxAvailable)
                setCsvValidation(false)
            else
                setCsvValidation(true)
        }
    },[csvLength,maxAvailable])

    const CsvUpload = (e) => {
        localStorage.removeItem('lh_unique_csv');
        let file = e;

        Papa.parse(file, {
            header: false,
            skipEmptyLines: true,
            complete: function (results) {
                setCsvLength(results.data.length)
            },
        });

        reader.readAsDataURL(file);

        reader.addEventListener('load', () => {
            localStorage.setItem('lh_unique_csv', reader.result);
            setSelectedFile(file);
            let fileSize = (file.size/(1024*1024)).toFixed(2);
            if(fileSize > 1)
                setFilesSize(fileSize + ' MB');
            else
                setFilesSize((file.size/(1024)).toFixed(2) + ' KB');
        });
    };

    const back = () => {
        navigate('/drop');
    }

    const submit = (data) => {
       
        if(submitAction == 'cancel' || submitAction == 'next') {
            
            let validation = true;
            
            if(properties != '' && addLink == 2){
                
                if(csvLength < data.maxAvailable) {
                    setCsvValidation(false)
                    validation = false;
                }
                else
                    setCsvValidation(true)
            }
            else {
                if(properties == 1 && isValidUrl == false)
                    validation = false;
                else
                    setCsvValidation(true)
            }
            
            if(submitAction == 'cancel' || validation){

                localStorage.removeItem('lh_setupform_data');
                
                data.instruction.map(function(instruct,index){
                    if(!instruct)
                        data.instruction.splice(index, 1);
                });

                data.reward_properties = properties;
                data.inputName = inputName;
                data.addLink   = addLink; 
                data.duration  = duration;
                data.rewardDescription = rewardesc;
                data.display_countdown = dcd;
                data.display_criteria  = dc;
                data.display_time_remaining = dtr;
                data.liveDate = valueDate.format('MM/DD/YYYY')+' '+valueTime.format('h:mm A');
                data.liveTime = valueDate.format('MM/DD/YYYY')+' '+valueTime.format('h:mm A');

                localStorage.setItem('lh_setupform_data',JSON.stringify(data));
                setOpenSuccess(submitAction=='cancel'?2:1);
                createAndUpdateReward(submitAction);
            } 
        }
    }

    const createAndUpdateReward = async (submitAction) => {
        const url = 'https://api.drop.fish/api-flow/create-reward';
        //const url = 'http://localhost/lighthouseapi/api-flow/create-reward';
        const formData = new FormData();

        if(localStorage.getItem('lh_form_rewardImage'))
            formData.append('reward_image',localStorage.getItem('lh_form_rewardImage'));
      
        if(localStorage.getItem('lh_unique_csv'))    
            formData.append('unique_csv',localStorage.getItem('lh_unique_csv'));

        if(localStorage.getItem('lh_setupform_data')) {
            
            const setData = JSON.parse(localStorage.getItem('lh_setupform_data'));

            const utcLiveDate = moment(setData.liveDate);
            utcLiveDate.utc();

            const utcLiveTime = moment(setData.liveTime);
            utcLiveTime.utc();

            setData.liveDate = utcLiveDate.format('MM/DD/YYYY')+' '+utcLiveTime.format('h:mm A');
            
            setData.liveTime = utcLiveTime.format('MM/DD/YYYY')+' '+utcLiveTime.format('h:mm A');
            
            formData.append('setupform_data',JSON.stringify(setData));
        }

        if(user_id)
            formData.append('user_id',user_id);
        else if(temp_user_id)
            formData.append('user_id',temp_user_id);

        if(localStorage.getItem('lh_edit_drop'))
            formData.append('drop_id',localStorage.getItem('lh_edit_drop'));

        formData.append('access_token', localStorage.getItem("lh_access_token"));
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
                    localStorage.setItem('lh_edit_drop',response.data.data.drop_id);
              
                if(response.data.data.countdown_time)
                    localStorage.setItem('lh_countdown_time', response.data.data.countdown_time);

                if(response.data.data.display_time)
                    localStorage.setItem('lh_display_time', response.data.data.display_time);
                
                if(response.data.data.temp_user)
                    localStorage.setItem('lh_trmp_user_id',response.data.data.user_id);
                
               /*  if(response.data.data.lh_unique_csv_file)
                    localStorage.setItem('lh_unique_csv_file',response.data.data.lh_unique_csv_file); */

                if(response.data.data.lh_unique_csv)
                    localStorage.setItem('lh_unique_csv',response.data.data.lh_unique_csv);
                
                if(submitAction != 'cancel'){
                    if(localStorage.getItem('lh_criteriaform_data')) 
                        navigate('/criteria',{state:{'action':'back-and-next'}});
                    else
                        navigate('/criteria');
                }
                else {
                    setOpenSuccess(0);
                    setOpenWarning(true);
                }
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    createAndUpdateReward(submitAction);
                });
            }
            else 
            {
                if(submitAction == 'cancel'){
                    setOpenSuccess(0);
                    setOpenWarning(true);
                }
            }
        });
    }

    const viewRenderers = {
        hours: () => <DigitalClock onChange={handleTimeChange}/>,
    };

    return( 
    <ThemeProvider theme={ThemeCustom}>
        <NavBar getOAuthToken={props.getOAuthToken}/>
        <SuccessModal setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} mid={1}/>
        <WarningModal setOpenWarning={setOpenWarning} openWarning={openWarning}/>  
        <form onSubmit={handleSubmit(submit)} >
        <Container maxWidth="xl" className="mt-[8.25rem] sm:mt-[8.917rem] md:mt-[7.563rem] mb-[8.125rem] text-white">
            <Typography component={'div'} variant='header48' align='left' className='py-10'>Setup a Reward</Typography>
            <div>
                <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} sm={6} >
                        <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                            <InputLabel shrink htmlFor="name-input">Reward Name</InputLabel>
                            <OutlinedInput 
                                id="name-input"
                                defaultValue={rewardData && rewardData.rewardName?rewardData.rewardName:''}
                                autoComplete="off"
                                readOnly={(rewardData?.is_nft_created == 1)?true:false}
                                placeholder="e.g: Tickets to my event, App loyalty program etc." 
                                type='text'
                                {...register("rewardName",{ required: (submitAction !== 'cancel')?true:false})}
                            />
                            {errors?.rewardName?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} >
                        <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                            <InputLabel shrink htmlFor="name-input">Organization Name</InputLabel>
                            <OutlinedInput 
                                id="org-name-input"
                                defaultValue={(rewardData && rewardData.rewardOrgName)?rewardData.rewardOrgName:''}
                                autoComplete="off" 
                                placeholder="Organization name" 
                                type='text'
                                {...register("rewardOrgName",{ required: (submitAction !== 'cancel')?true:false})}
                            />
                            {errors?.rewardOrgName?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} sm={4} >
                        <div className='flex justify-between'>
                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col max-w-[49%]'>
                            <InputLabel shrink htmlFor="calendar-input">Go Live Date & Time</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        {...register("liveDate",{ required: (submitAction !== 'cancel')?true:false})}
                                        value={valueDate}
                                        onChange={handleDateChange}
                                        sx={{
                                            '.MuiSvgIcon-root':{
                                                color:'#fff',
                                                marginRight:'0.5rem'
                                            },
                                            '.MuiInputBase-input':{
                                                padding:'16.5px 24px 16.5px 14px !important'
                                            }
                                        }}
                                    />
                                    
                            </LocalizationProvider>    
                            {errors?.liveDate?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                            </FormControl>
                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col max-w-[49%]'>
                            <InputLabel shrink htmlFor="time-input" className='p-[0.7rem]'></InputLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <TimePicker
                                        {...register("liveTime",{ required: (submitAction !== 'cancel')?true:false})}
                                        value={valueTime}
                                        viewRenderers={viewRenderers}
                                        sx={{
                                            '.MuiSvgIcon-root':{
                                                color:'#fff',
                                                marginRight:'0.5rem'
                                            },
                                            '.MuiInputBase-input':{
                                                padding:'16.5px 24px 16.5px 14px !important'
                                            }
                                        }}
                                        
                                    />
                                    {/* <TimeField 
                                        {...register("liveTime",{ required: true})}
                                        value={valueTime}
                                        onChange={handleTimeChange}
                                        sx={{
                                            '.MuiInputBase-input':{
                                                padding:'16.5px 24px 16.5px 14px !important'
                                            }
                                        }}
                                    /> */}
                            </LocalizationProvider>    
                            {errors?.liveTime?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={4} >
                        <div className='flex justify-between'>
                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col max-w-[49%]'>
                            <InputLabel shrink labelid="duration-select">Duration</InputLabel>
                                <OutlinedInput 
                                    id="duration-input"
                                    autoComplete="off" 
                                    defaultValue={(rewardData && rewardData.durationInput)?rewardData.durationInput:''}
                                    placeholder={duration != '0' ?'Enter a number':'N/A'} 
                                    type="number"
                                    readOnly={duration === 0 ? true : false}
                                    //disabled={duration === 0 ? true : false}
                                    //(submitAction !== 'cancel')?true:false
                                    {...register("durationInput",{ required: (duration != 0 && submitAction !== 'cancel')?true:false})} 
                                />
                                {errors?.durationInput?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                            </FormControl>
                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col max-w-[49%]'>
                                <InputLabel shrink htmlFor="duration-input" className='p-[0.7rem]'></InputLabel>
                                <Select
                                    labelid="duration-select"
                                    id="duration-select"
                                    value={duration}
                                    label="Duration"
                                    onChange={handleChangeDuration}
                                    input={<OutlinedInput />}
                                    displayEmpty
                                    name="duration"
                                    >
                                    <MenuItem className={NavStyle['admin-nav-item']} selected value={0}>Unlimited</MenuItem>
                                    <MenuItem className={NavStyle['admin-nav-item']} value={1}>Hours</MenuItem>
                                    <MenuItem className={NavStyle['admin-nav-item']} value={2}>Days</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={4} >
                        <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                        <InputLabel shrink htmlFor="max-aval-input">Max Available</InputLabel>
                        <OutlinedInput 
                            id="max-aval-input"
                            defaultValue={(rewardData && rewardData.maxAvailable)?rewardData.maxAvailable:''}
                            autoComplete="off" 
                            readOnly={(rewardData?.is_nft_created == 1)?true:false}
                            placeholder="Enter a number" 
                            type="number"
                            {...register("maxAvailable",{ required: (submitAction !== 'cancel')?true:false})} 
                            onChange={(e) => setMaxAvailable(e.target.value)}
                        />
                        {errors?.maxAvailable?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} sm={4} >
                        <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                            <InputLabel shrink>Reward Image</InputLabel>
                            {(rewardImage)?(
                                <div>
                                    <CardContent className='bg-[#1A1C1E] p-0 m-0 rounded-2xl flex justify-center items-center flex-col min-h-[26.375rem] max-h-[24.375rem] overflow-hidden relative max-w-[26.5rem]'>
                                        <CardMedia
                                            className='min-h-[6.25rem] max-h-[6.25rem] max-w-[6.25rem] z-50 cursor-pointer'
                                            component="img"
                                            alt="Delete image"
                                            image={DeleteImg}
                                            onClick={(e)=>{removeRewardImage(e)}}
                                        />
                                        <img src={rewardImage} alt='nft' className='absolute w-full h-full object-cover'/>
                                    </CardContent>
                                </div>
                            ):(
                                <>
                                <FileUploader
                                    classes={'max-w-[26.5rem] rounded-2xl drag-drop'}
                                    multiple={false}
                                    handleChange={loadRewardImage}
                                    name="file"
                                    types={["JPEG","JPG","PNG","GIF","JFIF","PJP","SVG","WEBP"]}
                                    children={
                                        <div className='mb-0 cursor-pointer p-4 bg-[#1A1C1E] rounded-2xl  max-w-[26.5rem]'>
                                            <CardContent className='bg-[#1A1C1E] p-0 m-0 border-2 border-onyx border-dashed rounded-2xl flex justify-center items-center flex-col min-h-[24.375rem] max-h-[24.375rem] max-w-[26.5rem]'>
                                                <CardMedia
                                                    className='min-h-[6.25rem] max-h-[6.25rem] max-w-[6.25rem]'
                                                    component="img"
                                                    alt="Add image"
                                                    image={CreateImg}
                                                    {...register("rewardImage",{ required: true })} 
                                                />
                                                <Typography variant="textBase" align='center' className='mt-7 no-underline mb-2' component="div">
                                                    Add Reward Image
                                                </Typography>
                                                <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces text-center text-sm mx-3' component="div">
                                                    1024px x 1024px recommended JPEG,JPG,PNG,GIF,JFIF,PJP,SVG,WEBP (Max 1MB)
                                                </Typography>
                                            </CardContent>
                                        </div>
                                    }
                                />
                              </>
                            )}
                            {errors?.rewardImage?.type=== "required" && <p className='text-salsaRed'>This field is required</p>}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} sm={4} >
                    <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                        <InputLabel shrink labelid="rew-prop-input">Reward Properties</InputLabel>
                        <Select
                            labelid="rew-prop-input"
                            id="rew-prop-input"
                            defaultValue={(rewardData && rewardData.reward_properties)?rewardData.reward_properties:properties}
                            label=">Reward Properties"
                            onChange={handleChangeProperty}
                            input={<OutlinedInput />}
                            displayEmpty
                            //{...register("properties",{ required: true })}
                        >
                           {/* <MenuItem className={NavStyle['admin-nav-item']} selected disabled value={''}>-Select-</MenuItem> */}
                            <MenuItem className={NavStyle['admin-nav-item']} selected value={''}>Collectible only</MenuItem>
                            <MenuItem className={NavStyle['admin-nav-item']} value={1}>Add link to drop</MenuItem>
                            <MenuItem className={NavStyle['admin-nav-item']} value={2}>Add QR code to drop</MenuItem>
                            <MenuItem className={NavStyle['admin-nav-item']} value={3}>Add promo code to drop</MenuItem>
                        </Select>
                        {/*errors.properties && <span className='err-msg'>This is required.</span>*/}
                    </FormControl>
                    </Grid>
                    <Grid xs={12} sm={4} ></Grid>
                    <Grid xs={12} sm={4} ></Grid>
                </Grid>
                {properties !== '' ? (
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} sm={4} >
                    <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                        <InputLabel shrink labelid="add-link-input">Add {(properties == '1')?('Link'):('Code')}</InputLabel>
                        <Select
                            labelid="add-link-input"
                            id="add-link-input"
                            value={(rewardData && rewardData.addLink)?rewardData.addLink:addLink}
                            label="Add Link"
                            onChange={handleLinkProperty}
                            input={<OutlinedInput />}
                            displayEmpty
                            //{...register("properties",{ required: true })}
                        >
                            <MenuItem className={NavStyle['admin-nav-item']} selected disabled value={''}>-Select-</MenuItem>
                            <MenuItem className={NavStyle['admin-nav-item']} value={1}>Same {(properties == '1')?('link'):('code')} for everyone</MenuItem>
                            <MenuItem className={NavStyle['admin-nav-item']} value={2}>Unique {(properties == '1')?('links'):('codes')} for everyone</MenuItem>
                        </Select>
                        {/*errors.properties && <span className='err-msg'>This is required.</span>*/}
                    </FormControl>
                    </Grid>
                    <Grid xs={12} sm={4} ></Grid>
                    <Grid xs={12} sm={4} ></Grid>
                </Grid>
                ):''}
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {properties != 4 && addLink === 1 ?(
                        <Grid xs={12} >
                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                                <InputLabel shrink htmlFor="url-input">{(properties==1)?'URL':(properties==2)?'QR Code':'Promo Code'}</InputLabel>
                                <OutlinedInput 
                                    id="url-input"
                                    defaultValue={(rewardData && rewardData.link_url)?rewardData.link_url:''}
                                    autoComplete="off" 
                                    placeholder={(properties==1)?'Enter URL':(properties==2)?'Enter QR code':'Enter promo code'} 
                                    type='text'
                                    //name="link_url"
                                    {...register("link_url",{ required:  (submitAction !== 'cancel')?true:false })} 
                                    onChange={handleUrlValidation}
                                    //{...register("url",{ required: true })} 
                                />
                                {!isValidUrl?(
                                    <p className='text-salsaRed'>Please enter a valid URL.</p>
                                ):(
                                    errors?.link_url?.type === "required" && <p className='text-salsaRed'>This field is required.</p>
                                )}
                                
                            </FormControl>
                        </Grid>
                    ): ('')}
                    {properties != '' && addLink === 2? (
                        /*csv feild*/
                        <>
                            <Grid xs={12} sm={4} >
                                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                                    <InputLabel shrink>Upload</InputLabel>
                                    {!(selectedFile)?(
                                        <>
                                        <FileUploader
                                            classes={'max-w-[26.5rem] rounded-2xl drag-drop'}
                                            multiple={false}
                                            handleChange={CsvUpload}
                                            name="file"
                                            types={["xlsx", "csv"]}
                                            children={
                                                <div className='mb-0 cursor-pointer p-4 bg-[#1A1C1E] rounded-2xl  max-w-[26.5rem]'>
                                                    <CardContent className='bg-[#1A1C1E] p-0 m-0 border-2 border-onyx border-dashed rounded-2xl flex justify-center items-center flex-col min-h-[24.375rem] max-h-[24.375rem] max-w-[26.5rem]'>
                                                        <CardMedia
                                                            className='min-h-[6.25rem] max-h-[6.25rem] max-w-[6.25rem]'
                                                            component="img"
                                                            alt="Add CSV"
                                                            image={CreateImg}
                                                            {...register("rewardImage",{ required: true })} 
                                                        />
                                                        <Typography variant="textBase" align='center' className='mt-7 no-underline mb-2' component="div">
                                                            Upload CSV File
                                                        </Typography>
                                                        <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces text-center text-sm mx-3' component="div">
                                                            Upload a CSV file with all {(properties==1)?'links':'codes'} in a single column with no column title or header. The number of {(properties==1)?'links':'codes'} should equal the Max Available Drops {(maxAvailable > 0)?'('+maxAvailable+')':''}
                                                        </Typography>
                                                    </CardContent>
                                                </div>
                                            }
                                        />
                                        {!csvValidation && <p className='text-salsaRed'>This field is required</p>}
                                        </>
                                    ):(
                                        <div>
                                            <CardContent className='p-10 m-0 border flex-col border-platinum border-dashed rounded-2xl flex justify-center items-center min-h-[20.75rem] max-h-[20.75rem] min-w-[28.125rem] w-full'>
                                                <CardMedia
                                                    className='w-full max-w-[6.25rem] cursor-pointer'
                                                    component="img"
                                                    alt="delete image"
                                                    image={DeleteImg}
                                                    onClick={(e)=>{removeFile(e)}}
                                                />
                                                <div className='mt-4'>
                                                    <Typography variant="textBase" className='no-underline mb-1 break-words whitespace-break-spaces font-semibold text-xl' component="div">
                                                        {(selectedFile.name)}
                                                    </Typography>
                                                    <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces  text-sm' component="div">
                                                        {filesize}
                                                    </Typography>
                                                </div>
                                            </CardContent>
                                            {(csvLength > maxAvailable)?(
                                                <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces text-sm' component="div">
                                                    We take the first {(maxAvailable > 0)?'('+maxAvailable+')':''} {(properties==1)?'links':'codes'} in the CSV because the number of {(properties==1)?'links':'codes'} is greater than the Max Available Drops.
                                                </Typography>
                                            ):('')}
                                            {submitAction !== 'cancel' && !csvValidation  && <p className='text-salsaRed'> Invalid CSV. The number of {(properties==1)?'links':'codes'} should equal the Max Available Drops {(maxAvailable > 0)?'('+maxAvailable+')':''}</p>}
                                        </div>
                                       
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={4} ></Grid>
                            <Grid xs={12} sm={4} ></Grid>
                        </>
                    ):''}
                </Grid>
                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 pt-4 flex flex-col'>
                    <InputLabel shrink htmlFor="instruct-input">Reward Instructions</InputLabel>
                    <AddInputReward instructions={rewardData && rewardData.instruction?rewardData.instruction:['']} register={register} errors={errors} btnAction={submitAction}/>
                    {errors?.instruction && <p className='text-salsaRed'>You need at least 1 instruction to help guide your audience.</p>}
                </FormControl>
            </div>
         </Container>    
        <FooterNav displayCheks={displayChecks} btnAction={setSubmitAction} disableNext={false} back={back}/>
        </form>
    </ThemeProvider>   
    )
}

export default SetUpRewards;