import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import {Box, Typography, Modal} from '@mui/material';

import ModalStyle from './Modal_styles.module.scss';
import ButtonMain from '../button/Button_main';

import animIcon from '../../assets/dog-anim.gif';
import animWarn from '../../assets/warning-anim.gif';
import Copy from '../../assets/copy.svg';

const SuccessModal = ({openWarning = true, setOpenWarning}) =>{

    const navigate = useNavigate();

    const no = () => {
        setOpenWarning(false);
    }

    const yes = () => {
       
        navigate('/drop');
        setOpenWarning(false);
    }

    return(
        <div>
            <Modal
                open={openWarning}
                aria-labelledby="modal-modal-title"
                id="SuccessModal"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-success']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={`${ModalStyle['icon-wrapper']} ${ModalStyle['content-warning']}`}>
                                <img src={animWarn} alt='icon' className={`${ModalStyle['icon-img']} ${ModalStyle['icon-warning']}`}/>
                            </div>
                            <Typography id="modal-modal-text" className={ModalStyle['modal-text18']} align={'center'} variant="text24" component="div">
                                Are you sure you want to cancel? Your current changes will be saved even if you exit the form.
                            </Typography>
                            <div className={`${ModalStyle['modal-button-wrapper']} ${ModalStyle['modal-button-wrapper-flex']}`}>
                                <ButtonMain text="Not Now" fullWidth variant={'whiteBtnRegular'}  type="button" onClick={no} />
                                <ButtonMain text="Yes" fullWidth variant={'orangeBtn'}  type="submit" onClick={yes} />
                            </div>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default SuccessModal;