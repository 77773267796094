import {Card, CardActions, CardContent, CardMedia, Typography, Skeleton } from '@mui/material';

import ButtonCard from '../button/Btn_card';

import { useNavigate } from 'react-router-dom';

const DropCardSkeleton = () =>{
    

    return(
        <Card className='rounded-3xl bg-darckGray shadow-none'>
            <Skeleton
                className='md:min-h-[12.5rem] md:max-h-[12.5rem] min-h-[200px] max-h-[200px] bg-primary transform-none'
            />
            <CardContent className='bg-darckGray min-h-[11.438rem] max-h-[11.438rem]'>
                <Skeleton variant="subHeader1" className='my-8 p-4 bg-darkGrey' >
                </Skeleton >
                <Skeleton variant="text20" className='mt-2 p-3 bg-darkGrey' >
                </Skeleton >
                <Skeleton variant="text20" className='mt-2 p-3 bg-darkGrey' >
                </Skeleton >
            </CardContent>
            <CardActions  className='bg-darckGray mb-10 mt-9 justify-center'>
                <Skeleton variant="primaryBtn" className='mt-2 min-w-[9.844rem] bg-darkGrey min-h-[3.26rem] rounded-xl' >
                </Skeleton >
            </CardActions>
        </Card>
    )
}

export default DropCardSkeleton;