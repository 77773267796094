import React, {useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ModalStyle from './Modal_styles.module.scss';

import animIcon from '../../assets/dog-anim.gif'

const WaitModal = ({openWait = true, setOpenWait}) =>{

    return(
        <div>
            <Modal
                open={openWait}
                aria-labelledby="modal-modal-title"
                id="waitModal"
            >
                <Box className={`${ModalStyle['modal-base']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={animIcon} alt='delete icon' className={ModalStyle['icon-img']}/>
                            </div>
                            <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="subHeader1" component="h2">
                                Congratulations! You got the drop 🥳
                            </Typography>
                            <Typography id="modal-modal-text" className={ModalStyle['modal-text']} align={'center'} variant="subHeader1" component="div">
                                It'll take a few seconds to set up your reward. Hold tight!
                            </Typography>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default WaitModal;