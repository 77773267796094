import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";

const ButtonPrimary = (props) => {
  if (props.disabled == 'yes') {
      return (
          <ThemeProvider theme={ThemeCustom}>
            <Button variant="primaryBtn" target='_blank' type={props.type} disabled
              sx={{
              backgroundColor:props.buttonProp.color,
              '&:hover': {
                  backgroundColor: `${props.buttonProp.color + 'ba'} !important`,
                },
                '&.Mui-disabled':{
                  color: `${'#ffffff' + '80'}`,
                  background: `${props.buttonProp.color + '80'}`,
                }
              }} 
              className={props.classes} onClick={props.onClick}>
              {props.buttonProp.btnName}</Button>
          </ThemeProvider>
      );
   }
   else{
      return (
          <ThemeProvider theme={ThemeCustom}>
            <Button variant="primaryBtn" target='_blank' type={props.type} 
              sx={{
              backgroundColor:props.buttonProp.color,
              '&:hover': {
                  backgroundColor: `${props.buttonProp.color + 'ba'} !important`,
                },
              '&.Mui-disabled':{
                  color: `${'#ffffff' + '80'}`,
                  background: `${props.buttonProp.color + '80'}`,
                }
              }}
              className={props.classes} onClick={props.onClick}>
              {props.buttonProp.btnName}</Button>
          </ThemeProvider>
      );
   }
}

export default ButtonPrimary;