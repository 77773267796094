import React, {useState, useEffect} from 'react';
import {ThemeProvider, Typography, Container} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation } from "react-router-dom"
import NavBar from "../components/nav/Nav";
import DropCard from "../components/card/Drop_card";
import axios from 'axios';
import SuccessAlert from '../components/alerts/Success_alert';
import PendingAlert from '../components/alerts/Pending_alert';
import ErrorAlert from '../components/alerts/Fail_alert';
import DropCardSkeleton from '../components/card/Drop_card_skeleton';
import { ThemeCustom} from "../styles/Theme";


const Drops = (props) => {
    const location  = useLocation();
    //let transfer_id = location && location.state ? location.state.transfer_id : 0;
    let user_id     = localStorage.getItem('nift_user_id');

    const [viewDrop, setViewDrop]       = useState([]);
    const viewDropSkeleton              = [1,2,3,4];
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail]       = useState(false);
    const [openPending, setOpenPending] = useState(false);
    const [unlock, setUnlock]           = useState([]);
  
    const [textPending, setTextPending] = useState();
    const [textSuccess, setTextSuccess] = useState();
    const [textFail, setTextFail]       = useState();
 
    const [userNftCount, setuserNftCount]     = useState(false);
    const [nftMintSuccess, setnftMintSuccess] = useState(false);
    const [loginData, setLoginData]           = useState('');

    useEffect(()=>{
        if(user_id)
            getDrops(user_id);
        else    
            getDrops('');
    },[user_id]);

    const userNfts = async (uid) => {
        setuserNftCount(true);
        const url = 'https://api.drop.fish/api-flow/user-nfts';
        //const url = 'http://localhost/lighthouseapi/api-flow/user-nfts';
        const formData = new URLSearchParams();
        formData.append('user_id',uid);
        formData.append('access_token', localStorage.getItem("lh_access_token"));
        
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        };
        
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
                setViewDrop(response.data.data);
            }
            else 
            {
                if(response.data.message == 'invalid_token'){
                    props.getOAuthToken().then(res => {
                        userNfts(uid);
                    });
                }
                else
                    setuserNftCount(false);
            }
        });
    }

    const getDrops = async (uid) => {
        //const url = 'http://localhost/lighthouseapi/api-flow/get-drops?uid='+uid;
        const access_token =  localStorage.getItem("lh_access_token");
        const url = 'https://api.drop.fish/api-flow/get-drops?uid='+uid+'&access_token='+access_token;
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        };
        
        axios.get(url, config).then(function (response) {
            if(response.data.message == 'success'){
                if(response.data.data){
                    setUnlock(response.data.data);
                }
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    getDrops(uid);
                });
            }
        });
    }

    const checkUserDropsCounters = async () => {
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        const updatedUnlock = await Promise.all(
            viewDrop.map(async (drop,index) => {
                if (drop.serial_number == '...') {
                    try {
                        const access_token =  localStorage.getItem("lh_access_token");
                        await axios.get('https://api.drop.fish/api-flow/get-mint-count?nft='+drop.nft_id+'&access_token='+access_token, config).then(function (response){
                            if(response.data.message == 'success'){
                                if (response.data.data) {
                                    if (response.data.data.serial_number != '...') {
                                        userNfts(user_id);
                                    }
                                    else {
                                        setTimeout(()=>{
                                            checkUserDropsCounters();
                                        },5000);
                                    }
                                }
                            }
                            else if(response.data.message == 'invalid_token'){
                                props.getOAuthToken().then(res => {
                                    checkUserDropsCounters();
                                });
                            }
                        });
                    } 
                    catch (error) {
                        console.error('Error fetching data:', error);
                    }

                }
            })
        );
     };

    checkUserDropsCounters();

    useEffect(()=>{
        if(user_id)
            userNfts(user_id);
    },[user_id]);
   
    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)
    },[openFail]);
  
    useEffect(()=>{
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    useEffect(()=>{
        setTimeout(()=>{
            setOpenPending(false);
        },10000)
    },[openPending]);

    useEffect(()=>{
        if(loginData)
            userNfts(loginData);
    },[loginData]);

    return(
        <ThemeProvider theme={ThemeCustom}>
           <NavBar setLoginData={setLoginData} getOAuthToken={props.getOAuthToken}/>
           <div className='fixed top-12 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
                <PendingAlert setOpenPending={setOpenPending} openPending={openPending} alretText={textPending}/>
            </div>
           <Container maxWidth="xl" className="mt-[8.25rem] sm:mt-[8.917rem] md:mt-[7.563rem] mb-10 text-white">
                {(user_id && userNftCount)?(
                    <>
                    <Typography component={'div'} variant='header48' align='left' className='py-10'>My drops</Typography>
                    <Grid container  spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {(viewDrop.length > 0)?(
                            viewDrop.map((drop, index) => (
                                <Grid xs={12} sm={4} lg={3} key={index}>
                                    <DropCard cardType={true} cardDetails={drop}/>
                                </Grid>
                            ))
                        ):(
                            viewDropSkeleton.map((drop, index) => (
                                <Grid xs={12} sm={4} lg={3}>
                                    <DropCardSkeleton/>
                                </Grid>
                            ))
                        )}

                    </Grid>
                    </>
                ):('')}
                {(unlock.length > 0)?(
                    <>
                    <Typography component={'div'} variant='header48' align='left' className='pb-10 pt-20'>Available Drops</Typography>
                    <Grid container  spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {unlock.map((drop, index) => (
                            <Grid xs={12} sm={4} lg={3} key={index}>
                                <DropCard cardType={true} cardDetails={drop}/>
                            </Grid>
                        ))} 
                    </Grid>
                    </>
                ):('')}
                
            </Container>    
        </ThemeProvider>
    )
}

export default Drops;