import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { InstagramLogin } from '@amraneze/react-instagram-login';
import {Box, Typography, Modal} from '@mui/material';

import ModalStyle from './Modal_styles.module.scss';
import ButtonMain from '../button/Button_main';
import ButtonMainImg from '../button/Button_main_img';

import animIcon from '../../assets/dog-anim.gif';
import twitterLogo from '../../assets/Twitter-Logo.svg';
import GoogleLogo from '../../assets/Google-Logo.svg';
import EmailLogo from '../../assets/mail.svg';
import DiscordLogo from '../../assets/discord.svg';

const LoginModal = ({openLogin = true, setOpenLogin,drop = 0}) =>{

    const navigate = useNavigate();

    const responseInstagram = (response) => {
        if(!response.error) {
            if(drop !== 0)
                window.location.replace('https://api.drop.fish/instagram-auth?drop=login'+drop+'&code='+response);
            else
                window.location.replace('https://api.drop.fish/instagram-auth?drop=login&code='+response);
            
            setOpenLogin(false);
        }   
    };
    
    const twitterLogin = (e) => {
        if(drop !== 0)
            window.location.replace('https://api.drop.fish/auth?drop=login'+drop);
        else
            window.location.replace('https://api.drop.fish/auth?drop=login');

        setOpenLogin(false);
    }

    const googleLogin = () => {
        if(drop !== 0)
            window.location.replace('https://api.drop.fish/google-auth?drop=login'+drop);
        else
            window.location.replace('https://api.drop.fish/google-auth?drop=login');

        setOpenLogin(false);
    }

    const discordLogin = () => {
        if(drop !== 0)
            window.location.replace('https://api.drop.fish/discord-auth?drop=login'+drop);
        else
            window.location.replace('https://api.drop.fish/discord-auth?drop=login');

        setOpenLogin(false);
    }

    const emailLogin =() => {
        if(drop !== 0){
            navigate("/sign_in",{
                state: drop
            });
        }
        else {
            navigate("/sign_in");
        }
    }

    return(
        <div>
            <Modal
                open={openLogin}
                onClose={()=>setOpenLogin(false)}
                aria-labelledby="modal-modal-title"
                id="SuccessModal"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-login']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <Typography id="modal-modal-title" className={`${ModalStyle['modal-text24']}, ${ModalStyle['modal-title']}`} align={'center'} variant="text24" component="h2">
                                Login
                            </Typography>
                            <Typography id="modal-modal-text" className={ModalStyle['modal-text18']} align={'center'} variant="text18" component="div">
                                Please select your provider
                            </Typography>
                            <div className={ModalStyle['modal-button-wrapper']}> 
                                <ButtonMainImg text="Login with Twitter" img={twitterLogo} fullWidth variant={'lableBtn'} classes="mb-5" type="button" onClick={(e)=>twitterLogin(e)}/>
                                <ButtonMainImg text="Login with Google" img={GoogleLogo} fullWidth variant={'lableBtn'} classes="mb-5" type="button" onClick={googleLogin}/>
                                <ButtonMainImg text="Login with Email" img={EmailLogo} fullWidth variant={'lableBtn'} classes="mb-5" type="button" onClick={emailLogin}/>
                                <ButtonMainImg text="Login with Discord" img={DiscordLogo} fullWidth variant={'lableBtn'} classes="mb-5" type="button" onClick={discordLogin}/>
                                {/* <InstagramLogin clientId="1671951633329257" scope="user_profile" buttonText="Login with Instagram" onSuccess={responseInstagram} onFailure={responseInstagram} /> */}
                            </div>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default LoginModal;