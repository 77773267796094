import {Container, Button} from '@mui/material';
import { useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CountDown from '../count-down/Countdown';
import BackArrow from '../../assets/arrow-left.svg'

const DropNav = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const backTo = () => {
        navigate('/tank');
    }
    return(
        <Container maxWidth="false">
            <div className='flex justify-start'>
                {(location.pathname != "/utility")?(
                    <Button onClick={backTo} className=' sm:hidden mt-10 flex items-center justify-center border-2 border-white border-solid text-white rounded-[0.625rem] min-w-[3.375rem] min-h-[3.375rem] py-[0.8rem] px-[2.425rem] hover:bg-[#ffffff30] capitalize text-lg'>
                            <img src={BackArrow} alt='back arrow' className='w-8 mr-2'/> Back
                    </Button>
                ):('')}
                </div>
            <div className={`flex justify-between items-center relative my-10  ${(location.pathname == '/utility') ?'flex-row-reverse':'flex-col md:flex-row-reverse'}`}>
                {(props.navDetails.logo2)?(
                    <div className='flex items-center flex-col sm:flex-row'>
                        <img src={props.navDetails.logo} alt='logo' className='w-full max-w-[9.375rem]'/>
                        <span className='text-white font-sans font-medium text-3xl sm:mx-10 my-4 sm:my-0'>x</span>
                        <img src={props.navDetails.logo2} alt="logo" className='w-full max-w-[9.375rem]'/>
                    </div>
                ):(
                <div className='flex items-center flex-col sm:flex-row'>
                    <img src={props.navDetails.logo} alt="logo" className={`w-full ${(location.pathname == '/utility') ? 'max-w-[9.375rem]':'max-w-[16rem] md:max-w-[9.375rem]'}`}/>
                </div>
                )} 
                <div className={`text-white font-sans font-medium text-3xl   ${(location.pathname == '/utility') ?'mt-0':'mt-4 sm:mt-0'}`}>
                    {(location.pathname == "/utility")?(
                        <Button onClick={backTo} className='sm:flex items-center justify-center border-2 border-white border-solid text-white rounded-[0.625rem] min-w-[3.375rem] min-h-[3.375rem] py-[0.8rem] px-[2.425rem] hover:bg-[#ffffff30] capitalize text-lg'>
                            <img src={BackArrow} alt='back arrow' className='w-8 mr-2'/> Back
                        </Button>
                    ):('')}
                    {/* <CountDown timmer={props.navDetails.expire_date}/> */}
                </div>
            </div>
        </Container>
    )
}
export default DropNav;