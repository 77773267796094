import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import DropCompCreate from '../components/common-comp/Drop_comp_create';
import FooterNav from '../components/nav/Nav-footer';
import { useForm } from "react-hook-form";
import SuccessModal from '../components/modal/Modal_success';
import moment, { utc } from 'moment';
import axios from 'axios';
import LoadingModal from '../components/modal/Modal_loading';
import WarningModal from '../components/modal/Modal_warning';

const CreateDrop = (props) => {
    const navigate = useNavigate();
    const {register,reset, handleSubmit,formState: { errors }} = useForm({});
    const [openSuccess, setOpenSuccess]     = useState(0);
    const [disableNext, setDisableNext]     = useState(false);
    const [displayChecks, setDisplayChecks] = useState({'dec':true,'dlc':false,'dtr':false});
    const user_id      = localStorage.getItem('nift_user_id');
    const temp_user_id = localStorage.getItem('lh_trmp_user_id');
    const [submitAction, setSubmitAction] = useState('next');
    const [openWarning, setOpenWarning]     = useState(false);

    const back = () => {
        navigate('/criteria',{state: {'action':'back-and-next'}})
    }

    const submit = (data) => {

        if(submitAction == 'next' || submitAction == 'cancel') {

            let tempData = JSON.parse(localStorage.getItem('lh_setupform_data'));
            tempData.rewardDescription = data.rewardDescription;

            if(displayChecks.dec == true)
                tempData.display_criteria = 1;
            else
                tempData.display_criteria = 0;

            if(displayChecks.dlc == true)
                tempData.display_countdown = 1;
            else
                tempData.display_countdown = 0;
            
            if(displayChecks.dtr == true)
                tempData.display_time_remaining = 1;
            else
                tempData.display_time_remaining = 0; 
        
            setOpenSuccess(submitAction !== 'cancel'?1:2);
            localStorage.setItem('lh_setupform_data',JSON.stringify(tempData));   
            createAndUpdateReward(submitAction);
        }
        //navigate('/sign_up');
    }

    const createAndUpdateReward = async (submitAction) => {
        const url = 'https://api.drop.fish/api-flow/create-reward';
        //const url = 'http://localhost/lighthouseapi/api-flow/create-reward';
        const formData = new FormData();

        if(localStorage.getItem('lh_logo_image'))
            formData.append('logo_image',localStorage.getItem('lh_logo_image'));

        if(localStorage.getItem('lh_form_rewardImage'))
            formData.append('reward_image',localStorage.getItem('lh_form_rewardImage'));

        if(localStorage.getItem('lh_background_image'))
            formData.append('background_image',localStorage.getItem('lh_background_image'));
        
        if(localStorage.getItem('lh_setupform_data')) {

            const setData = JSON.parse(localStorage.getItem('lh_setupform_data'));

            const utcLiveDate = moment(setData.liveDate);
            utcLiveDate.utc();

            const utcLiveTime = moment(setData.liveTime);
            utcLiveTime.utc();

            setData.liveDate = utcLiveDate.format('MM/DD/YYYY')+' '+utcLiveTime.format('h:mm A');
            
            setData.liveTime = utcLiveTime.format('MM/DD/YYYY')+' '+utcLiveTime.format('h:mm A');

            formData.append('setupform_data',JSON.stringify(setData));
        }   

        if(user_id)
            formData.append('user_id',user_id);
        else if(temp_user_id)
            formData.append('user_id',temp_user_id);

        if(localStorage.getItem('lh_edit_drop'))
            formData.append('drop_id',localStorage.getItem('lh_edit_drop'));

        formData.append('access_token', localStorage.getItem("lh_access_token"));

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
              localStorage.setItem('lh_edit_drop',response.data.data.drop_id);
              
              if(response.data.data.temp_user){
                  localStorage.setItem('lh_trmp_user_id',response.data.data.user_id);
              }

              if(submitAction != 'cancel') {
                if(user_id)
                    navigate('/drop');
                else
                    navigate('/sign_up',{state:{'action':'back-and-next'}});
              }
              else {
                  setOpenSuccess(0);
                  setOpenWarning(true);
              }
            }
            else if(response.data.message == 'invalid_token'){
              props.getOAuthToken().then(res => {
                createAndUpdateReward(submitAction);
              });
            }
        });
    }

    return(
        <div className='text-white mb-[10.125rem]'>
            <WarningModal setOpenWarning={setOpenWarning} openWarning={openWarning} />  
            <LoadingModal setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} mid={3}/> 
            <form onSubmit={handleSubmit(submit)} >
                <DropCompCreate btnAction={submitAction} reset={reset} register={register} displaychecks={displayChecks} errors={errors} />
                <FooterNav displayCheks={displayChecks} setDisplayChecks={setDisplayChecks} btnAction={setSubmitAction} disableNext={disableNext} back={back} />
            </form>
        </div>
    )
}

export default CreateDrop;