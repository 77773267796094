import {Card, CardActions, CardContent, CardMedia, Typography, Button} from '@mui/material';

import ButtonCard from '../button/Btn_card';

import inforImg from '../../assets/info-img.png';
import CreateImg from '../../assets/add-line.svg';

const CreateCollectionCard = (props) =>{
    

    const onClick = () => {
    }

    return(
        <Card className='rounded-3xl bg-darckGray shadow-none'>
        <CardContent className='bg-darckGray p-0 m-4 border-2 border-onyx border-solid rounded-2xl flex justify-center items-center flex-col md:min-h-[44.7rem] md:max-h-[44.7rem] min-h-[46.44rem] max-h-[46.44rem]'>
            <CardMedia
                className='min-h-[6.25rem] max-h-[6.25rem] max-w-[6.25rem]'
                component="img"
                alt="add collection"
                image={CreateImg}
            />
            <Typography variant="textBase" align='center' className='mt-7 no-underline' component="div">
                Create a drop
            </Typography>
        </CardContent>
        </Card>
    )
}

export default CreateCollectionCard;