import React, {useState, useRef,useEffect } from 'react';
import {ThemeProvider, Typography, FormControl,OutlinedInput,InputLabel, Container} from '@mui/material';
import { Link, useNavigate,useLocation } from "react-router-dom"
import axios from 'axios';
import { ThemeCustom} from "../styles/Theme";
import ButtonMain from '../components/button/Button_main';
import logo from '../assets/logo-lg.svg'
import SuccessModal from '../components/modal/Modal_success';
import { useForm } from "react-hook-form";
import ErrorAlert from '../components/alerts/Fail_alert';
import SuccessAlert from '../components/alerts/Success_alert';

const SignIn = (props) => {
    const location  = useLocation();
    const state     = location && location.state?location.state:0;

    const [walletMerge, setWalletMerge]   = useState(0);
    const [dropText, setDropText]         = useState(0);
    const [openSuccess, setOpenSuccess]   = useState(false);
    const [emailLogin, setEmailLogin]     = useState(true)
    const [email, setEmail]               = useState()
    const {register,handleSubmit,formState: { errors }} = useForm();
    const navigate = useNavigate();
    const [inputone, setInputone]     = useState();
    const [inputtwo, setInputtwo]     = useState();
    const [inputthree, setInputthree] = useState();
    const [inputfour, setInputfour]   = useState();

    const [openFail, setOpenFail]     = useState(false);
    const [textFail, setTextFail]     = useState();
    const [textSuccess, setTextSuccess] = useState();
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    useEffect(()=>{
        if(state && state.email){
            setEmailLogin(false);
            setEmail(state.email);
            setDropText(state.drop_text);
            setWalletMerge(1); 
        }
    },[state]);

    const signin = (e) =>{
        e.preventDefault();
        setOpenSuccess(true)
    }

    const resendEmail = (e) => {
        next({'email':email});
    }

    useEffect(()=>{
      console.log(errors)
    },[errors]);

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)
    },[openFail]);

    useEffect(()=>{
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    const handleInputChange = (e,index) => {
        const numericValue = e.target.value.replace(/\D/g, '');
        const digitValue = numericValue.slice(0, 1);
        
        if(index == 1) {
            setInputone(digitValue);
            //inputRef2.current.focus();
        }
        else if(index == 2) {
            setInputtwo(digitValue)
           
        }
        else if(index == 3) {
            setInputthree(digitValue)
           
        }
        else {
            setInputfour(digitValue);
           
        }
        
        if (digitValue && index < inputRefs.length) {
            inputRefs[index].current.focus();
        } 
    };

    const next = (data) => {
        const url = 'https://api.drop.fish/api-flow/email-login';
        //const url = 'http://localhost/lighthouseapi/api-flow/email-login';
        const formData = new FormData();
        formData.append('email',data.email);
        formData.append('access_token', localStorage.getItem("lh_access_token"));
        setEmail(data.email);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        //setEmailLogin(false);
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
                setTextSuccess('A verification code has been sent. Please check your email.');
                setOpenSuccess(true);
                setEmailLogin(false);
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    next(data);
                });
            }
        });
    }

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text/plain').replace(/\D/g, '').slice(0, 4);
        
        // Distribute pasted data to each input field
        for (let i = 0; i < Math.min(pastedData.length, inputRefs.length); i++) {
          const digit = pastedData[i];
          setInputByIndex(i + 1, digit);
          handleInputChangeSimulated(i + 1, digit); 
        }
    };

    const handleInputChangeSimulated = (index, value) => {
        const input = inputRefs[index - 1].current;
        const inputEvent = new Event('input', { bubbles: true });
    
        Object.defineProperty(input, 'value', {
            value: value,
            writable: true
        });
    
        input.dispatchEvent(inputEvent);
    };

    const setInputByIndex = (index, value) => {
        switch (index) {
          case 1:
            setInputone(value);
            break;
          case 2:
            setInputtwo(value);
            break;
          case 3:
            setInputthree(value);
            break;
          case 4:
            setInputfour(value);
            break;
          default:
            break;
        }
    };

    const codeSubmit = (data) => {
        const code = data.inputOne+data.inputTwo+data.inputThree+data.inputFour
        const url = 'https://api.drop.fish/api-flow/email-login';
        //const url = 'http://localhost/lighthouseapi/api-flow/email-login';
        const formData = new FormData();
        formData.append('email',email);
        formData.append('code',code);
        formData.append('merge',walletMerge);
        formData.append('drop_text',dropText);
        formData.append('access_token', localStorage.getItem("lh_access_token"));
        setEmail(data.email);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
                localStorage.setItem('user_name',response.data.data.name);
                localStorage.setItem('nift_user_id',response.data.data.user_id);
                localStorage.setItem('auth_type',response.data.data.auth_type);
                localStorage.setItem('drop_text',response.data.data.dropText);
                if(dropText !== 0) {
                    if(walletMerge)
                        navigate('/tank')
                    else
                        navigate('/'+dropText)
                }
                else
                    navigate('/tank')
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    codeSubmit(data);
                });
            }
            else {
                setTextFail('Invalid code, please check the code again.');
                setOpenFail(true);
            }
        });
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='fixed top-12 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
           {/*  <SuccessModal setOpenSuccess={setOpenSuccess} openSuccess={openSuccess}/>   */} 
            <Container maxWidth="xl">
                {emailLogin?(
                <div className='h-[100vh] items-center justify-center flex-col flex'>
                    <img src={logo} alt='logo' className='w-full max-w-[15rem] mb-20'></img>
                        <Typography variant='header50'>Login with email</Typography>
                        <Typography variant='text24Gray' className='mt-5 mb-20 text-center'>Let's make some magic happen</Typography>
                        <form className='w-full max-w-[27.5rem]' onSubmit={handleSubmit(next)} >
                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                                <InputLabel shrink htmlFor="email-input">Email</InputLabel>
                                <OutlinedInput 
                                    id="email-input"
                                    autoComplete="off" 
                                    placeholder="Enter email" 
                                    type='email'
                                    {...register("email",{ required: true })} 
                                />
                                {errors?.email?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                            </FormControl>
                            <ButtonMain type='submit' variant='goGreenBtn' text='Next' classes='w-full uppercase'/>
                        </form>
                    {/* <Typography variant='text18Gray' className='mt-16'>I don't have an account <Link to={'/sign_up'} className='no-underline text-white'>Sign up</Link></Typography> */}
                </div>
                ):(
                <div className='h-[100vh] flex items-center justify-center flex-col'>
                    <img src={logo} alt='logo' className='w-full max-w-[15rem] mb-20'></img>
                        <Typography variant='header50'>Enter 4 digit code</Typography>
                        {email?(
                            <Typography variant='text24Gray' onClick={()=>{setEmailLogin(true)}} className='mt-5 mb-20 text-center cursor-pointer'><span className='underline'>{email}</span>{' >'}</Typography>
                        ):('')}
                        <form className='w-full max-w-[27.5rem]' onSubmit={handleSubmit(codeSubmit)} >
                            <div className='flex' onPaste={handlePaste}>
                                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col max-w-[6rem]'>
                                    <InputLabel shrink htmlFor="email-input" className='p-4'></InputLabel>
                                        <OutlinedInput 
                                           
                                            id="verify-input-1"
                                            autoComplete="off" 
                                            type="number"
                                            {...register("inputOne",{ required: true})}
                                            value={inputone}
                                            inputRef={inputRefs[0]}
                                            sx={{'input':{textAlign:'center'}}}
                                            onChange={(e) => handleInputChange(e,1)}
                                            
                                        />
                                </FormControl> 
                                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col max-w-[6rem] mr-1 ml-2'>
                                    <InputLabel shrink htmlFor="email-input" className='p-4'></InputLabel>
                                        <OutlinedInput 
                                           
                                            id="verify-input-2"
                                            autoComplete="off" 
                                            type="number"
                                            {...register("inputTwo",{ required: true})}
                                            value={inputtwo}
                                            inputRef={inputRefs[1]}
                                            sx={{'input':{textAlign:'center'}}}
                                            name="inputTwo"
                                            onChange={(e) => handleInputChange(e,2)} 
                                            
                                        />
                                </FormControl>
                                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col max-w-[6rem] mr-2 ml-1'>
                                    <InputLabel shrink htmlFor="email-input" className='p-4'></InputLabel>
                                    <OutlinedInput 
                                        
                                        id="verify-input-3"
                                        autoComplete="off" 
                                        type="number"
                                        {...register("inputThree",{ required: true})}
                                        value={inputthree}
                                        inputRef={inputRefs[2]}
                                        sx={{'input':{textAlign:'center'}}}
                                        name="inputThree"
                                        onChange={(e) => handleInputChange(e,3)}
                                        
                                    />
                                </FormControl>
                                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col max-w-[6rem]'>
                                    <InputLabel shrink htmlFor="email-input" className='p-4'></InputLabel>
                                    <OutlinedInput 
                                        
                                        id="verify-input-4"
                                        autoComplete="off" 
                                        type="number"
                                        {...register("inputFour",{ required: true})}
                                        value={inputfour}
                                        inputRef={inputRefs[3]}
                                        sx={{'input':{textAlign:'center'}}}
                                        name="inputFour" 
                                        onChange={(e) => handleInputChange(e,4)}
                                        
                                    />
                                </FormControl>
                                </div>
                                {/*errors.username && <span className='err-msg'>Email is required.</span>*/}
                            <ButtonMain type='submit' variant='goGreenBtn' text='Verify' classes='w-full uppercase'/>
                        </form>
                        <Typography variant='text18Gray' className='mt-16'>I haven't receive the code. <Typography onClick={(e)=>resendEmail(e)} variant='textBase' className='text-goGreen cursor-pointer'>Resend Now</Typography></Typography>
                    </div>
                )}
            </Container>    
        </ThemeProvider>
    )
}

export default SignIn;