import React, {useState, useEffect} from 'react';
import {ThemeProvider, Typography, Container} from '@mui/material';
import ButtonPrimary from '../button/Btn_primary';
import { ThemeCustom} from "../../styles/Theme";
import axios from 'axios';
import { useNavigate, useLocation,useParams } from 'react-router-dom';
import SuccessAlert from '../alerts/Success_alert';
import PendingAlert from '../alerts/Pending_alert';
import ErrorAlert from '../alerts/Fail_alert';
import WaitModal from '../modal/Modal_wait';
import logo from '../../assets/logo-sm.svg';
import fishIcon from '../../assets/fish-icon.svg';
import dropload from '../../assets/drop-load.gif';
import ButtonImg from '../button/Button_img';
import Youtube from '../../assets/Youtube_Logo.svg'
import CountDown from '../count-down/Countdown';
import momentTimezone from 'moment-timezone';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, } from 'swiper/modules';
import QRCode from "react-qr-code";
import copy from 'copy-to-clipboard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const customStyles = {
    navigation: {
      nextEl: '.swiper-next-button',
    },
};

const DropCompShare = (props) => {
    let userId;
    let userAddress;
    const copy_button = {btnName: "COPY CODE", btnLink: "#", color: "#F7941D"};
    const share_button = {btnName: "SHARE YOUR DROP", btnLink: "#", color: "#039503"};
    
    const searchParams = new URLSearchParams(window.location.search);
    const tw_token = searchParams.get('token');
    const navigate = useNavigate();
    const params   = useParams();
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [openPending, setOpenPending] = useState(false);
  
    const [textPending, setTextPending] = useState();
    const [textSuccess, setTextSuccess] = useState();
    const [textFail, setTextFail]   = useState();
    const [btnstatus, setBtnstatus] = useState('no');
    const [openWait, setOpenWait]   = useState(false);
    const location = useLocation();

    const [ gooleuser, setGoogleUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    const [endtime, setEndTime]   = useState();

    const display_time   = momentTimezone.unix(props.drop.start_time).tz('UTC').format('YYYY-MM-DD HH:mm:ss');
    const countdown_time = momentTimezone.unix(props.drop.expire_date).tz('UTC').format('YYYY-MM-DD HH:mm:ss');

    const [displayTimer, setDisplayTimer] = useState(props.drop.start_time > 0 ?1:0);
    const [displayTimeRemain, setDisplayTimeRemain] = useState(props.drop.expire_date > 0 ?1:0);

    useEffect(()=>{
        if(tw_token == 9) {
            navigate("/info",{
                state: '#9'
            });
        }
        else if(tw_token == 0) {
            navigate("/info",{
                state: props.drop.drop_text
            });
        }
    },[tw_token]);

    const shareLink = () => {
        const tweetText = "I just unlocked "+props.drop.title+" from "+props.drop.brand+". You can check if you're eligible for this drop below.";
        const tweetUrl = 'http://api.drop.fish/drops?d='+props.drop.nft_id; 
    
        const tweetUrlEncoded = encodeURIComponent(tweetUrl);
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${tweetUrlEncoded}`;

        window.open(twitterShareUrl);
        // window.open("https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fwww.drop.fish%2F7&text=Generative%20art%20based%20on%20the%20lyrics%20to%20%E2%80%9CLucy%20in%20the%20Diamonds%E2%80%9D%20by%20the%20Beatles.&url=https%3A%2F%2Fwww.drop.fish%2F7%2F", '_blank');
    }

    const copyLink = () => {
        copy(props.drop.property_link);
    }

    const useLink = () => {
        window.open(props.drop.drop_use_link, '_blank');
    }

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)
    },[openFail]);
  
    useEffect(()=>{
        setTimeout(()=>{
           setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    useEffect(()=>{
        setTimeout(()=>{
            setOpenPending(false);
        },10000)
    },[openPending]);

    return(
        <>
        <WaitModal setOpenWait={setOpenWait} openWait={openWait}/>
        <div className='fixed top-12 right-8 w-full z-[1999] h-0'>
            <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
            <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            <PendingAlert setOpenPending={setOpenPending} openPending={openPending} alretText={textPending}/>
        </div>
        <ThemeProvider theme={ThemeCustom}>
            <div className='relative'>
                <div className='lg:mb-12 lg:absolute w-full top-[-5.75rem] z-[-1]'>
                    {(props.drop.tw_name)?(
                        <Typography variant='header50' component={'div'} className='md:mt-0 text-center break-all uppercase sm:break-normal text-3xl sm:text-4xl' >Congratulations {props.drop.tw_name}!</Typography>
                    ):(
                        <Typography variant='header50' component={'div'} className='md:mt-0 text-center break-all uppercase sm:break-normal text-3xl sm:text-4xl' >Congratulations!</Typography>
                    )}
                    <Typography variant='text30' component={'div'} className='mt-3 text-center line-clamp-2'>You have been whitelisted and unlocked {props.drop.title}</Typography>
                </div>
            </div>
            <Container maxWidth="false" >
                <div className='lg:mt-28'>
                    <div className='flex items-center justify-center md:flex-row flex-col w-full mt-12'>
                        <div className='max-w-[30rem] sm:max-w-[70vw]'> 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={50}
                            slidesPerView={1}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            navigation
                        >
                            <SwiperSlide>
                                <div className='relative'>
                                    <div className='md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem] max-w-[27rem] m-auto'>   
                                        <img src={props.drop.img} alt='unlock drop' className='w-full max-w-[27rem] object-top rounded-3xl object-cover md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem]'/>
                                    </div>
                                    <div className='h-[98%] absolute bottom-0 left-1/2 translate-x-1/2M w-full bg-gradient flex flex-col justify-between border-4 border-solid border-white rounded-3xl max-w-[27rem] '>
                                        <div className='pt-5 w-full flex justify-between px-4 items-start max-w-[25rem]'>
                                            <Typography variant="text14Code" align='left' className='line-clamp-2 w-1/2 pl-1 ' component="div">
                                                {props.drop.brand}
                                            </Typography>
                                            <Typography variant="text14Code" align='right' className='line-clamp-2 w-1/2 pr-1' component="div">
                                                {props.drop.title}
                                            </Typography>
                                        </div>
                                        <div className=' pb-5 w-full flex flex-col justify-between px-4 max-w-[25rem]'>
                                            <div className='flex justify-between pb-2 items-end'>
                                                <Typography variant="text14TwitterTag" align='left' className='line-clamp-2 w-5/12' component="div">
                                                    {(props.drop.tw_name != 'XXX')?(
                                                        '@'+props.drop.tw_name
                                                    ):('')}
                                                </Typography>
                                                <Typography variant="text14Code" align='right' className='line-clamp-2 w-5/12 flex justify-end' component="div">
                                                {(props.drop.serial_number =='...')?(
                                                    <img src={dropload} alt='loading' className='max-w-[1.5rem]'/>
                                                ):(props.drop.serial_number)}
                                                </Typography>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <Typography variant="text14Code" align='left' className='line-clamp-2 w-full flex items-center' component="div">
                                                <img src={fishIcon} alt='fish' className='w-full max-w-[0.5rem] mr-2' /> {props.drop.user_number}
                                                </Typography>
                                                <img src={logo} alt='logo' className='w-full max-w-[6rem] opacity-50' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {props.drop.property == 2 && props.drop.property_link && props.drop.property_link.length > 0? 
                                (<SwiperSlide>
                                    <div className='md:min-h-[26.5rem] md:max-h-[26.5rem] sm:min-h-[28.25rem] max-h-[28.25rem] md:max-w-[26.5rem] max-w-[27.714rem] min-w-[27.714rem] min-h-[28.25rem] rounded-3xl bg-white w-full m-auto flex flex-col justify-evenly items-center'>   
                                        <QRCode value={props.drop.property_link} className='w-full max-w-[20rem] object-top object-cover md:min-h-[20rem] md:max-h-[22rem] min-h-[25.036rem] max-h-[25.036rem]' />
                                    </div>
                                </SwiperSlide>)
                            :('')}
                            {props.drop.property != 2 && props.drop.property_link && props.drop.property_link.length > 0?
                                (<SwiperSlide>
                                    <div className='md:min-h-[26.5rem] md:max-h-[26.5rem] sm:min-h-[28.25rem] max-h-[28.25rem] md:max-w-[26.5rem] max-w-[27.714rem] min-w-[27.714rem] min-h-[28.25rem] rounded-3xl bg-white w-full m-auto flex flex-col justify-evenly items-center'>   
                                        <Typography variant='text30' component={'div'} className='mt-3 text-black text-center line-clamp-2 font-bold'>{props.drop.property_link}</Typography>
                                        <ButtonPrimary classes='w-full sm:w-auto max-w-[80%]' type='button' onClick={copyLink} buttonProp={copy_button}/>                                
                                    </div>
                                </SwiperSlide>)
                            :('')} 
                        </Swiper>
                        </div>
                    </div>                
                    {(props.drop.use_link_text.length > 1)?(
                        props.drop.use_link_text.map((textItem, index)=> (
                            <div key={index} className='flex mt-5 justify-center'>
                                 <div className='flex items-center'>
                                    <Typography variant='text30' component={'div'} className='me-2 self-start mt-4'>{index+1}.</Typography>
                                    <Typography variant='text30' component={'div'} className='mt-4' dangerouslySetInnerHTML={{__html:textItem}} />
                                </div>

                            </div>
                        ))
                    ):(
                        props.drop.use_link_text.map((textItem, index)=> (
                            <div key={index} className='flex mt-5'>
                                <Typography variant='text30' component={'div'} className='mt-4 w-full text-center xxxx' dangerouslySetInnerHTML={{__html:textItem}} />
                            </div>
                        ))
                    )}
                    {(props.drop.share)?(
                        <Typography variant='text30' component={'div'} className='mt-16 w-full text-center'>
                            <ButtonPrimary classes='w-full sm:w-auto' type='button' onClick={shareLink} buttonProp={share_button}/>
                        </Typography>):
                        ('')
                    }
{/*                     {(props.drop.use_link_button)?(
                    <div className='mt-16 w-full sm:w-auto'>
                        <ButtonPrimary classes='w-full sm:w-auto' type='button' onClick={useLink} disabled={btnstatus} buttonProp={props.drop.button}/>
                    </div>
                    ):('')} */}

                    {(props.drop.dtr == 1 && displayTimeRemain == 1)?(   
                        <CountDown setVisible={setDisplayTimeRemain} type="end" timmer={countdown_time} dropText={0}/>
                    ):('')}
                </div>
            </Container>    
        </ThemeProvider>
        </>
    );
}

export default DropCompShare;