import React, {useState, useEffect} from 'react';
import {ThemeProvider, Typography, Container, FormControl, InputLabel, CardContent, CardMedia, TextField} from '@mui/material';
import ButtonMain from '../button/Button_main';
import { ThemeCustom} from "../../styles/Theme";
import SuccessAlert from '../alerts/Success_alert';
import PendingAlert from '../alerts/Pending_alert';
import ErrorAlert from '../alerts/Fail_alert';
import WaitModal from '../modal/Modal_wait';
import DropImg from '../../assets/Image-77.png'
import CreateImg from '../../assets/add-fill.svg';
import DeleteImg from '../../assets/delete-fill.svg';
import bgImg from '../../assets/admin-bg.png';
import DropLogo from '../../assets/notion-logo.svg';
import CountDown from '../count-down/Countdown';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { data } from 'autoprefixer';
import { FileUploader } from "react-drag-drop-files";

const button = {color:'#000', btnName:'Unlock Drop'}

const DropCompCreate = (props) => {
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail]       = useState(false);
    const [openPending, setOpenPending] = useState(false);
    const [textPending, setTextPending] = useState();
    const [textSuccess, setTextSuccess] = useState();
    const [textFail, setTextFail]       = useState();
    const [btnstatus, setBtnstatus]     = useState('no');
    const [openWait, setOpenWait]       = useState(false);
    const [logoImage, setLogoImage]     = useState();
    const [background, setBackground]   = useState();
    const [bgName, setBgName]           = useState();
    const [bgSize, setBgSize]           = useState();
    const [rewardImage, setRewardImage] = useState();
    const reader                        = new FileReader();
    const [criteriaformData, setCriteriaformData] = useState();
    const [setupformData, setSetupformData]       = useState();
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const setupform_data = localStorage.getItem('lh_setupform_data');
    const criteriaform_data = localStorage.getItem('lh_criteriaform_data');

    const countdown_time = momentTimezone.unix(localStorage.getItem('lh_countdown_time')).format('YYYY-MM-DD HH:mm:ss');
    const display_time   = momentTimezone.unix(localStorage.getItem('lh_display_time')).format('YYYY-MM-DD HH:mm:ss');

    const [displayTimer, setDisplayTimer] = useState(display_time?1:0);
    const [displayTimeRemain, setDisplayTimeRemain] = useState(countdown_time?1:0);

    useEffect(()=>{
        setTimeout(()=>{
           setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    useEffect(()=>{
        setTimeout(()=>{
            setOpenPending(false);
        },10000)
    },[openPending]);

    useEffect(() => {
        let setupData = JSON.parse(setupform_data);
        setSetupformData(setupData);
        props.displaychecks.dlc = setupData.display_countdown;
        props.displaychecks.dec = setupData.display_criteria;
        props.displaychecks.dtr = setupData.display_time_remaining;
    },[setupform_data]);

    useEffect(() => {
        setCriteriaformData(JSON.parse(criteriaform_data));
        /*  */
    },[criteriaform_data]);

    useEffect(() => {
        document.body.style.background = `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0) 100%),url(${background}), #000 no-repeat `;
    },[background]);

    useEffect(() => {
        setBackground(localStorage.getItem('lh_background_image'));
        //document.body.style.background = `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0) 100%),url(${localStorage.getItem('lh_background_image')}), #000 no-repeat `;
    },[localStorage.getItem('lh_background_image')]);

    useEffect(() => {
        setBgName(localStorage.getItem('lh_bg_name'));
        setBgSize(localStorage.getItem('lh_bg_size'));
    },[localStorage.getItem('lh_bg_name')]);

    useEffect(() => {
        setRewardImage(localStorage.getItem('lh_form_rewardImage'));   
    },[localStorage.getItem('lh_form_rewardImage')]);

    useEffect(() => {
        setLogoImage(localStorage.getItem('lh_logo_image'));   
    },[localStorage.getItem('lh_logo_image')]);

    const changeLogoHandler = (event) => {
        let image = event.target.files[0];
        reader.readAsDataURL(image);
        reader.addEventListener('load', () => {
            localStorage.setItem('lh_logo_image', reader.result);
            setLogoImage(reader.result);
        });
    };

    const removeBgImage = (e) => {
        setBackground();
        localStorage.removeItem('lh_background_image');
    }

    const removeRewardImage = (e) => {
        setRewardImage();
        localStorage.removeItem('lh_form_rewardImage');
    }

    const removeLogoImage = (e) => {
        setLogoImage()
        localStorage.removeItem('lh_logo_image');
    }

    const changeBackgroundkHandler = (e) => {
        localStorage.removeItem("lh_background_image");
        let imageBackground = e;
        reader.readAsDataURL(imageBackground);
        reader.addEventListener('load', () => {
            localStorage.setItem("lh_background_image", reader.result);
            setBackground(reader.result);
            document.body.style.background = `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0) 100%),url(${reader.result}), #000 no-repeat `;
            
            setBgName(imageBackground.name);
            localStorage.setItem("lh_bg_name", imageBackground.name);
            let fileSize = (imageBackground.size/(1024*1024)).toFixed(2);
            if(fileSize > 1) {
                setBgSize(fileSize + ' MB');
                localStorage.setItem("lh_bg_size", fileSize + ' MB');
            }
            else {
                setBgSize((imageBackground.size/(1024)).toFixed(2) + ' KB');
                localStorage.setItem("lh_bg_size", (imageBackground.size/(1024)).toFixed(2) + ' KB');
            }
        });
    };

    const changeRewardHandler = (e) => {
        let rewardImage = e;
        reader.readAsDataURL(rewardImage);
        reader.addEventListener('load', () => {
            localStorage.setItem("lh_form_rewardImage", reader.result);
            setRewardImage(reader.result);
        });
    };

    useEffect(() => {
        props.reset(props.setupformData);
    }, [props.reset, props.setupformData]);

     //for testings - file drag

     /* const [file, setFile] = useState(null);

     const handleChange = (file) => {
         setFile(file);
     };
 
     useEffect(()=>{console.log('file upload',file)},[file]) */
    

    return(
        <>
        <WaitModal setOpenWait={setOpenWait} openWait={openWait}/>
        <div className='fixed top-12 right-8 w-full z-[1999] h-0'>
            <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
            <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            <PendingAlert setOpenPending={setOpenPending} openPending={openPending} alretText={textPending}/>
        </div>
        <ThemeProvider theme={ThemeCustom}>
            <Container maxWidth="xl">
            <div className='flex justify-between items-center relative my-10 w-full'>
                <div>
                    {(logoImage)?(
                    <div className='relative'>
                        <CardMedia
                            className='min-h-[3.5rem] max-h-[3.5rem] max-w-[3.5rem] absolute top-[-1.5rem] right-[-1.5rem] z-50 cursor-pointer'
                            component="img"
                            alt="Delete image"
                            image={DeleteImg}
                            onClick={(e)=>{removeLogoImage(e)}}
                        />
                        <CardContent className='m-0 border border-platinum border-dashed rounded-2xl flex justify-center items-center min-h-[9.375rem] max-h-[9.375rem] max-w-[28.125rem] w-full p-4'>
                            <div className='max-h-[7.375rem] overflow-hidden rounded-2xl w-full min-w-[28.125rem] px-4'>
                                <img src={logoImage} alt='reward' className='w-full m-0 max-w-[60%]'/>
                            </div>
                        </CardContent>
                    </div>
                    ):('')}
                </div>
                <div>
                    <FormControl variant="standard" fullWidth  margin="normal" className='m-0'>
                    {!(background)?(
                        <>
                        <FileUploader
                            classes={'mb-0 cursor-pointer p-0 bg-[#1A1C1E] rounded-2xl drag-drop'}
                            multiple={false}
                            handleChange={changeBackgroundkHandler}
                            name="file"
                            types={["JPEG","JPG","PNG","GIF","JFIF","PJP","SVG","WEBP"]}
                            children={
                                <CardContent className='bg-[#1A1C1E] p-10 m-0 border border-onyx border-dashed rounded-2xl flex justify-center items-center min-h-[9.375rem] max-h-[9.375rem] min-w-[28.125rem] w-full'>
                                    <CardMedia
                                        className='w-full max-w-[5rem]'
                                        component="img"
                                        alt="Add image"
                                        image={CreateImg}
                                    />
                                    <div className='ml-10'>
                                        <Typography variant="textBase" className='no-underline mb-1 break-words whitespace-break-spaces font-semibold text-xl' component="div">
                                            Upload Background
                                        </Typography>
                                        <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces  text-sm' component="div">
                                            1920px x 1080px recommended JPEG,JPG,PNG,GIF,JFIF,PJP,SVG,WEBP (Max 4MB)
                                        </Typography>
                                    </div>
                                </CardContent>
                            }
                        />
                        </>
                         ):('')}
                    </FormControl>
                    <div>
                        {(background)?(
                            <CardContent className='p-10 m-0 border border-platinum border-dashed rounded-2xl flex justify-start items-center min-h-[9.375rem] max-h-[9.375rem] min-w-[28.125rem] w-full'>
                                <CardMedia
                                    className='w-full max-w-[5rem] cursor-pointer'
                                    component="img"
                                    alt="delete image"
                                    image={DeleteImg}
                                    onClick={(e)=>{removeBgImage(e)}}
                                />
                                <div className='ml-10'>
                                    <Typography variant="textBase" className='no-underline mb-1 break-words whitespace-break-spaces font-semibold text-xl' component="div">
                                        {(bgName)}
                                    </Typography>
                                    <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces  text-sm' component="div">
                                        {(bgSize)}
                                    </Typography>
                                </div>
                            </CardContent>
                        ):('')}
                    </div>
                </div>
            </div>
            <div className='flex items-start flex-col mt-12'>
                <div className='flex items-center flex-row w-full'>
                    {(!rewardImage)?(
                        <>
                            <FormControl variant="standard"  margin="normal" className='mb-10  flex-col max-w-[26.5rem] w-full max-h-[26.5rem] h-full rounded-2xl'>
                            <FileUploader
                                    classes={'max-w-[26.5rem] rounded-2xl drag-drop border border-onyx border-dashed'}
                                    multiple={false}
                                    handleChange={changeRewardHandler}
                                    name="file"
                                    types={["JPEG","JPG","PNG","GIF","JFIF","PJP","SVG","WEBP"]}
                                    children={
                                        <div className='mb-0 cursor-pointer p-4 bg-[#1A1C1E] rounded-2xl  max-w-[26.5rem]'>
                                            <CardContent className='bg-[#1A1C1E] p-0 m-0 rounded-2xl flex justify-center items-center flex-col min-h-[24.375rem] max-h-[24.375rem] max-w-[26.5rem]'>
                                                <CardMedia
                                                    className='min-h-[5rem] max-h-[5rem] max-w-[5rem]'
                                                    component="img"
                                                    alt="Add image"
                                                    image={CreateImg}
                                                    //{...register("rewardImage",{ required: true })} 
                                                />
                                                <Typography variant="textBase" align='center' className='mt-7 no-underline mb-2 font-semibold text-xl' component="div">
                                                    Upload Reward Image
                                                </Typography>
                                                <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces text-center text-sm mx-3' component="div">
                                                    1024px x 1024px recommended JPEG,JPG,PNG,GIF,JFIF,PJP,SVG,WEBP (Max 1MB)
                                                </Typography>
                                            </CardContent>
                                        </div>
                                    }
                                />
                        </FormControl>
                        </>
                    ):(
                        <div className='relative mt-4 mb-10 flex flex-col max-w-[26.5rem] w-full max-h-[26.5rem] h-full border border-platinum border-dashed rounded-2xl'>
                            <CardMedia
                                className='min-h-[3.5rem] max-h-[3.5rem] max-w-[3.5rem] absolute top-[-1.5rem] right-[-1.5rem] z-50 cursor-pointer'
                                component="img"
                                alt="Delete image"
                                image={DeleteImg}
                                onClick={(e)=>{removeRewardImage(e)}}
                            />
                            <CardContent className='m-0 rounded-2xl flex justify-center items-center flex-col min-h-[26.5rem] max-h-[26.5rem] p-4'>
                                <div className='max-h-[25rem] overflow-hidden rounded-2xl w-full'>
                                    <img src={rewardImage} alt='reward' className='w-full h-full m-0 object-cover'/>
                                </div>
                            </CardContent>
                        </div> 
                    )}
                    <FormControl variant="standard"  margin="normal">
                        <TextField
                            value={setupformData?.rewardName}
                            id="drop-title"
                            multiline
                            placeholder="Enter drop title here..."
                            variant="standard"
                            className='ml-12 mt-0 text-left' 
                            sx={{ 
                                '& .MuiInputBase-input':{
                                    fontWeight: '700 !important',
                                    fontSize:'3.125rem',
                                }
                            }}
                        />
                        <TextField
                            id="drop-description"
                            multiline
                            defaultValue={setupformData?.rewardDescription}
                            placeholder="Enter drop description here..."
                            variant="standard"
                            className='ml-12 mt-0 text-left' 
                            sx={{ 
                                '& .MuiInputBase-input':{
                                    fontSize:'1.875rem',
                                }
                            }}
                            {...props.register("rewardDescription", { required: (props.btnAction !== 'cancel')?true:false})}
                        />
                        {props.errors?.rewardDescription?.type === "required" && <p className='text-salsaRed ml-16'>This field is required</p>}
                    </FormControl>
                    </div>
                    {(props.displaychecks.dec != false && (criteriaformData?.platform == 'YouTube' || criteriaformData?.platform == 'Twitter' || criteriaformData?.platform == 'Discord'))?(
                        <div>
                            <Typography variant='text30Green' component={'div'} className='my-14' >To unlock:</Typography>
                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-3 mt-0'>
                                {criteriaformData?.platform == 'Twitter'?(
                                    criteriaformData?.social_criteria?.map((textItem, index)=> (
                                        <div key={index} className='flex'>
                                            <Typography variant='text30' component={'div'} className='me-2'>{index+1}.</Typography>
                                            {(textItem == 'Follow')?(
                                                <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem+' <a href="'+criteriaformData.social_inputs[index]+'" target="blank" class="drop_link">this page</a> '}} />
                                            ):(
                                                <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem+' <a href="'+criteriaformData.social_inputs[index]+'" target="blank" class="drop_link">this tweet</a> '}} />
                                            )}
                                        </div>
                                    ))
                                ):('')}

                                {criteriaformData?.platform == 'YouTube'?(    
                                    criteriaformData?.social_criteria?.map((textItem, index)=> (
                                        <div key={index} className='flex'>
                                            <Typography variant='text30' component={'div'} className='me-2'>{index+1}.</Typography>
                                            {(textItem == 'Subscribe')?(
                                                <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem+' to <a href="'+criteriaformData.social_inputs[index]+'" target="blank" class="drop_link">this channel</a> '}} />
                                            ):(
                                                <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem+' <a href="'+criteriaformData.social_inputs[index]+'" target="blank" class="drop_link">this video</a> '}} />
                                            )}
                                        </div>
                                    ))

                                ):('')}

                                {criteriaformData?.platform == 'Discord'?(
                                    criteriaformData?.social_criteria?.map((textItem, index)=> (
                                        <div key={index} className='flex'>
                                            <Typography variant='text30' component={'div'} className='me-2'>{index+1}.</Typography>
                                            {criteriaformData.social_inputs_extra[index].length > 1?(
                                                criteriaformData.social_inputs_label[index].length > 1?(
                                                    <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem+' <a href="'+criteriaformData.social_inputs[index]+'" target="blank" class="drop_link">this guild with role "'+criteriaformData.social_inputs_label[index]+'"</a> '}} />
                                                ):(
                                                    <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem+' <a href="'+criteriaformData.social_inputs[index]+'" target="blank" class="drop_link">this guild with role "'+criteriaformData.social_inputs_extra[index]+'"</a> '}} />
                                                )
                                            ):(
                                                <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem+' <a href="'+criteriaformData.social_inputs[index]+'" target="blank" class="drop_link">this guild</a> '}} />
                                            )}
                                        </div>
                                    ))
                                ):('')}
                            </FormControl>
                        </div>
                    ):('')}
                    {(props.displaychecks.dlc != false)?(
                        <CountDown setVisible={setDisplayTimer} type="start" timmer={moment(countdown_time)} dropText={1} />
                    ):('')}
                    <div className='mt-16 w-full sm:w-auto'>
                        <ButtonMain classes='w-full sm:w-auto rounded-[2.5rem]' type='button' variant='whiteBtn' disabled={btnstatus}  onClick={()=>{}} buttonProp={button} text='Unlock Drop'/>
                        {(props.displaychecks.dtr != false)?(
                        <CountDown setVisible={setDisplayTimeRemain} type="end" timmer={moment(display_time)} dropText={0}/>
                        ):('')}
                    </div>
                </div>
            </Container>
        </ThemeProvider>
        </>
    );
}

export default DropCompCreate;