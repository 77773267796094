import React, {useState, useEffect} from 'react';
import ButtonMainImg from '../button/Button_main_img'
import { ThemeProvider, FormControl, Button, OutlinedInput } from "@mui/material";
import { ThemeCustom} from "../../styles/Theme";
import MailImg from '../../assets/mail.svg';
import DelImg from '../../assets/trash.svg';
import AddInputCriteria from './Add_input_criteria';

const AddInputCsv = (props) => {

    const [emailInputs, setEmailInputs] = useState((props.addedEmail && props.addedEmail.length > 0)?props.addedEmail:[]);

/*      const addEmail = () => {
        props.setFormValidation(true);
        setEmailInputs([...emailInputs, '']);
    } */
    
    const removeEmail = (index) => {
        setEmailInputs(emailInputs.filter((_, i) => i !== index));
    }

/*     const handleEmailChange = (e, index) => {
        const updatedEmailInputs = [...emailInputs];
        updatedEmailInputs[index] = e.target.value;
        setEmailInputs(updatedEmailInputs);
        props.setAddEmails(updatedEmailInputs);
    } */

    return(
        <ThemeProvider theme={ThemeCustom}>
            {emailInputs.map((input, index) => (
                <FormControl key={index} variant="standard" fullWidth margin="normal" className='flex mb-5 mt-0'>
                <OutlinedInput
                    id={`API-input-${index}`}
                    autoComplete="off"
                    placeholder="Enter email"
                    type='text'
                    name={`email-${index}`}
                    aria-label='email'
                    value={input.email}
                    /* onChange={(e) => handleEmailChange(e, index)} */
                />
                {emailInputs.length > 1 && input.status != 1 ? (
                <Button className='bg-red rounded-lg min-w-[3.795rem] min-h-[3.795rem] absolute right-[-64px]' onClick={() => removeEmail(index)}>
                    <img src={DelImg} alt='icon' className='w-6' />
                </Button>
                ):''}
                </FormControl>
            ))}
           {/*  <ButtonMainImg text="Enter Email" img={MailImg} variant={'outlineBtnWhite'} classes="flex items-center" type="button" onClick={addEmail} /> */}

        </ThemeProvider>
    );
}

export default AddInputCsv;