import React, {useState, useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import NavBar from '../components/nav/Nav';
import {ThemeProvider, Typography, Container, FormControl, InputLabel, OutlinedInput, Select, MenuItem, CardContent, CardMedia} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import FooterNav from '../components/nav/Nav-footer';
import AddInput from '../components/input-add/Add_input'
import AddInputCsv from '../components/input-add/Add_input_forcsv';
import AdvancedDropdown from '../components/input/Advanced_dropdown';
import AddInputCriteria from '../components/input-add/Add_input_criteria';
import { ThemeCustom} from "../styles/Theme";
import NavStyle from '../components/nav/Nav_style.module.scss';
import axios from 'axios';
import { useForm } from "react-hook-form";
import CreateImg from '../assets/add-fill.svg';
import DeleteImg from '../assets/delete-fill.svg';

import LoadingModal from '../components/modal/Modal_loading';
import WarningModal from '../components/modal/Modal_warning';
import { FileUploader } from "react-drag-drop-files";

const Email = ['Upload List', 'Add Emails']
const Platform = ['Twitter', 'YouTube','Discord','Instagram' /* 'Facebook', 'Snapchat', 'Spotify', 'Amazon Music', 'Netflix', 'Microsoft', 'Apple' */]
const Ecommerce = ['Amazon', 'Walmart']
const Twitter =  ['Follow', 'Like', 'Retweet', 'Quote Tweet']
const Google =  ['Subscribe', 'Like']
const Meta =  ['Follow', 'Like', 'Share']
const Spotify =  ['Subscribe', 'Like', 'Starred Song', 'Added to Playlistt']
const AmazonMusic =  ['Subscribe', 'Like', 'Favorited Song', 'Added to Playlistt']
const Netflix =  ['Added to List', 'Watched']
const Discord =  ['Join']
const Instagram =  ['User Name']

function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

const SetUpCriteria = (props) => {
    const {register, handleSubmit,formState: { errors }} = useForm();
    const [source, setSource]                            = useState('no_critera');
    const [sourceItem, setSourceItem]                    = useState([]);
    const [selectPlatform, setSelectPlatform]            = useState('');
    const [platformItem, setPlatformItem]                = useState('');
    const [criteriaInputs, setCriteriaInputs]            = useState(['']);
    const [selectCriteria, setSelectCriteria]            = useState(['']);
    const [criteriaInputsExtra, setCriteriaInputsExtra]  = useState(['']);
    const [criteriaInputsExtraLabel, setCriteriaInputsExtraLabel] = useState(['']);
    const [criteria, setCriteria]                        = useState([])
    const navigate                                       = useNavigate();
    const [selectedFile, setSelectedFile]                = useState();
    const [filesize, setFilesSize]                       = useState();
    const reader                                         = new FileReader();
    const [addEmails, setAddEmails]                      = useState();
    const [addCsvEmails, setAddCsvEmails]                = useState();
    const [emailValidation, setEmailValidation]          = useState(true);
    const [openSuccess, setOpenSuccess]                  = useState(0);
    const [openWarning, setOpenWarning]                  = useState(false);
    const Papa      = require('papaparse');
    const location  = useLocation();
    const [validation, setValidation] = useState(true);
    const [submitAction, setSubmitAction] = useState('next');
    const [displayChecks, setDisplayChecks] = useState({'dec':true,'dlc':false,'dtr':false});

    const state        = location && location.state?location.state:'';
    const criteriaData = (state.action == 'back-and-next')?JSON.parse(localStorage.getItem('lh_criteriaform_data')):[];
    const user_id      = localStorage.getItem('nift_user_id');
    const temp_user_id = localStorage.getItem('lh_trmp_user_id');

    useEffect(()=>{
        setValidation(true);

        if(criteriaData && criteriaData.platform != selectPlatform) {
            setCriteriaInputs(['']);
            setSelectCriteria(['']);
        }
        
        switch(selectPlatform){
            case 'Twitter':
                setPlatformItem(Twitter);
            break;
            case 'YouTube':
                setPlatformItem(Google);
            break;
            case 'Facebook':
                setPlatformItem(Meta);
            break;
            case 'Instagram':
                setPlatformItem(Instagram);
            break;
            case 'Snapchat':
                setPlatformItem(Meta);
            break
            case 'Spotify':
                setPlatformItem(Spotify);
            break
            case 'Amazon Music':
                setPlatformItem(AmazonMusic);
            break
            case 'Netflix':
                setPlatformItem(Netflix);
            break
            case 'Discord':
                setPlatformItem(Discord);
            break
            default:
                setPlatformItem('');
            break;
        }
    },[selectPlatform])

    useEffect(() => {
        if (selectCriteria.length > 0) {
          let criteriaObj = selectCriteria.map((type, index) => ({ type, value: criteriaInputs[index] }));
          console.log(selectCriteria);
          setCriteria(criteriaObj);
        }
    }, [selectCriteria]);

    useEffect(() => {
        if (criteriaInputs.length > 0) {
          let criteriaObj = criteriaInputs.map((type, index) => ({ type:selectCriteria[index], value: type }));
          console.log(criteriaInputs);
          setCriteria(criteriaObj);
        }
    }, [criteriaInputs]);

    useEffect(() => {
        if (criteriaInputsExtra.length > 0) {
            let criteriaObj = criteriaInputsExtra.map((type, index) => ({ type:setCriteriaInputsExtra[index], value: type }));
            setCriteria(criteriaObj);
        }
    },[criteriaInputsExtra]);

    useEffect(() => {
        if (criteriaInputsExtraLabel && criteriaInputsExtraLabel.length > 0) {
            let criteriaObj = criteriaInputsExtraLabel.map((type, index) => ({ type:setCriteriaInputsExtraLabel[index], value: type }));
            setCriteria(criteriaObj);
        }
    },[criteriaInputsExtraLabel]);

    useEffect(()=>{
        
        if(criteriaData &&  state.action == 'back-and-next'){
           
            const base64Data  = localStorage.getItem('lh_emails_csv');

            if(criteriaData.source)
                setSource(criteriaData.source)

            if(criteriaData.platform)
                setSelectPlatform(criteriaData.platform)
            
            if(criteriaData.social_criteria && criteriaData.social_inputs){
                setSelectCriteria(criteriaData.social_criteria);
                setCriteriaInputs(criteriaData.social_inputs)
                setCriteriaInputsExtra(criteriaData.social_inputs_extra);
                setCriteriaInputsExtraLabel(criteriaData.social_inputs_label)
            } 
            
            if(criteriaData.platform == 'Add Emails'){
                if(criteriaData.added_emails && criteriaData.added_emails.length > 0) {
                    setAddEmails(criteriaData.added_emails);
                } 
            }
            else {

                if(criteriaData.added_csv_emails && criteriaData.added_csv_emails.length > 0) {
                    setAddCsvEmails(criteriaData.added_csv_emails);
                }

                if(base64Data){
                    const base64CSV   = base64Data.split(',')[1];
                    const decodedData = atob(base64CSV);
                    
                    Papa.parse(decodedData, {
                        header: false,
                        skipEmptyLines: true,
                        complete: function (results) {
                            results.data.map(function(email){
                                if (!isValidEmail(email)) {
                                    setEmailValidation(false);
                                }
                            });
                        },
                    });
                    
                    const file   = new Blob([base64Data], { type: 'text/csv' });
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.addEventListener('load', () => {
                        setSelectedFile(file);
                        let fileSize = (file.size/(1024*1024)).toFixed(2);
                        if(fileSize > 1)
                            setFilesSize(fileSize + ' MB');
                        else
                            setFilesSize((file.size/(1024)).toFixed(2) + ' KB');
                    });
                }
            }
        }

    },[]);

    useEffect(() => {
        setValidation(true);
        switch(source){
            case 'emails':
                setSourceItem(Email);
            break;
            case 'social_content':
                setSourceItem(Platform);
            break;
            case 'ecommerce':
                setSourceItem(Ecommerce);
            break;
            case 'api':
                setSourceItem(['api']);
            break;
            default:
                setSourceItem('');
            break;
        }
    },[source]);
      
    const back = () => {
        navigate('/reward',{state:{'action':'back-and-next'}})
    }

    const cancel = () => {
        navigate('/drop');
    }

    const submit = (data) => {
        
        if(submitAction == 'next' || submitAction == 'cancel') {

            if(submitAction == 'cancel') {
                setOpenSuccess(0);
                setOpenWarning(true);
            }
            else {
                
                let formValidation = true;
                let criteriaData   = {};

                if(source == 'emails' || source == 'social_content' || source == 'no_critera'){

                    if(source == 'emails') {
                        setValidation(emailValidation);
                        formValidation = emailValidation;
                    }
                    
                    if(validation) {
                        
                        localStorage.removeItem('lh_criteriaform_data');
                        criteriaData.source   = source;
                        criteriaData.platform = selectPlatform;

                        if(source == 'emails') {
                            if(selectPlatform == 'Add Emails') {
                                formValidation = (addEmails && addEmails.length > 0)?true:false;
                                setValidation(formValidation);
                                criteriaData.added_emails = addEmails;
                            }
                            else
                            {    
                                if(addCsvEmails && addCsvEmails.length == 0){
                                    if(!selectedFile){
                                        formValidation = false;
                                        setEmailValidation(false);
                                    }
                                    else
                                    {
                                        formValidation = true;
                                    }
                                }
                                else {
                                    setEmailValidation(true);
                                    criteriaData.added_csv_emails = addCsvEmails;
                                }
                            }
                        }
                        else if(source == 'social_content') {
                            
                            if(selectPlatform.length > 0){

                                //if(selectPlatform !== 'Instagram') {
                                    criteriaInputs.map((item) => {
                                        if(item.length == 0) {
                                            formValidation = false;
                                            setValidation(false);
                                        }
                                    });
                                    
                                    selectCriteria.map((item) => {
                                        if(item.length == 0) {
                                            formValidation = false;
                                            setValidation(false);
                                        }
                                    });
                                    
                                    criteriaData.social_criteria = selectCriteria;
                                    criteriaData.social_inputs   = criteriaInputs;
                                    criteriaData.social_inputs_extra = criteriaInputsExtra;
                                    criteriaData.social_inputs_label = criteriaInputsExtraLabel;
                                /* }
                                else {
                                    formValidation = true;
                                    setValidation(true);
                                } */
                            }
                            else {
                                formValidation = false;
                                setValidation(false);
                            }
                        }
                        else if(source == 'no_critera') {
                            formValidation = true;
                        }
                    }
                    else 
                    {
                        formValidation = false;
                        setValidation(false);
                    }
                }
                
                if(formValidation){
                    setOpenSuccess(submitAction !== 'cancel'?1:2);
                    localStorage.setItem("lh_criteriaform_data",JSON.stringify(criteriaData));
                    createAndUpdateReward(submitAction);
                }
                else
                    setValidation(false);
            }
        }
        else
            navigate('/reward',{state:{'action':'back-and-next'}});
    }

    const createAndUpdateReward = async (submitAction) => {
        const url = 'https://api.drop.fish/api-flow/create-reward';
        //const url = 'http://localhost/lighthouseapi/api-flow/create-reward';
        const formData = new FormData();

        if(localStorage.getItem('lh_emails_csv'))    
            formData.append('emails_csv',localStorage.getItem('lh_emails_csv'));
            
        if(localStorage.getItem('lh_criteriaform_data'))    
            formData.append('criteriaform_data',localStorage.getItem('lh_criteriaform_data'));

        if(user_id)
            formData.append('user_id',user_id);
        
        else if(temp_user_id)
            formData.append('user_id',temp_user_id);

        if(localStorage.getItem('lh_edit_drop'))
            formData.append('drop_id',localStorage.getItem('lh_edit_drop'));

        formData.append('access_token', localStorage.getItem("lh_access_token"));

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.post(url, formData, config).then(function (response) {
              if(response.data.message == 'success'){
                localStorage.setItem('lh_edit_drop',response.data.data.drop_id);
                
                if(response.data.data.temp_user){
                    localStorage.setItem('lh_trmp_user_id',response.data.data.user_id);
                }

                //navigate('/page',{state:{'action':'back-and-next'}});
                if(submitAction != 'cancel')
                    navigate('/page',{state:{'action':'back-and-next'}});
                else {
                    setOpenSuccess(0);
                    setOpenWarning(true);
                }
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    createAndUpdateReward(submitAction);
                });
            }
        });
    }

    const handleSetAddEmails = (e) => {
        setEmailValidation(true);
        e.map(function(email){
            if (!isValidEmail(email)) {
                setEmailValidation(false);
            }
        });
        setAddEmails(e);
    }

    const handleChangeProperty = (e) => {
        setSource(e.target.value);
        setSelectPlatform('')
    }; 

    const removeFile = (e) => {
        setSelectedFile();
    }

    const EmailCsvUpload = (e) => {
        let file = e;
        Papa.parse(file, {
            header: false,
            skipEmptyLines: true,
            complete: function (results) {
                results.data.map(function(email){
                    if (!isValidEmail(email)) {
                        setEmailValidation(false);
                    }
                });
            },
        });

        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
            localStorage.setItem('lh_emails_csv', reader.result);
            setSelectedFile(file);
            let fileSize = (file.size/(1024*1024)).toFixed(2);
            if(fileSize > 1)
                setFilesSize(fileSize + ' MB');
            else
                setFilesSize((file.size/(1024)).toFixed(2) + ' KB');
        });
    }

    const onSubmit = (data) => {
        data.selectPlatform = selectPlatform
        data.selectCriteria = selectCriteria
        data.criteriaInputs = criteriaInputs
        data.criteriaInputsExtra      = criteriaInputsExtra
        data.criteriaInputsExtraLabel = criteriaInputsExtraLabel
        data.added_csvemails          = addCsvEmails
        localStorage.setItem("lh_criteriaform_data",JSON.stringify(data));
    }

    //for testings - file drag

    /* const [file, setFile] = useState(null);

    const handleChange = (file) => {
        setFile(file);
    };

    useEffect(()=>{console.log('file upload',file)},[file]) */


    return(
        <ThemeProvider theme={ThemeCustom}>
            <NavBar getOAuthToken={props.getOAuthToken}/>
            <WarningModal setOpenWarning={setOpenWarning} openWarning={openWarning}/>  
            <LoadingModal setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} mid={2}/>
            <form onSubmit={handleSubmit(submit)} >
                <Container maxWidth="xl" className="mt-[8.25rem] sm:mt-[8.917rem] md:mt-[7.563rem] mb-[8.125rem] text-white">
                    <Typography component={'div'} variant='header48' align='left' className='py-10'>Setup Eligibility Criteria</Typography>
                    <div>
                        <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid xs={12} sm={4} >
                                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                                <InputLabel shrink labelid="source-input">Criteria Source</InputLabel>
                                    <Select
                                        labelid="source-input"
                                        id="source_input"
                                       // {...register("source_input",{ required: true ,validateOnChange: false})} 
                                        value={source}
                                        label="source"
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        onChange={handleChangeProperty}
                                    >
                                        <MenuItem className={NavStyle['admin-nav-item']} selected value={'no_critera'}>No Criteria</MenuItem>
                                        <MenuItem className={NavStyle['admin-nav-item']} value={'emails'}>Emails</MenuItem>
                                        <MenuItem className={NavStyle['admin-nav-item']} value={'social_content'}>Social or Content Platform</MenuItem>
{/*                                         <MenuItem className={NavStyle['admin-nav-item']} value={'ecommerce'}>E-commerce</MenuItem>
                                        <MenuItem className={NavStyle['admin-nav-item']} value={'api'}>API</MenuItem> */}
                                    </Select>
                                    {errors?.source_input?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={4} ></Grid>
                            <Grid xs={12} sm={4} ></Grid>
                        </Grid>
                        {source === 'emails' || source === 'social_content' ?(
                            sourceItem !== [] ? (
                            <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid xs={12} sm={4} >
                                    <AdvancedDropdown sourceItem={sourceItem} source={source} setSelectPlatform={setSelectPlatform} selectPlatform={selectPlatform}/>
                                </Grid>
                                <Grid xs={12} sm={4} ></Grid>
                                <Grid xs={12} sm={4} ></Grid>
                            </Grid>
                            ) : ''
                        ): source === 'api' ? (
                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                                <InputLabel shrink htmlFor="API-input">API</InputLabel>
                                <OutlinedInput 
                                    id="API-input"
                                    autoComplete="off" 
                                    placeholder="List or describe what you would like to validate and we'll make this available in the next few hours." 
                                    type='text'
                                    multiline
                                    rows={4}
                                    {...register("api",{ required: (submitAction !== 'cancel')?true:false })} 
                                />
                                {errors?.api?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                            </FormControl>
                        ):''}

                        {   selectPlatform !== '' &&  source === 'ecommerce' ? (
                                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                                    <InputLabel shrink htmlFor={`${selectPlatform}-input`}>Your Criteria</InputLabel>
                                    <OutlinedInput 
                                        id={`${selectPlatform}-input`}
                                        autoComplete="off" 
                                        placeholder="List or describe what you would like to validate and we'll make this available in the next few hours." 
                                        type='text'
                                        multiline
                                        rows={4}
                                        {...register("criteria",{ required: (submitAction !== 'cancel')?true:false })} 
                                    />
                                    {errors?.criteria?.type === "required" && <p className='text-salsaRed'>This field is required</p>}
                                </FormControl>
                            ): selectPlatform !== '' && source === 'emails' ? (
                            selectPlatform === 'Add Emails' ? (
                                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    <Grid xs={12} sm={4} >
                                        <FormControl variant="standard" fullWidth  margin="normal" className='m-0 flex flex-col'>
                                            <AddInput setFormValidation={setValidation} addedEmail={addEmails} setAddEmails={handleSetAddEmails}/>
                                        {/*errors.properties && <span className='err-msg'>This is required.</span>*/}
                                        {!emailValidation  && <p className='text-salsaRed'> Invalid email, Please check email formatting</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} sm={4} ></Grid>
                                    <Grid xs={12} sm={4} ></Grid>
                                </Grid>
                                ):(
                                    <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }} >
                                        <Grid xs={12} sm={4} >
                                            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                                                <InputLabel shrink>Upload</InputLabel>
                                                {!(selectedFile)?(
                                                    <>
                                                    {/* <InputLabel labelid="email-csv-input" className='mb-0 cursor-pointer p-4 bg-[#1A1C1E] rounded-2xl'>
                                                        <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                                                        className='hidden' 
                                                        id="email-csv-input" 
                                                        type="file" 
                                                        name={`email-csv-upload`}
                                                        onChange={EmailCsvUpload} />
                                                        <CardContent className='bg-[#1A1C1E] p-0 m-0 border-2 border-onyx border-dashed rounded-2xl flex justify-center items-center flex-col min-h-[18.75rem] max-h-[18.75rem]'>
                                                            <CardMedia
                                                                className='min-h-[6.25rem] max-h-[6.25rem] max-w-[6.25rem]'
                                                                component="img"
                                                                alt="Add CSV"
                                                                image={CreateImg}
                                                            />
                                                            <Typography variant="textBase" align='center' className='mt-7 no-underline' component="div">
                                                                Upload CSV File
                                                            </Typography>
                                                        </CardContent>
                                                        <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces text-sm' component="div">
                                                            Upload a CSV file with email addresses in a single column with no column title or header.
                                                        </Typography>
                                                        {!emailValidation  && <p className='text-salsaRed'>This field is required</p>}
                                                    </InputLabel> */}
                                                    <FileUploader
                                                        classes={'max-w-[26.5rem] rounded-2xl drag-drop'}
                                                        multiple={false}
                                                        handleChange={EmailCsvUpload}
                                                        name="file"
                                                        types={["xlsx", "csv"]}
                                                        children={
                                                            <div className='mb-0 cursor-pointer p-4 bg-[#1A1C1E] rounded-2xl  max-w-[26.5rem]'>
                                                                <CardContent className='bg-[#1A1C1E] p-0 m-0 border-2 border-onyx border-dashed rounded-2xl flex justify-center items-center flex-col min-h-[24.375rem] max-h-[24.375rem] max-w-[26.5rem]'>
                                                                    <CardMedia
                                                                        className='min-h-[6.25rem] max-h-[6.25rem] max-w-[6.25rem]'
                                                                        component="img"
                                                                        alt="Add CSV"
                                                                        image={CreateImg}
                                                                        {...register("rewardImage",{ required: true })} 
                                                                    />
                                                                    <Typography variant="textBase" align='center' className='mt-7 no-underline mb-2' component="div">
                                                                        Upload CSV File
                                                                    </Typography>
                                                                    <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces text-center text-sm mx-3' component="div">
                                                                        Upload a CSV file with email addresses in a single column with no column title or header.
                                                                    </Typography>
                                                                    {!emailValidation  && <p className='text-salsaRed'>This field is required</p>}
                                                                </CardContent>
                                                            </div>
                                                        }
                                                    />
                                                    </>
                                                ):(
                                                    <div>
                                                        <CardContent className='p-10 m-0 border flex-col border-platinum border-dashed rounded-2xl flex justify-center items-center min-h-[20.75rem] max-h-[20.75rem] min-w-[28.125rem] w-full'>
                                                            <CardMedia
                                                                className='w-full max-w-[6.25rem] cursor-pointer'
                                                                component="img"
                                                                alt="delete image"
                                                                image={DeleteImg}
                                                                onClick={(e)=>{removeFile(e)}}
                                                            />
                                                            <div className='mt-4'>
                                                                <Typography variant="textBase" className='no-underline mb-1 break-words whitespace-break-spaces font-semibold text-xl' component="div">
                                                                    {(selectedFile.name)}
                                                                </Typography>
                                                                <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces  text-sm' component="div">
                                                                    {filesize}
                                                                </Typography>
                                                            </div>
                                                        </CardContent>
                                                        <Typography variant="textBase" className='no-underline break-words leading-6 whitespace-break-spaces text-sm' component="div">
                                                            Upload a CSV file with email addresses in a single column with no column title or header.
                                                        </Typography>
                                                        {!emailValidation  && <p className='text-salsaRed'> Invalid CSV. Please check email formatting. Each email should contain an '@' symbol and a domain extension.</p>}
                                                    </div>
                                                )}
                                                
                                            </FormControl>
                                            <AddInputCsv setFormValidation={setValidation} addedEmail={addCsvEmails}/>
                                        </Grid>
                                        <Grid xs={12} sm={4} ></Grid>
                                        <Grid xs={12} sm={4} ></Grid>
                                    </Grid>
                                )
                            ): selectPlatform !== '' && (source === 'social_content') ? (
                                <AddInputCriteria 
                                    criteria={criteria}
                                    criteriaInputs={criteriaInputs} 
                                    criteriaInputsExtra={criteriaInputsExtra}
                                    criteriaInputsExtraLabel={criteriaInputsExtraLabel}
                                    setCriteria={setCriteria}
                                    setCriteriaInputs={setCriteriaInputs}
                                    setCriteriaInputsExtra={setCriteriaInputsExtra}
                                    setCriteriaInputsExtraLabel={setCriteriaInputsExtraLabel}
                                    selectCriteria={selectCriteria}
                                    setSelectCriteria={setSelectCriteria}
                                    setFormValidation={setValidation}
                                    platformItem={platformItem}
                                    selectPlatform={selectPlatform}
                                    />
                            ) : ''  }
                                
                            {!validation  && <p className='text-salsaRed'>Invalid values for eligibility criteria.</p>}
                    </div>
                </Container>    
                <FooterNav displayCheks={displayChecks} btnAction={setSubmitAction} cancel={cancel} back={back} />
            </form>
        </ThemeProvider>   
    )
}

export default SetUpCriteria;