import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {ThemeProvider, Typography, Container} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ButtonMain from '../components/button/Button_main';
import NavBar from '../components/nav/Nav';
import twitterLogo from '../assets/Twitter-Logo.svg';
import GoogleLogo from '../assets/Google-Logo.svg';
import FbLogo from '../assets/FB-icon.svg';
import { ThemeCustom} from "../styles/Theme";
import axios from 'axios';
import ErrorAlert from '../components/alerts/Fail_alert';

const style = {disconnect:'bg-darkGrey p-6 pl-10 rounded-xl border border-eerieBlack border-solid', connect:'bg-dark p-6 pl-10 rounded-xl border border-eerieBlack border-solid'}

const Settings = (props) => {
    const searchParams = new URLSearchParams(window.location.search);
    const token        = searchParams.get('auth');
    const user_id      = localStorage.getItem('nift_user_id');
    //const user_id    = 'nift_user_id';
    const auth_type    = localStorage.getItem('auth_type');
    
    const [userAccounts, setUserAccounts] = useState([]);
    const [loginData, setLoginData]       = useState('');
    const [openFail, setOpenFail]         = useState(false);
    const [textFail, setTextFail]         = useState();
    const navigate = useNavigate();

    function accrountDisconnect(disconnect_id){
        const url = 'https://api.drop.fish/api-flow/user-account-disconnect';
        const formData = new FormData();
        formData.append('user_id',user_id);
        formData.append('disconnect_id',disconnect_id);
        formData.append('access_token', localStorage.getItem("lh_access_token"));
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
                setUserAccounts(response.data.data);
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    accrountDisconnect(disconnect_id);
                });
            }
        });
    }

    const connectTwitter = () => {
        window.location.replace('https://api.drop.fish/auth?drop=settings');
    }

    const connectGoogle = () => {
        window.location.replace('https://api.drop.fish/google-auth?drop=settings');
    }

    const connectFacebook = () => {
        console.log('connect')
    }

    const connectByToken = (token) => {
        const url = 'https://api.drop.fish/api-flow/connect-by-token';
        const formData = new FormData();
        formData.append('token', token);
        formData.append('user_id',user_id);
        formData.append('access_token', localStorage.getItem("lh_access_token"));
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        
        axios.post(url, formData, config).then(function(response){
            if(response.data.message == 'success'){
                setUserAccounts(response.data.data);
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    connectByToken(token);
                });
            }
            else
            {
                setTextFail('Please ensure that your connecting accounts are valid. If you think this is an error, please contact us at admin+error@lighthouse.xyz with the connecting account.');
                setOpenFail(true);
            }
        });
    }

    useEffect(()=>{
        if(token){
            connectByToken(token)
        }
    },[token]);

    useEffect(()=>{
        if(user_id)
            getAccounts(user_id);
        else
            navigate("/tank");
    },[user_id]);

    const getAccounts = async (uid) => {
        const url = 'https://api.drop.fish/api-flow/user-accounts';
        const formData = new FormData();
        formData.append('user_id',uid);
        formData.append('access_token', localStorage.getItem("lh_access_token"));
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
                setUserAccounts(response.data.data);
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    getAccounts(uid);
                });
            }
        });
    }

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)
    },[openFail]);
  
    return(
        <ThemeProvider theme={ThemeCustom}>
        <div className='fixed top-12 right-8 w-full z-[1999] h-0'>
            <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
        </div>
            <NavBar setLoginData={setLoginData} getOAuthToken={props.getOAuthToken}/>
            <Container maxWidth="xl" className="mt-[8.25rem] sm:mt-[8.917rem] md:mt-[7.563rem] mb-[8.125rem] text-white">
                <Typography component={'div'} variant='header48' align='left' className='py-10'>Link Multiple Accounts</Typography>
                <Typography component={'div'} variant='text20' align='left' className='mb-5 font-medium'>Connected Accounts</Typography>
               
                    {(userAccounts.map((account,index) => (
                        <>
                            <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid xs={12} md={6} lg={4} >
                                    <div className={style.disconnect}>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex justify-start items-center text-white font-medium text-lg'>
                                                {(account.type=='Twitter')?(<img src={twitterLogo} alt='icon' className='mr-5 w-8'/>):(<img src={GoogleLogo} alt='icon' className='mr-5 w-8'/>)}
                                                <div>
                                                    <div className='text-white font-medium text-lg'>{account.username}</div>
                                                    <div className='text-gray font-medium text-sm'>{account.type}</div>
                                                </div>
                                            </div>
                                            {(account.parent == 0)?(
                                                <ButtonMain text="Disconect" variant={'outlineBtnWhite'} classes="linkAccountBtn ml-5 w-full max-w-[8.75rem]" type="button" onClick={() => {accrountDisconnect(account.user_id)}} disabled={false}/>
                                            ):(
                                                <ButtonMain text="Connected" variant={'outlineBtnWhite'} classes="linkAccountBtn ml-5 w-full max-w-[8.75rem]" type="button" disabled={true}/>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid> 
                        </>)
                    ))}
                
                {/* <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} md={6} lg={4} >
                        <div className={style.disconnect}>
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-start items-start '>
                                    <img src={GoogleLogo} alt='icon' className='mr-5 w-8'/>
                                    <div>
                                        <div className='text-white font-medium text-lg'>Google</div>
                                        {(userAccounts.google)?(
                                            <div className='text-gray font-medium text-sm'>{userAccounts.google.username}</div>
                                        ):('')}
                                    </div>
                                </div>
                                {(userAccounts.google && userAccounts.google.parent == 0)?(
                                    <ButtonMain text="Disconect" variant={'outlineBtnWhite'} classes="linkAccountBtn ml-5 w-full max-w-[8.75rem]" type="button" onClick={() => {accrountDisconnect(userAccounts.google.user_id)}} disabled={false}/>
                                ):(
                                    <ButtonMain text={userAccounts.google?"Connected":"Connect"} variant={'outlineBtnWhite'} classes="linkAccountBtn ml-5 w-full max-w-[8.75rem]" type="button" onClick={connectGoogle} disabled={userAccounts.google?true:false}/>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid> */}
                <Typography component={'div'} variant='text20' align='left' className='mb-5 mt-10 font-medium'>Add Accounts</Typography>
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} md={6} lg={4} >
                        <div className={style.connect}>
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-start items-center text-white font-medium text-lg'>
                                    <img src={twitterLogo} alt='icon' className='mr-5 w-8'/>
                                    <div>
                                        <div className='text-white font-medium text-lg'>Twitter</div>
                                    </div>
                                </div>
                                <ButtonMain text="Add" variant={'outlineBtnWhite'} classes="linkAccountBtn ml-5 w-full max-w-[8.75rem]" type="button" onClick={connectTwitter} disabled={false}/>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6} lg={4} >
                    </Grid>
                    <Grid xs={12} md={6} lg={4} >
                    </Grid>
                </Grid> 
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} md={6} lg={4} >
                        <div className={style.connect}>
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-start items-start '>
                                    <img src={GoogleLogo} alt='icon' className='mr-5 w-8'/>
                                    <div>
                                        <div className='text-white font-medium text-lg'>Google</div>
                                    </div>
                                </div>
                                <ButtonMain text="Add" variant={'outlineBtnWhite'} classes="linkAccountBtn ml-5 w-full max-w-[8.75rem]" type="button" onClick={connectGoogle} disabled={false}/>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6} lg={4} >
                    </Grid>
                    <Grid xs={12} md={6} lg={4} >
                    </Grid>
                </Grid>
                {/* <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid xs={12} md={6} lg={4} >
                        <div className={style.disconnect}>
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-start items-center text-white font-medium text-lg'>
                                    <img src={FbLogo} alt='icon' className='mr-5 w-8'/>
                                    Facebook
                                </div>
                                <ButtonMain text={userAccounts.facebook?"Connected":"Connect"} variant={'outlineBtnWhite'} classes="linkAccountBtn ml-5 w-full max-w-[8.75rem]" type="button" onClick={connectFacebook} disabled="true"/>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6} lg={4} >
                    </Grid>
                    <Grid xs={12} md={6} lg={4} >
                    </Grid>
                </Grid> */}
            </Container>
        </ThemeProvider>    
    )
}

export default Settings;