import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";

const ButtonMain= (props) => {

    return (
        <ThemeProvider theme={ThemeCustom}>
          <Button variant={props.variant}  type={props.type} className={props.classes} onClick={props.onClick} disabled={props.disabled}>
            {props.text}
          </Button>
        </ThemeProvider>
    );
}

export default ButtonMain;