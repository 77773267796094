import React, {useState, useEffect} from 'react';

import ButtonMainImg from '../button/Button_main_img'

import { ThemeProvider, FormControl, Button, OutlinedInput } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";
import AddDark from '../../assets/add-dark.svg';
import DelImg from '../../assets/trash.svg';

const AddInputReward = ({ instructions, register, errors,btnAction }) => {

    const [instructInputs, setInstructInputs] = useState(instructions);

/*      useEffect(()=>{
        if(instructions.length > 0){
            setInstructInputs(instructions);
        }  
    },[instructions]);  */

    const addInstruct = () => {
        setInstructInputs([...instructInputs, '']);
    }

    const removeInstruct = (index) => {
        setInstructInputs(instructInputs.filter((_, i) => i !== index));
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            {instructInputs.map((input, index) => (
                <FormControl key={index+1} variant="standard" fullWidth margin="normal" className='flex flex-row mb-0'>
                <span className='flex flex-row mt-5 ml-2 mr-2'>{index+1}.</span> 
                <OutlinedInput
                    id={`instruction-input-${index}`}
                    autoComplete="off"
                    defaultValue={instructInputs[index]}
                    placeholder={index === 0 ? `e.g: Click the Access Drop button to view your tickets`:`e.g: Enter your instructions`}
                    type='text'
                    aria-label='Reward instructions'
                    {...register(`instruction[${index}]`, { required: (btnAction !== 'cancel')?true:false})}
                />
                
                {instructInputs.length > 1  ? (
                    <Button className='bg-red rounded-lg min-w-[3.795rem] min-h-[3.795rem] ml-5' onClick={() => removeInstruct(index)}>
                        <img src={DelImg} alt='icon' className='w-6' />
                    </Button>
                ):<div className='min-w-[3.795rem] ml-5'></div>}    
                </FormControl>
            ))}
            <ButtonMainImg text="Add New Reward Instructions" img={AddDark} variant={'whiteBtnRegular'} classes="flex items-center mt-[16px] max-w-[24rem] max-h-[3.8rem] add-instruct" type="button" onClick={addInstruct} />
        </ThemeProvider>
    );
}

export default AddInputReward;