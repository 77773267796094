import { createTheme } from "@mui/material/styles";  

export const ThemeMain = createTheme({

    palette: {
        primary: {
            light: '#986de6',
            main: "#7F49E0",
            dark:'#58339c'
        },
        crayolaBlue:{
            light: '#376ece',
            main: "#054AC2",
            dark:'#033387'
        },
        white:{
            main: "#fff"
        },
        black:{
            main: "#000"
        },
        Eerie:{
            main: "#201E19"
        },
        crayolaGreen:{
            main: "#00FFD4"
        },
        green:{
            light: '#35ac79',
            main: "#039858",
            dark:'#026a3d'
        },
        goGreen:{
            light: '#37ce8c',
            main: "#05C270",
            dark:'#03874e'
        },
        gray:{
            main: "#7C7E80",
        },
        eerieBlack:{
            light: '#47494b',
            main: "#1A1C1E",
            dark:'#121315'
        },
        orangeRed:{
            light: '#ff6333',
            main: "#FF3C00",
            dark:'#b22a00'
        }
    },
    typography: {
        fontFamily: ['Inter','sans-serif',].join(','),
        fontSize: 16,
    }

});

export const ThemeCustom = createTheme({
    typography: {
        text14Code: {
            color:  ThemeMain.palette.white.main,
            fontSize: '1.125rem',
            fontFamily: `${['Source Code Pro','monospace'].join(',')} !important`,
            fontWeight: 400,
        },
        text14TwitterTag: {
            color:  ThemeMain.palette.white.main,
            fontSize: '1.3rem',
            fontFamily: `${['Amatic SC','cursive',].join(',')} !important`,
            fontWeight: '600 !important',
        },
        textBase:{
            color:  ThemeMain.palette.white.main,
            fontSize: '1rem',
        },
        text18: {
            color:  ThemeMain.palette.white.main,
            fontSize: '1.125rem',
        },
        text18Gray: {
            color:  ThemeMain.palette.gray.main,
            fontSize: '1.125rem',
        },
        text20: {
            color:  ThemeMain.palette.white.main,
            fontSize: '1.25rem',
        },
        text24Gray: {
            color:  ThemeMain.palette.gray.main,
            fontSize: '1.5rem',
        },
        text24: {
            color:  ThemeMain.palette.white.main,
            fontSize: '1.5rem',
            fontWeight: '600 !important',
        },
        text30: {
            color:  ThemeMain.palette.white.main,
            fontSize: '1.875rem',
        },
        text30Green: {
            color:  ThemeMain.palette.crayolaGreen.main,
            fontSize: '1.875rem',
        },
        text36: {
            color:  ThemeMain.palette.white.main,
            fontSize: '2.25rem',
        },
        header50: {
            color:  ThemeMain.palette.white.main,
            fontWeight: '700 !important',
            fontSize:'3.125rem',
            textAlign:'center'
        },
        header48: {
            color:  ThemeMain.palette.white.main,
            fontWeight: '500 !important',
            fontSize:'3rem',
            textAlign:'center'
        },
        header48Bold: {
            color:  ThemeMain.palette.white.main,
            fontWeight: '700 !important',
            fontSize:'3rem',
            textAlign:'center'
        },
        subHeader1: {
            color:  ThemeMain.palette.white.main,
            fontWeight: '600 !important',
            fontSize:'1.625rem'
        }
    },
    components:{
        MuiTypography:{
            styleOverrides: {
                root: {
                    fontFamily: ['Inter','sans-serif',].join(','),
                    fontWeight: 400,
                    lineHeight: 1.2,
                }
            },
        },
        MuiButton:{
            styleOverrides: {
                root: {
                    minHeight: '3rem',
                    minWidth: '7.5rem'
                  },
            },
            variants: [
                {
                    props: { variant: 'primaryBtn' },
                    style: {
                        color:  '#ffffff',
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.5rem',
                        lineHeight: 1.2,
                        fontWeight: 600,
                        //backgroundColor: ThemeMain.palette.primary.main,
                        borderRadius: '2.5rem',
                        padding: '1.5rem 3rem',
                        textTransform: 'initial',
                        //'&:hover': {
                            //backgroundColor: ThemeMain.palette.primary.light
                        //}
                    },
                },
                {
                    props: { variant: 'secondaryBtn' },
                    style: {
                        color:  '#ffffff',
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.125rem',
                        lineHeight: 1.2,
                        fontWeight: 600,
                        backgroundColor: ThemeMain.palette.green.main,
                        borderRadius: '0.75rem',
                        padding: '1.2rem 2.3rem',
                        textTransform: 'initial',
                        minWidth:'11.25rem',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.green.dark
                        },
                    },
                },  
                {
                    props: { variant: 'orangeBtn' },
                    style: {
                        color:  '#ffffff',
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.125rem',
                        lineHeight: 1.2,
                        fontWeight: 600,
                        backgroundColor: ThemeMain.palette.orangeRed.main,
                        borderRadius: '0.75rem',
                        padding: '1.2rem 2.3rem',
                        textTransform: 'initial',
                        minWidth:'11.25rem',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.orangeRed.dark
                        },
                    },
                },  
                {
                    props: { variant: 'goGreenBtn' },
                    style: {
                        color:  '#ffffff',
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.125rem',
                        lineHeight: 1.2,
                        fontWeight: 600,
                        backgroundColor: ThemeMain.palette.goGreen.main,
                        borderRadius: '0.75rem',
                        padding: '1.2rem 2.3rem',
                        textTransform: 'initial',
                        minWidth:'10rem',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.goGreen.dark
                        },
                    },
                }, 
                {
                    props: { variant: 'blueBtn' },
                    style: {
                        color:  '#ffffff',
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.125rem',
                        lineHeight: 1.2,
                        fontWeight: 600,
                        backgroundColor: ThemeMain.palette.crayolaBlue.main,
                        borderRadius: '0.75rem',
                        padding: '1.2rem 2.3rem',
                        textTransform: 'initial',
                        minWidth:'11.25rem',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.crayolaBlue.dark
                        },
                    },
                },   
                {
                    props: { variant: 'whiteBtn' },
                    style: {
                        color:  '#000',
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.5rem',
                        lineHeight: 1.2,
                        fontWeight: 600,
                        backgroundColor: ThemeMain.palette.white.main,
                        borderRadius: '1rem',
                        padding: '1.5rem 2.3rem',
                        textTransform: 'initial',
                        minWidth:'11.25rem',
                        '&:hover': {
                            backgroundColor: '#dfdfdf'
                        },
                    },
                },
                {
                    props: { variant: 'whiteBtnRegular' },
                    style: {
                        color:  '#000',
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.125rem',
                        lineHeight: 1.2,
                        fontWeight: 600,
                        backgroundColor: ThemeMain.palette.white.main,
                        borderRadius: '0.75rem',
                        padding: '1.2rem 2.3rem',
                        textTransform: 'initial',
                        minWidth:'10rem',
                        '&:hover': {
                            backgroundColor: '#dfdfdf'
                        },
                    },
                },
                {
                    props: { variant: 'outlineBtn' },
                    style: {
                        color:  ThemeMain.palette.goGreen.main,
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.125rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: 'transparent',
                        border:`1px solid ${ThemeMain.palette.goGreen.main}`,
                        borderRadius: '0.75rem',
                        padding: '1.2rem 2.3rem',
                        minWidth: '10rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: '#14201b'
                        }
                    },
                },
                {
                    props: { variant: 'outlineBtnWhite' },
                    style: {
                        color:  ThemeMain.palette.white.main,
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.125rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: 'transparent',
                        border:`1px solid ${ThemeMain.palette.white.main}`,
                        borderRadius: '0.75rem',
                        padding: '1.2rem 2.3rem',
                        minWidth: '10rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: '#1c1c1c'
                        }
                    },
                },  
                {
                    props: { variant: 'lableBtn' },
                    style: {
                        color:  ThemeMain.palette.white.main,
                        fontFamily: ['Inter','sans-serif',].join(','),
                        fontSize: '1.125rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: ThemeMain.palette.eerieBlack.main,
                        border:`none`,
                        borderRadius: '0.75rem',
                        padding: '1.2rem 2.3rem',
                        minWidth: '10rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.eerieBlack.light
                        }
                    },
                },  
            ]  
        },
        MuiInputLabel:{
            styleOverrides: {
                root: {
                    position: 'relative',
                    fontSize: '1rem',
                    color: ThemeMain.palette.white.main,
                    fontFamily: ['Inter','sans-serif',].join(','),
                    fontWeight: 600,
                    transform: 'inherit',
                    marginBottom: '0.75rem',
                    '&.Mui-focused':{
                        color: ThemeMain.palette.white.main
                    }
                  },
            }
        },
        MuiOutlinedInput:{
            styleOverrides: {
                root: {
                    borderRadius:'0.75rem',
                    width:'100%',
                    fontWeight: 500,
                    backgroundColor: '#1A1C1E',
                    color:ThemeMain.palette.white.main,
                    fontFamily: ['Inter','sans-serif',].join(','),
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
                        borderColor: ThemeMain.palette.gray.main,
                        borderWidth: '1px'
                    },
                    '& .MuiOutlinedInput-notchedOutline':{
                        borderWidth:'0'
                    },
                }
                },
                input:{
                    fontSize: '1rem',
                    color: ThemeMain.palette.white.main,
                    fontFamily: ['Inter','sans-serif',].join(','),
                    fontWeight: 500,
                    padding: '0.75rem 1rem',
                }  
            
        },
        MuiSelect:{
            styleOverrides: {
                root:{
                    fontFamily: ['Inter','sans-serif',].join(','),
                },
                icon: {
                    color:ThemeMain.palette.white.main,
                    marginRight:'1rem'
                }
            }
        },
        MuiMenuItem:{
            styleOverrides: {
                root:{
                    fontFamily: ['Inter','sans-serif',].join(','),
                }
            }    
        },
        MuiTextField:{
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input':{
                        color: ThemeMain.palette.white.main,
                        fontFamily: ['Inter','sans-serif',].join(','),
                        padding: '0.75rem 1rem',
                        lineHeight:'1.2',
                        fontWeight:'400'
                        
                    },
                    '& .MuiInputBase-root':{
                        '&::before, &::after':{
                            border: 'none !important'
                        }
                    }
                }
            }
        }
        
    }
});