import React, {useState, useEffect} from 'react';

import ButtonMainImg from '../button/Button_main_img';

import { ThemeProvider, FormControl, Button, OutlinedInput, InputLabel, Select, MenuItem } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { ThemeCustom} from "../../styles/Theme";
import NavStyle from '../nav/Nav_style.module.scss';

import AddDark from '../../assets/add-dark.svg';
import DelImg from '../../assets/trash.svg';

const AddInputCriteria = (props) => {
    console.log(props.selectPlatform);
    const [selectCriteria, setSelectCriteria] = useState((props.selectCriteria && props.selectCriteria.length > 0)?props.selectCriteria:[]);
    const [criteriaInputs, setCriteriaInputs] = useState((props.criteriaInputs && props.criteriaInputs.length > 0)?props.criteriaInputs:[]);
    const [criteriaInputsExtra, setCriteriaInputsExtra] = useState((props.criteriaInputsExtra && props.criteriaInputsExtra.length > 0)?props.criteriaInputsExtra:[]);
    const [criteriaInputsExtraLabel, setCriteriaInputsExtraLabel] = useState((props.criteriaInputsExtraLabel && props.criteriaInputsExtraLabel.length > 0)?props.criteriaInputsExtraLabel:[]);

    const addCriteria = () => {
       // props.setFormValidation(true);
        setCriteriaInputs([...criteriaInputs, '']);
        setSelectCriteria([...selectCriteria, '']);
        setCriteriaInputsExtra([...criteriaInputsExtra, '']);
        /* props.setCriteriaInputs([...props.criteriaInputs, '']);
        props.setSelectCriteria([...props.selectCriteria, '']); */
    }

    const removeCriteria = (index) => {
        setCriteriaInputs(criteriaInputs.filter((_, i) => i !== index));
        setSelectCriteria(selectCriteria.filter((_, i) => i !== index));
        setCriteriaInputsExtra(criteriaInputsExtra.filter((_, i) => i !== index));
       /*  props.setCriteriaInputs(props.criteriaInputs.filter((_, i) => i !== index));
        props.setSelectCriteria(props.selectCriteria.filter((_, i) => i !== index)); */
    }

    const handleCriteriaExtraLableChange = (e,index) => {
        const updatedCriteriaExtraLabels = [...criteriaInputsExtraLabel];
        updatedCriteriaExtraLabels[index] = e.target.value;
        setCriteriaInputsExtraLabel(updatedCriteriaExtraLabels);
        props.setCriteriaInputsExtraLabel(updatedCriteriaExtraLabels);
    }

    const handleCriteriaExtraChange = (e,index) => {
        const updatedCriteriaExtraInputs = [...criteriaInputsExtra];
        updatedCriteriaExtraInputs[index] = e.target.value;
        setCriteriaInputsExtra(updatedCriteriaExtraInputs);
        props.setCriteriaInputsExtra(updatedCriteriaExtraInputs);
    }

    const handleCriteriaChange = (e, index) => {
       
        if(props.selectPlatform=="Twitter")
            props.setFormValidation(checkTwitterURL(e.target.value)?true:false);
        else if(props.selectPlatform=="YouTube")
            props.setFormValidation(checkYoutubeURL(e.target.value)?true:false);
        else if(props.selectPlatform !="Instagram")
            props.setFormValidation(checkDiscordURL(e.target.value)?true:false);

        const updatedCriteriaInputs = [...criteriaInputs];
        updatedCriteriaInputs[index] = e.target.value;
        setCriteriaInputs(updatedCriteriaInputs);
        props.setCriteriaInputs(updatedCriteriaInputs);
    }

    const handleChangeSelectCriteria = (e, index) =>{  
        props.setFormValidation(true);
        const updatedCriteriaSelects = [...selectCriteria];
        updatedCriteriaSelects[index] = e.target.value;
        setSelectCriteria(updatedCriteriaSelects);
        props.setSelectCriteria(updatedCriteriaSelects);
    }

    const checkTwitterURL = (url) => {
        const twitterRegex = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
        return twitterRegex.test(url);
    }

    const checkYoutubeURL = (url) => {
        const twitterRegex = /^(?:https?:\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu\.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?|^(?:https?:\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu\.be))(\/(?:@)?)([\w\-]+)(\S+)?$/;
        return twitterRegex.test(url);
    }

    const checkDiscordURL = (url) => {
        const discordRegex = /http(?:s)?:\/\/(?:www\.)?discord\.com\/([a-zA-Z0-9_]+)/;
        return discordRegex.test(url);
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            {props.selectPlatform === "Microsoft" || props.selectPlatform === "Apple"  ? (
                <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                    <InputLabel shrink htmlFor={`criteria-input-${props.selectPlatform}`}>Your Criteria</InputLabel>
                    <OutlinedInput 
                        id={`criteria-input-${props.selectPlatform}`}
                        autoComplete="off" 
                        placeholder="Describe your criteria" 
                        type='text'
                        multiline
                        name={`criteria-input-${props.selectPlatform}`}
                        rows={4}
                        //{...register("instruct",{ required: true })} 
                    />
                    {/*errors.instruct && <span className='err-msg'>This is required.</span>*/}
                </FormControl>
            ): props.selectPlatform === "" ? (
            ''
            ):(
                <>
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}  className='pt-3'>
                    <Grid xs={12} sm={4} >
                        <InputLabel shrink labelid="criteria-input" >Criteria</InputLabel>
                        {selectCriteria.map((input, index) => (
                            <FormControl key={index} variant="standard" fullWidth margin="normal" className='flex mb-0'>
                            <Select
                                labelid={`criteria-select-${index}`}
                                id="criteria-select"
                                value={selectCriteria[index]}
                                label="criteria"
                                onChange={(e) => handleChangeSelectCriteria(e, index)}
                                input={<OutlinedInput />}
                                name={`criteria-select-${index}`}
                                displayEmpty
                                //{...register("source",{ required: true })}
                            >
                                <MenuItem className={NavStyle['admin-nav-item']} selected disabled value={''}>-Select-</MenuItem>
                                {props.platformItem.length !== 0 && props.platformItem.map((item, index) => (
                                    <MenuItem key={index+1} className={NavStyle['admin-nav-item']} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                            {/*errors.source && <span className='err-msg'>This is required.</span>*/}
                            </FormControl>
                        ))} 
                    </Grid>
                    
                        <Grid xs={12} sm={4} className='mt-6'>
                            {criteriaInputs.map((input, index) => (
                                <FormControl key={index} variant="standard" fullWidth margin="normal" className='mb-0'>
                                    <OutlinedInput
                                        id={`criteria-input-${index}`}
                                        autoComplete="off"
                                        placeholder={props.selectPlatform !== "Instagram" ? 'Enter URL':'Enter usernames separated by commas'}
                                        type='text'
                                        name={`criteria-${index}`}
                                        aria-label='criteria'
                                        value={input}
                                        onChange={(e) => handleCriteriaChange(e, index)}
                                        className='bg-primary'
                                    />
                                    {props.selectPlatform !== "Discord" && criteriaInputs.length > 1 ? (
                                        <Button className='bg-red rounded-lg min-w-[3.795rem] min-h-[3.795rem] absolute right-[-64px]' onClick={() => removeCriteria(index)}>
                                        <img src={DelImg} alt='icon' className='w-6' />
                                    </Button>
                                    ):''}
                                    
                                </FormControl>
                            ))}
                        </Grid>

                    {props.selectPlatform === "Discord" ? (
                        <>
                            <Grid xs={12} sm={2} className='mt-6'>
                                {criteriaInputsExtra.map((input, index) => (
                                    <FormControl key={index} variant="standard" fullWidth margin="normal" className='mb-0'>
                                        <OutlinedInput
                                            id={`criteria-roleid-${index}`}
                                            autoComplete="off"
                                            placeholder='Enter Role Id'
                                            type='text'
                                            name={`criteria-roleid-${index}`}
                                            aria-label='criteria'
                                            value={input}
                                            onChange={(e) => handleCriteriaExtraChange(e, index)}
                                            className='bg-primary'
                                        />
                                    </FormControl>
                                ))}
                            </Grid>
                            <Grid xs={12} sm={2} className='mt-6'>
                                {criteriaInputsExtraLabel.map((input, index) => (
                                    <FormControl key={index} variant="standard" fullWidth margin="normal" className='mb-0'>
                                        <OutlinedInput
                                            id={`criteria-rolelable-${index}`}
                                            autoComplete="off"
                                            placeholder='Enter Role Name'
                                            type='text'
                                            name={`criteria-rolelable-${index}`}
                                            aria-label='criteria'
                                            value={input}
                                            onChange={(e) => handleCriteriaExtraLableChange(e, index)}
                                            className='bg-primary'
                                        />
                                        {criteriaInputsExtraLabel.length > 1 ? (
                                            <Button className='bg-red rounded-lg min-w-[3.795rem] min-h-[3.795rem] absolute right-[-64px]' onClick={() => removeCriteria(index)}>
                                            <img src={DelImg} alt='icon' className='w-6' />
                                        </Button>
                                        ):''}
                                    </FormControl>
                                ))}
                            </Grid>
                        </>
                    ):('')}
                </Grid>
                <ButtonMainImg text="Add New Criteria" img={AddDark} variant={'whiteBtnRegular'} classes="flex items-center mt-[16px] max-w-[24rem] max-h-[3.8rem] add-instruct" type="button" onClick={addCriteria} />
            </>
            )
            }
        </ThemeProvider>
    );

}

export default AddInputCriteria;