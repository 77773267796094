import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {Box, Typography, Modal} from '@mui/material';

import ModalStyle from './Modal_styles.module.scss';
import ButtonMain from '../button/Button_main';

import animIcon from '../../assets/dog-anim.gif';
import animWarn from '../../assets/warning-anim.gif';
import Copy from '../../assets/copy.svg';

const EndDropModal = ({openWarning = true, setOpenWarning,endDrop=null}) =>{

    const navigate = useNavigate();
    

    const no = () => {
        setOpenWarning(false);
    }

    const yes = () => {
        setOpenWarning(false);
        const user_id      = localStorage.getItem('nift_user_id');
        const access_token = localStorage.getItem("lh_access_token");
        const url          = 'https://api.drop.fish/api-flow/end-drop';
        //const url        = 'https://api.drop.fish/api-flow/end-drop';
        
        const formData  = new FormData();
        formData.append('user_id', user_id);
        formData.append('drop_id', endDrop);
        formData.append('access_token', localStorage.getItem("lh_access_token"));
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
            
        axios.post(url, formData, config).then(function(response){
            if(response.data.message == 'success'){
                
            }
        });
        
    }

    return(
        <div>
            <Modal
                open={openWarning}
                aria-labelledby="modal-modal-title"
                id="SuccessModal"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-success']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={`${ModalStyle['icon-wrapper']} ${ModalStyle['content-warning']}`}>
                                <img src={animWarn} alt='icon' className={`${ModalStyle['icon-img']} ${ModalStyle['icon-warning']}`}/>
                            </div>
                            <Typography id="modal-modal-text" className={ModalStyle['modal-text18']} align={'center'} variant="text24" component="div">
                                Are you sure you want to end this reward
                            </Typography>
                            <div className={`${ModalStyle['modal-button-wrapper']} ${ModalStyle['modal-button-wrapper-flex']}`}>
                                <ButtonMain text="Not Now" fullWidth variant={'whiteBtnRegular'}  type="button" onClick={no} />
                                <ButtonMain text="Yes" fullWidth variant={'orangeBtn'}  type="submit" onClick={yes} />
                            </div>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default EndDropModal;