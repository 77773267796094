import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";

const ButtonCard= (props) => {

    return (
        <ThemeProvider theme={ThemeCustom}>
          <Button variant={props.btnProp.variant}  type={props.type} className={props.classes} onClick={props.onClick}>
            {props.btnProp.text}
          </Button>
        </ThemeProvider>
    );
}

export default ButtonCard;