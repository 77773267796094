import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {ThemeProvider } from "@mui/material";

import ButtonMain from '../button/Button_main';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ThemeCustom, ThemeMain } from "../../styles/Theme";
import NavStyle from './Nav_style.module.scss';

import LogoutIcon from '../../assets/logout.svg'
import SettingIcon from '../../assets/settings.svg'
import logo from '../../assets/logo-lg.svg'
import axios from 'axios';
/* import { googleLogout, useGoogleLogin } from '@react-oauth/google'; */
import LoginModal from '../modal/Modal_login';

const NavBar = (props) => {

  const location      = useLocation();
  const searchParams  = new URLSearchParams(window.location.search);
  const tw_token      = searchParams.get('token');
  const navigate      = useNavigate();
  const user_id       = localStorage.getItem('nift_user_id');
  
  const [loginuser, setLoginuser] = useState('');
  const [anchorElUser, setAnchorElUser] = useState(null);
  
/*const [ gooleuser, setGoogleUser ] = useState([]);
  const [ profile, setProfile ] = useState([]); */
  const [openLogin, setOpenLogin]   = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)'
  })
  
/* const goolelogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setGoogleUser(codeResponse);
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  const gLogOut = () => {
    googleLogout();
    setProfile(null);
  };
  
  useEffect(() => {
    if (gooleuser) {
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gooleuser.access_token}`, {
            headers: {
                Authorization: `Bearer ${gooleuser.access_token}`,
                Accept: 'application/json'
            }
        }).then((res) => {
            console.log(res.data);
            setProfile(res.data);
        }).catch((err) => console.log(err));
    }},
    [ gooleuser ]
  ); */

  const walletByToken = (tw_token) => {
    const url = 'https://api.drop.fish/api-flow/wallet-by-token';
    const formData = new FormData();
    formData.append('token', tw_token);
    formData.append('access_token', localStorage.getItem("lh_access_token"));
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    
    axios.post(url, formData, config).then(function(response){
        if(response.data.message == 'success'){
            localStorage.setItem('user_name',response.data.data.name);
            localStorage.setItem('nift_user_id',response.data.data.user_id);
            localStorage.setItem('auth_type',response.data.data.auth_type);
            setLoginuser(localStorage.getItem('user_name'));
            props.setLoginData(response.data.data.user_id)
        }
        else if(response.data.message == 'invalid_token'){
          props.getOAuthToken().then(res => {
              walletByToken(tw_token);
          });
      }
    });
  }

  useEffect(()=>{
    if(tw_token){
      walletByToken(tw_token);
    }
},[tw_token]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () =>{
    handleCloseUserMenu();
    localStorage.removeItem('nift_user_id');
    localStorage.removeItem('user_name');
    localStorage.removeItem('auth_type');
    window.location.replace('https://api.drop.fish/logout?back='+location.pathname);
  }

  useEffect(()=>{
    if(user_id && localStorage.getItem('user_name') != null)
      setLoginuser(localStorage.getItem('user_name'));

  },[user_id]);


  const logIn = () => {
    setOpenLogin(true)
   // window.location.replace('https://api.drop.fish/auth?drop=login');
  }

  const settings = () => {
    navigate('/settings')
  }
  
  return (
    <ThemeProvider theme={ThemeCustom}>
      <LoginModal setOpenLogin={setOpenLogin} openLogin={openLogin}/>   
        <AppBar position="fixed" className='bg-dark shadow-none border-b border-solid border-0 border-b-[#333642] py-7'>
        <Container maxWidth="xl">
            <Toolbar disableGutters className='flex justify-between'>
                <div className='flex items-center'>
                  {(location.pathname=='/drop' || location.pathname=='/reward' || location.pathname=='/page' || location.pathname=='/criteria' || location.pathname=='/sign_up')?(
                    <Link to={'/drop'} className='flex items-center'>
                      <img src={logo} alt='Logo' className={`${NavStyle['nav-img-width']} w-full`}/>
                    </Link>  
                  ):(
                    <Link to={'/tank'} className='flex items-center'>
                      <img src={logo} alt='Logo' className={`${NavStyle['nav-img-width']} w-full`}/>
                    </Link>  
                  )}
                </div>
                <Box sx={{ flexGrow: 0 }} className="flex items-center ">               
                {(loginuser)?(
                    <Box component={'div'} onClick={handleOpenUserMenu} className='flex items-center cursor-pointer' >
                        <Typography className='mr-6 hidden sm:block' variant="text20" component={'div'}>{loginuser}</Typography>
                        <Tooltip title="Open settings">
                        <IconButton sx={{ p: 0 }}>
                            <Avatar alt={loginuser} src="#" />
                            <ArrowDropDownIcon className='text-white'/>
                        </IconButton>
                        </Tooltip>
                    </Box>
                ):(
                  <ButtonMain variant='outlineBtn' text='Login' type='button' onClick={logIn}/>
                )}

                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >   
                        {/* <MenuItem onClick={settings} className={NavStyle['nav-item-wrapper']} >
                            <Typography textAlign="center" component={'div'} variant="userDropdown" className={NavStyle['nav-item']}>
                                <img src={SettingIcon} alt='settings' width="24"/>
                                Settings
                            </Typography>
                        </MenuItem> */}
                        <MenuItem onClick={logout} className={NavStyle['nav-item-wrapper']} >
                            <Typography textAlign="center" component={'div'} variant="userDropdown" className={NavStyle['nav-item']}>
                                <img src={LogoutIcon} alt='Logout' width="24" className={NavStyle['nav-icon-rotate']}/>
                                Logout
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </Container>
        </AppBar>
    </ThemeProvider>    
  );
}
export default NavBar;