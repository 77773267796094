import React, {useState, useEffect} from 'react';
import {Container} from '@mui/material';
import { Route, Routes, useLocation,useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import DropComp from "../components/common-comp/Drop_comp";
import DropNav from "../components/nav/Drop_nav";
import DropFooterNav from '../components/nav/Drop_footer_nav';

const Unlock = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const drop_text = params.drop_text;
    const user_id   = params.user_id;
    const action    = params.action;
    const [isLoading, setIsLoading] = useState(true);
    const [drop, setDrop] = useState(null);
    
    const getDrops = async (drop_text) => {
      const access_token = localStorage.getItem("lh_access_token");
      let url = 'https://api.drop.fish/api-flow/get-drops?dt='+drop_text+'&uid='+user_id+'&access_token='+access_token;
      //let url = 'http://localhost/lighthouseapi/api-flow/get-drops?dt='+drop_text+'&uid='+user_id;

      if(user_id){
        if(action == 'unlock'){
          //url = 'http://localhost/lighthouseapi/api-flow/private-drop?dt='+drop_text+'&uid='+user_id+'&a=unlock&access_token='+access_token;
          url = 'https://api.drop.fish/api-flow/private-drop?dt='+drop_text+'&uid='+user_id+'&a=unlock&access_token='+access_token;
        }
        else
          url = 'https://api.drop.fish/api-flow/get-drops?dt='+drop_text+'&uid='+user_id+'&p=share&access_token='+access_token;
      }
      
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          },
      };
      
      axios.get(url, config).then(function (response) {
          if(response.data.message == 'success' && response.data.data){
            setDrop(response.data.data[0]);
            document.title = 'Unlock '+response.data.data[0].title+' | Dropfish'; 
            setIsLoading(false);
          }
          else {
            if(response.data.message == 'invalid_token'){
              props.getOAuthToken().then(res => {
                  getDrops(drop_text);
              });
            }
            else if(response.data.message == 'link_expired'){
                navigate("/info",{
                  state:{
                    id:'#5',
                    drop:drop_text,
                    uid:user_id,
                    token:access_token
                  }
                });
            }
            else if(response.data.message == 'already_claimed') {
              navigate("/info",{
                state: '#6'
              });
            }
            else {
              navigate("/info",{
                state: '#4'
              });
            }
          }
      });
    }

    useEffect(() => {
      getDrops(drop_text);
    },[drop_text]);

    useEffect(() => {
      if(drop)
        document.body.style.background = `url(${drop.bg_img}), #000 no-repeat`;
    },[drop]);
    
    return(
        <>
        {(!isLoading)?(
          <Container maxWidth="xl" className='flex flex-col justify-between min-h-[100vh]'>
            <DropNav navDetails={drop}/>
              <DropComp drop={drop} getOAuthToken={props.getOAuthToken} />
              <div className='mb-12'></div>
              <DropFooterNav dropClass={drop.class}/>
          </Container>
        ):(
          ''
        )}

        </>
    )
}

export default Unlock;