import {Container} from '@mui/material';

import Logo from '../../assets/logo-lg.svg';
import YouTubeLogo from '../../assets/Logo_YouTube.svg';

const DropFooterNav = (props) => {
    return(
        <Container maxWidth="false">
            {(props.dropClass == 1)?(
                <div className='justify-center items-center flex sm:items-end flex-col relative sm:mb-12 mt-12 mb-6 sm:mt-0'>
                    <div className='text-white font-sans text-end text-xs mb-1'>Powered by</div>
                    <img src={Logo} alt='logo' className='w-full max-w-[140px]'/>
                </div>
            ):('')}
            {(props.dropClass == 2)?(
                <div className='flex flex-col md:flex-row sm:justify-between lg:items-center relative my-14 '>
                    <div className='text-white font-sans text-left text-xl md:pr-16 sm:max-w-[75%] mb-5 md:mb-0'>Dropfish's use and transfer to any other app of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy' target="blank" class="drop_link">Google API Services User Data Policy</a>, including the Limited Use requirements.</div>
                    <img src={YouTubeLogo} alt='youtube logo' className='w-full max-w-[19rem]'/>
                </div>
            ):('')}
        </Container>
    )
}

export default DropFooterNav;