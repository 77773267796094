import React, {useState, useEffect} from 'react';

import { Switch, styled, FormControlLabel  } from '@mui/material';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
    width: '4.875rem',
    height: '2.5rem',
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: '0.25rem',
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(2.275rem)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#05C270' : '#05C270',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#05C270',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? '#05C270'
            : '#05C270',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: '2rem',
      height: '2rem',
    },
    '& .MuiSwitch-track': {
      borderRadius: 46 / 2,
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(124, 126, 128, 0.2)' : 'rgba(124, 126, 128, 0.2)',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
}));


const SwitchInput = (props) => {

    const [checked, setChecked] = useState(false)

    const handleChange = (e) => {
        setChecked(e.target.checked);
        props.setCheck(e.target.checked)
    };

    useEffect(() => {
      if(props.checked=='true')
        setChecked(true);  
    },[props.checked]);

    return(
        <FormControlLabel className='font-sans font-base text-white'
            control={ <IOSSwitch className="mr-4"  checked={checked} onChange={handleChange}/>}
            label={props.text}
        />
    );
}

export default SwitchInput;