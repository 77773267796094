import React, {useState, useEffect} from 'react';
import {ThemeProvider, FormControl, InputLabel, OutlinedInput, Select, MenuItem} from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";
import NavStyle from '../nav/Nav_style.module.scss';

const sourceMenu = {'emails': 'Emails', 'social_content':'Social or Content Platform','ecommerce':'E-commerce','api':'API'}

const AdvancedDropdown = (props) => {
    const handleChangePlatform = (e) => {
        props.setSelectPlatform(e.target.value)
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 flex flex-col'>
                <InputLabel shrink labelid="sourceItem-input">{sourceMenu[props.source]}</InputLabel>
                <Select
                    labelid="sourceItem-input"
                    id="sourceItem-input"
                    value={props.selectPlatform}
                    label="source"
                    onChange={handleChangePlatform}
                    input={<OutlinedInput />}
                    displayEmpty
                    name="sourceItem"
                    //{...register("source",{ required: true })}
                >
                    <MenuItem className={NavStyle['admin-nav-item']} selected disabled value={''}>-Select-</MenuItem>
                    {props.sourceItem.length !== 0 && props.sourceItem.map((item, index) => (
                        <MenuItem key={index+1} className={NavStyle['admin-nav-item']} value={item}>{item}</MenuItem>
                    ))}    
                </Select>
                {/*errors.source && <span className='err-msg'>This is required.</span>*/}
            </FormControl>
        </ThemeProvider>
    )
}

export default AdvancedDropdown;