import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";
import ButtonStyle from './Button_style.module.scss';
import './Button_img_styes.scss'


const ButtonImg = (props) => {

    return (
        <ThemeProvider theme={ThemeCustom}>
          <Button variant={props.variant}  type={props.type} className={props.classes} onClick={props.onClick}>
            <img src={props.img} className='max-w-[8rem]' alt='icon'/>
          </Button>
        </ThemeProvider>
    );
}

export default ButtonImg;
