import React, {useEffect, useState} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ThemeMain } from "./styles/Theme";
import "./styles/custom_styles.scss";

import Drops from './views/Drops';
import UseLink from './views/Use_link';
import Unlock from './views/Unlock';
import Home from './views/home';
import axios from 'axios';
import MyRewards from './views/Admin_my_rewards';
import SignIn from './views/Signin';
import SignUp from './views/Signup';
import SetUpRewards from './views/Admin_setup_rewards';
import CreateDrop from './views/Create_drop';
import SetUpCriteria from './views/Admin_setup_criteria'
import Messages from './views/Messages';
import Settings from './views/Settings';

function App() {
  const location = useLocation();
  const [navBar, setNavBar] = useState(false);
  let headTtle = 'DropFish | Reward Engagement Everywhere';

  const getOAuthToken = async () => {
    localStorage.removeItem("lh_access_token");
    const url = 'https://api.drop.fish/token';
    //const url      = 'http://localhost/lighthouseapi/token';
    const formData = new URLSearchParams();
    formData.append('grant_type','client_credentials');
    const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ZHJvcGZpc2gtMjNqc3llVXdrOmVjM2U0YjNkM2RmNWMxOTFhZjU0V3U3MjY0QXFzNDJkOTJhZDk3NTQwNmNi'
        },
    };
    
    const response = await axios.post(url, formData, config);
    
    if(response.data.access_token){
      localStorage.setItem("lh_access_token",response.data.access_token);
      return response.data.access_token;
    }
  }

  useEffect(() => {
    switch (location.pathname) {
        case "/utility":
          setNavBar(false);
          //document.body.style.background = `url(${congratInfo.bgImg}), #000`;
          document.body.style.minHeight = 'calc(100vh - 5rem)';
        break;

        case "/tank":
          setNavBar(true);
          document.body.style.background = "none";
          document.body.style.background = "#141416";
          headTtle = 'The Tank - Check out and collect drops | Dropfish';
        break;

        case "/drop":
          setNavBar(true);
          document.body.style.background = "none";
          document.body.style.background = "#141416";
          headTtle ='Create and Manage Your Drops | Dropfish';
        break;

        case "/settings":
          setNavBar(true);
          document.body.style.background = "#141416";
          headTtle = 'My Tank Settings | Dropfish';
        break;

        case "/reward":
          setNavBar(true);
          document.body.style.background = "#141416";
          document.body.className = 'setup_rewards';
          headTtle = 'Create Drop Reward| Dropfish';
        break;

        case "/criteria":
          setNavBar(true);
          document.body.style.background = "#141416";
          document.body.className = 'setup_criteria';
          headTtle = 'Setup Drop Criteria | Dropfish';
        break;

        case "/page":
          setNavBar(false);
          document.body.style.background = "#141416";
          headTtle = 'Drop Preview | Dropfish';
        break;

        case "/sign_up":
          document.body.style.background = "#141416";
          headTtle = 'Sign Up | Dropfish';
        break;

        case "/sign_in":
          document.body.style.background = "#141416";
          headTtle = 'Login | Dropfish';
        break;

        case "/info":
          //document.body.style.background = "#141416";
          headTtle = 'No Drops Here | Dropfish';
        break;

        default:
          setNavBar(true);
          /* if(location.pathname == "/utility")
            window.location.replace('https://web.drop.fish'); */
          document.body.style.background = "#141416";
        break;
    }

    /*const metaTag = document.querySelector('meta[name="description"]');
    metaTag.setAttribute('content', 'Your new description here'); */
    document.title = headTtle; 
  }, [location]);

  return (
    <ThemeProvider theme={ThemeMain}>
      <CssBaseline enableColorScheme />
        <Routes>
          <Route path='/tank' element={<Drops getOAuthToken={getOAuthToken} />} />
          <Route path='/utility' element={<UseLink getOAuthToken={getOAuthToken} />} />
          <Route path='/:drop_text' element={<Unlock getOAuthToken={getOAuthToken} />} />
          <Route path='/:drop_text/:user_id' element={<Unlock getOAuthToken={getOAuthToken}/>} />
          <Route path='/:drop_text/:user_id/:action' element={<Unlock getOAuthToken={getOAuthToken}/>} />
          <Route path='/info' element={<Messages getOAuthToken={getOAuthToken}/>} />
          <Route path='/settings' element={<Settings getOAuthToken={getOAuthToken}/>} />
          <Route path='/drop' element={<MyRewards getOAuthToken={getOAuthToken}/>}/>
          <Route path='/page' element={<CreateDrop getOAuthToken={getOAuthToken}/>}/>
          <Route path='/reward' element={<SetUpRewards getOAuthToken={getOAuthToken}/>}/>
          <Route path='/criteria' element={<SetUpCriteria getOAuthToken={getOAuthToken}/>}/>
          <Route path='/sign_in' element={<SignIn getOAuthToken={getOAuthToken}/>}/>
          <Route path='/sign_up' element={<SignUp getOAuthToken={getOAuthToken}/>}/>
          {/* <Route path='/discord/login' element={<DiscodeLogin />}/> */}
          <Route path='/' element={<Home />} />
        </Routes>
    </ThemeProvider>
  );
}

export default App;
