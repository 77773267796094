import React, {useState, useEffect} from 'react';
import {ThemeProvider, Typography, Container} from '@mui/material';
import ButtonPrimary from '../button/Btn_primary';
import { ThemeCustom} from "../../styles/Theme";
import axios from 'axios';
import { useNavigate, useLocation,useParams } from 'react-router-dom';
import SuccessAlert from '../alerts/Success_alert';
import PendingAlert from '../alerts/Pending_alert';
import ErrorAlert from '../alerts/Fail_alert';
import WaitModal from '../modal/Modal_wait';
import logo from '../../assets/logo-sm.svg';
import fishIcon from '../../assets/fish-icon.svg';
import dropload from '../../assets/drop-load.gif';
import ButtonImg from '../button/Button_img';
import Youtube from '../../assets/Youtube_Logo.svg'
import Discord from '../../assets/discord.svg'
import CountDown from '../count-down/Countdown';
import momentTimezone from 'moment-timezone';
import LoginModal from '../modal/Modal_login';
import { InstagramLogin } from '@amraneze/react-instagram-login';

const DropComp = (props) => {
    let userId;
    let userAddress;
    
    const user_id      = localStorage.getItem('nift_user_id');
    const share_button = {btnName: "SHARE YOUR DROP", btnLink: "#", color: "#039503"};
    const searchParams = new URLSearchParams(window.location.search);
    const tw_token     = searchParams.get('token');
    const navigate     = useNavigate();
    const params       = useParams();

    const [loginuser, setLoginuser]     = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail]       = useState(false);
    const [openPending, setOpenPending] = useState(false);
  
    const [textPending, setTextPending] = useState();
    const [textSuccess, setTextSuccess] = useState();
    const [textFail, setTextFail]   = useState();
    const [btnstatus, setBtnstatus] = useState('no');
    const [openWait, setOpenWait]   = useState(false);
    const location = useLocation();

    const [ gooleuser, setGoogleUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    const [endtime, setEndTime]   = useState();

    const display_time   = momentTimezone.unix(props.drop.start_time).format('YYYY-MM-DD HH:mm:ss');
    const countdown_time = momentTimezone.unix(props.drop.expire_date).format('YYYY-MM-DD HH:mm:ss');

    const [displayTimer, setDisplayTimer] = useState(props.drop.start_time > 0 ?1:0);
    const [displayTimeRemain, setDisplayTimeRemain] = useState(props.drop.expire_date > 0 ?1:0);
    const [openLogin, setOpenLogin]   = useState(false);

    const walletByEmailToken = (props) => {
        if(props.drop.class == 9 && props.drop.status != 'USE LINK'){
            let drop_text   = params.drop_text;
            let email_token = params.user_id;
            let action      = params.action;
            const url       = 'https://api.drop.fish/api-flow/wallet-by-email-token';
            const formData  = new FormData();

            formData.append('token', email_token);
            formData.append('access_token', localStorage.getItem("lh_access_token"));
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            
            axios.post(url, formData, config).then(function(response){
                if(response.data.message == 'success'){
                    setOpenWait(true);
                    localStorage.setItem('user_name',response.data.data.name);
                    localStorage.setItem('nift_user_id',response.data.data.user_id);
                    localStorage.setItem('auth_type',response.data.data.auth_type);
                    userAddress = response.data.data.address;
                    userId = response.data.data.user_id;
                    nftTransfer();
                }
                else 
                {
                    if(response.data.message == 'invalid_token'){
                        props.getOAuthToken().then(res => {
                            walletByEmailToken(props);
                        });
                    }
                    else {
                        navigate("/info",{
                            state: props.drop.drop_text
                        });
                    }
                }
            });
        }
    }

    const walletByToken = (tw_token) => {
        const url = 'https://api.drop.fish/api-flow/wallet-by-token';
        const formData = new FormData();
        formData.append('token', tw_token);
        formData.append('nift_user_id', localStorage.getItem("nift_user_id"));
        formData.append('access_token', localStorage.getItem("lh_access_token"));
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        
        axios.post(url, formData, config).then(function(response){
            if(response.data.message == 'success'){
                setOpenWait(true);
                localStorage.setItem('user_name',response.data.data.name);
                localStorage.setItem('nift_user_id',response.data.data.user_id);
                localStorage.setItem('auth_type',response.data.data.auth_type);
                userAddress = response.data.data.address;
                userId = response.data.data.user_id;
                nftTransfer(response.data.data.drop_owner);
            }
            else 
            {
                if(response.data.message == 'invalid_token'){
                    props.getOAuthToken().then(res => {
                        walletByToken(tw_token);
                    });
                }
                else {
                    navigate("/info",{
                        state: props.drop.drop_text
                    });
                }
            }
        });
    }

    useEffect(() => {
        walletByEmailToken(props);
    },[props]);

    useEffect(()=>{
        if(tw_token == 9) {
            navigate("/info",{
                state: '#9'
            });
        }
        else if(tw_token){
            walletByToken(tw_token);
        }
        else if(tw_token == 0) {
            navigate("/info",{
                state: props.drop.drop_text
            });
        }
    },[tw_token]);

    const login = () => {
        if(user_id){
            const url = 'https://api.drop.fish/api-flow/unlock-without-c';
            //const url = 'http://localhost/lighthouseapi/api-flow/unlock-without-c';
            const formData = new FormData();
            formData.append('user_id', user_id);
            formData.append('access_token', localStorage.getItem("lh_access_token"));
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            
            axios.post(url, formData, config).then(function(response){
                if(response.data.message == 'success'){
                    setOpenWait(true);
                    userAddress = response.data.data.address;
                    userId = response.data.data.user_id;
                    nftTransfer();
                }
                else 
                {
                    if(response.data.message == 'invalid_token'){
                        props.getOAuthToken().then(res => {
                            login();
                        });
                    }
                    else {
                        navigate("/info",{
                            state: props.drop.drop_text
                        });
                    }
                }
            });
        }
        else
            setOpenLogin(true);
    }

    const tweeterLogin = () => {
        let user_id = localStorage.getItem('nift_user_id');
        window.location.replace('https://api.drop.fish/auth?drop='+props.drop.drop_text+'&user_id='+user_id);
        //window.location.replace('http://localhost:8888/lighthouseapi/auth?drop='+props.drop.drop_text+'&user_id='+user_id);
    }

    const shareLink = () => {
        const tweetText = "I just unlocked "+props.drop.title+" from "+props.drop.brand+". You can check if you're eligible for this drop below.";
        const tweetUrl = 'http://api.drop.fish/drops?d='+props.drop.nft_id; 
    
        const tweetUrlEncoded = encodeURIComponent(tweetUrl);
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${tweetUrlEncoded}`;

        window.open(twitterShareUrl);
        // window.open("https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fwww.drop.fish%2F7&text=Generative%20art%20based%20on%20the%20lyrics%20to%20%E2%80%9CLucy%20in%20the%20Diamonds%E2%80%9D%20by%20the%20Beatles.&url=https%3A%2F%2Fwww.drop.fish%2F7%2F", '_blank');
    }

    const useLink = () => {
        window.open(props.drop.drop_use_link, '_blank');
    }

    const nftTransfer = async (drop_owner=0) => {
/*      setTextSuccess('Wallet successfully created. <a target="_blank" href="https://testnet.flowscan.org/account/'+userAddress+'" class="text-white">View Transaction</a>');
        setOpenSuccess(true); */

        const url = 'https://api.drop.fish/api-flow/nft-transfer';
        const formData = new FormData();
        const drop_id  = searchParams.get('drop');
        let nftModelId = props.drop.nft_model_id;
        
        if(nftModelId != null && nftModelId.length > 1) {
            formData.append('address', userAddress);
            formData.append('userId', (drop_owner !== 0)?drop_owner:userId);
            formData.append('nftModelId', nftModelId);
            formData.append('access_token', localStorage.getItem("lh_access_token"));
            
            const config = {
                headers: {'content-type': 'multipart/form-data'},
            };
            
            axios.post(url, formData, config).then(function (response) {
                
                if(response.data.message == 'already' ) {
                    setTextSuccess('Contragulation! You have already unlocked this reward.');
                    setOpenSuccess(true);

                    setTimeout(()=>{
                        navigate("/utility",{
                            state: props.drop.drop_text
                        });
                    },2000);
                }
                else if(response.data.message == 'no_available'){
                    navigate("/info",{
                        state: '#2'
                    });
                }
                else if(response.data.message == 'invalid_token'){
                    props.getOAuthToken().then(res => {
                        nftTransfer();
                    });
                }
                else {
                    if(response.data.data.data.transfer && response.data.data.data.transfer.id){
                        navigate("/utility",{
                            state: props.drop.drop_text
                        });
                    }
                    else {
                        navigate("/info",{
                            state: props.drop.drop_text
                        });
                    }
                }
            });
        }
        else {
            navigate("/info",{
                state: props.drop.drop_text
            });
        }
    }

    const goolelogin  = () => {
        let user_id = localStorage.getItem('nift_user_id');
        window.location.replace('https://api.drop.fish/google-auth?drop='+props.drop.drop_text+'&user_id='+user_id);
        //window.location.replace('http://localhost:8888/lighthouseapi/auth?drop='+props.drop.drop_text+'&user_id='+user_id);
    }

    const discordlogin  = () => {
        let user_id = localStorage.getItem('nift_user_id');
        window.location.replace('https://api.drop.fish/discord-auth?drop='+props.drop.drop_text+'&user_id='+user_id);
        //window.location.replace('http://localhost/lighthouseapi/discord-auth?drop='+props.drop.drop_text+'&user_id='+user_id);
    }
    
    const instagramLogin = () => {
        let user_id = localStorage.getItem('nift_user_id');
        window.location.replace('https://api.drop.fish/instagram-auth?drop='+props.drop.drop_text+'&user_id='+user_id);
    }
    /* 
    const goolelogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
          setGoogleUser(codeResponse);
          window.location.replace(`http://localhost:8888/lighthouseapi/google-auth?drop=${props.drop.drop_text}&access_token=${codeResponse.access_token}`);
        },
        onError: (error) => console.log('Login Failed:', error)
    }); */

    const responseInstagram = (response) => {
       //console.log(response);
       window.location.replace('https://api.drop.fish/instagram-auth?drop='+props.drop.drop_text+'&code='+response+'&user_id='+user_id);
      //window.location.replace('http://localhost/lighthouseapi/instagram-auth?drop=login'+props.drop.drop_text+'&code='+response);
    }

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)
    },[openFail]);
  
    useEffect(()=>{
        setTimeout(()=>{
           setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    useEffect(()=>{
        setTimeout(()=>{
            setOpenPending(false);
        },10000)
    },[openPending]);

    return(
        <>
        <LoginModal setOpenLogin={setOpenLogin} openLogin={openLogin} drop={props.drop.drop_text}/>
        <WaitModal setOpenWait={setOpenWait} openWait={openWait}/>
        <div className='fixed top-12 right-8 w-full z-[1999] h-0'>
            <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
            <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            <PendingAlert setOpenPending={setOpenPending} openPending={openPending} alretText={textPending}/>
        </div>
        <ThemeProvider theme={ThemeCustom}>
            <Container maxWidth="false">
            {(location.pathname == "/utility" || props.drop.share_link)?(
                <div className='flex items-start flex-col mt-4'>
                    <div className='flex items-start md:items-center md:flex-row flex-col w-full'>
                        {/* <img src={props.drop.img} alt='unlock drop' className='w-full max-w-[27rem] border-4 border-solid border-white rounded-3xl'/> */}
                        <div className='relative'>
                            <div className='md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem]'>   
                                <img src={props.drop.img} alt='unlock drop' className='w-full max-w-[27rem] object-top rounded-3xl object-cover md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem]'/>
                            </div>
                            <div className='h-full absolute bottom-0 left-1/2 translate-x-1/2M w-full bg-gradient flex flex-col justify-between border-4 border-solid border-white rounded-3xl'>
                                <div className='pt-5 w-full flex justify-between px-4 items-start'>
                                    <Typography variant="text14Code" align='left' className='line-clamp-2 w-1/2 pl-1' component="div">
                                        {props.drop.brand}
                                    </Typography>
                                    <Typography variant="text14Code" align='right' className='line-clamp-2 w-1/2 pr-1' component="div">
                                        {props.drop.title}
                                    </Typography>
                                </div>
                                <div className=' pb-5 w-full flex flex-col justify-between px-4'>
                                    <div className='flex justify-between pb-2 items-end'>
                                        <Typography variant="text14TwitterTag" align='left' className='line-clamp-2 w-5/12' component="div">
                                            {(props.drop.tw_name != 'XXX')?(
                                                '@'+props.drop.tw_name
                                            ):('')}
                                        </Typography>
                                        <Typography variant="text14Code" align='right' className='line-clamp-2 w-5/12 flex justify-end' component="div">
                                        {(props.drop.serial_number =='...')?(
                                            <img src={dropload} alt='loading' className='max-w-[1.5rem]'/>
                                        ):(props.drop.serial_number)}
                                        </Typography>
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <Typography variant="text14Code" align='left' className='line-clamp-2 w-full flex items-center' component="div">
                                        <img src={fishIcon} alt='fish' className='w-full max-w-[0.5rem] mr-2' /> {props.drop.user_number}
                                        </Typography>
                                        <img src={logo} alt='logo' className='w-full max-w-[6rem] opacity-50' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='md:max-w-[calc(100%-27rem)]'>
                            <Typography variant='header50' component={'div'} className='md:ml-12 md:mt-0 mt-14 text-left break-all sm:break-normal' >Congratulations!</Typography>
                            <Typography variant='text30' component={'div'} className='mt-6 md:ml-12 text-left line-clamp-2'>You have been whitelisted and unlocked {props.drop.title}</Typography>
                            {(props.drop.share)?(
                            <Typography variant='text30' component={'div'} className='mt-6 md:ml-12 text-left line-clamp-2'>
                                <ButtonPrimary classes='w-full sm:w-auto' type='button' onClick={shareLink} buttonProp={share_button}/>
                            </Typography>):('')}
                        </div>
                    </div>                
                    <Typography variant='text30Green' component={'div'} className='mb-14' ></Typography>

                    {(props.drop.use_link_text.length > 1)?(
                        props.drop.use_link_text.map((textItem, index)=> (
                            <div key={index} className='flex'>
                                <Typography variant='text30' component={'div'} className='me-2'>{index+1}.</Typography>
                                <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem}} />
                            </div>
                        ))
                    ):(
                        props.drop.use_link_text.map((textItem, index)=> (
                            <div key={index} className='flex'>
                                <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem}} />
                            </div>
                        ))
                    )}

   {/*                  <div className='flex'>
                        <Typography variant='text30' component={'div'} className='mb-3 userContent' dangerouslySetInnerHTML={{__html:props.drop.use_link_text}} />
                    </div> */}
                    {(props.drop.use_link_button)?(
                    <div className='mt-16 w-full sm:w-auto'>
                        <ButtonPrimary classes='w-full sm:w-auto' type='button' onClick={useLink} disabled={btnstatus} buttonProp={props.drop.button}/>
                    </div>
                    ):('')}

                    {(props.drop.dtr == 1 && displayTimeRemain == 1)?(   
                        <CountDown setVisible={setDisplayTimeRemain} type="end" timmer={countdown_time} dropText={0}/>
                    ):('')}
                </div>
            ):(
                <div className='flex items-center md:items-start flex-col mt-4 '>
                    <div className='flex items-center md:items-center md:flex-row flex-col w-full'>
                        {/* <img src={props.drop.img} alt='unlock drop' className='w-full max-w-[27rem] border-4 border-solid border-white rounded-3xl'/> */}
                        <div className='relative'>
                            <div className='md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem]'>   
                                <img src={props.drop.img} alt='unlock drop' className='w-full max-w-[27rem]  object-top rounded-3xl object-cover md:min-h-[26.5rem] md:max-h-[26.5rem] min-h-[28.286rem] max-h-[28.286rem]'/>
                            </div>
                            <div className='h-full absolute bottom-0 left-1/2 translate-x-1/2M w-full bg-gradient flex flex-col justify-between border-4 border-solid border-white rounded-3xl'>
                                <div className='pt-5 w-full flex justify-between px-4 items-start'>
                                    <Typography variant="text14Code" align='left' className='line-clamp-2 w-1/2 pr-1' component="div">
                                        {props.drop.brand}
                                    </Typography>
                                    <Typography variant="text14Code" align='right' className='line-clamp-2 w-1/2 pl-1' component="div">
                                        {props.drop.title}
                                    </Typography>
                                </div>
                                
                                <div className=' pb-5 w-full flex flex-col justify-between px-4'>
                                    <div className='flex justify-between pb-2 items-end'>
                                        <Typography variant="text14TwitterTag" align='left' className='line-clamp-2 w-5/12' component="div">
                                            {(props.drop.tw_name != 'XXX')?(
                                                '@'+props.drop.tw_name
                                            ):('')}
                                        </Typography>
                                        <Typography variant="text14Code" align='right' className='line-clamp-2 w-5/12' component="div">
                                            {props.drop.serial_number} 
                                        </Typography>
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <Typography variant="text14Code" align='left' className='line-clamp-2 w-full flex items-center' component="div">
                                        <img src={fishIcon} alt='fish' className='w-full max-w-[0.5rem] mr-2' /> {props.drop.user_number}
                                        </Typography>
                                        <img src={logo} alt='logo' className='w-full max-w-[6rem] opacity-50' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='md:ml-12 md:mt-0 mt-14 text-left md:max-w-[calc(100%-27rem)]'>
                            <Typography variant='header50' component={'div'} className='text-center md:text-left break-words sm:break-normal md:line-clamp-2 md:text-[3.125rem] text-[2.5rem]'>{props.drop.title}</Typography>
                            <Typography variant='text30' component={'div'} className=' text-center md:text-left break-words mt-3 sm:break-normal md:line-clamp-2 sm:text-[1.65rem] text-[1.75rem]'>{props.drop.sub_title}</Typography>
                        </div>
                    </div>
                    {(props.drop.dc == 1 && !(props.drop.class == 10))? (
                        <>
                            {(props.drop.class < 9)?(<Typography variant='text30Green' component={'div'} className='my-14' >To unlock:</Typography>):('')}
                            {(props.drop.info_text.length > 1)?(
                                props.drop.info_text.map((textItem, index)=> (
                                    <div key={index} className='flex'>
                                        <Typography variant='text30' component={'div'} className='me-2'>{index+1}.</Typography>
                                        <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem.text}} />
                                    </div>
                                ))
                            ):(
                                props.drop.info_text.map((textItem, index)=> (
                                    <div key={index} className='flex'>
                                        <Typography variant='text30' component={'div'} className='mb-3' dangerouslySetInnerHTML={{__html:textItem.text}} />
                                    </div>
                                ))
                            )}
                        </>
                    ):('')}
                    {(props.drop.dcd == 1 && displayTimer == 1)?
                        (<CountDown setVisible={setDisplayTimer} type="start" timmer={displayTimer} dropText={1}/>)
                    :(
                        <>
                        <div className='mt-16 w-full sm:w-auto'>
                            {(props.drop.class==1)?(
                                <ButtonPrimary classes='w-full sm:w-auto' type='button' disabled={btnstatus}  onClick={props.drop.class==1?tweeterLogin:goolelogin} buttonProp={props.drop.button}/>
                            ):('')}
                            {(props.drop.class==2)?(
                                <div>
                                    <div className='text-white font-sans font-medium text-xl mb-4'>Unlock drop with</div>
                                    <ButtonImg img={Youtube} type='button' disabled={btnstatus} onClick={goolelogin} classes='bg-white py-5 px-12 rounded-xl hover:bg-[#ffffffc2]'/>
                                </div>
                            ):('')}
                            {(props.drop.class==4)?(
                                <div>
                                    {/* <div className='text-white font-sans font-medium text-xl mb-4'>Unlock drop with discord</div> */}
                                    <ButtonPrimary classes='w-full sm:w-auto' type='button' disabled={btnstatus}  onClick={discordlogin} buttonProp={props.drop.button}/>
                                </div>
                            ):('')}
                            {(props.drop.class==10)?(
                                <ButtonPrimary classes='w-full sm:w-auto' type='button' disabled={btnstatus}  onClick={login} buttonProp={props.drop.button}/>
                            ):('')}
                            {(props.drop.class==5)?(
                                <InstagramLogin clientId="1671951633329257" cssClass='insta-btn' scope="user_profile" buttonText="Unlock Drop with Instagram" onSuccess={responseInstagram} onFailure={responseInstagram} />
                            ):('')}
                        </div>
                        {(props.drop.dtr == 1 && displayTimeRemain == 1)?(   
                            <CountDown setVisible={setDisplayTimeRemain} type="end" timmer={countdown_time} dropText={0}/>
                        ):('')}
                        </>
                    )}
                </div>
                )}
            </Container>    
        </ThemeProvider>
        </>
    );
}

export default DropComp;