import { useEffect, useState } from "react";
import Countdown from "react-countdown";

const CountDown = (props) => {
    
    const [expireDate, setExpireDate] = useState(props.timmer);

    useEffect(()=>{
      setInterval(()=>{
          setExpireDate(props.timmer);
      },10000)
    },[props.timmer]);

    const Completionist = () => <div className="text-xl text-error text-right">Drop is Expired</div>;

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a complete state
          props.setVisible(0); //<Completionist />
        } else {
          // Render a countdown
          props.setVisible(1);
          return (
            <div className="mb-4 sm:mb-0 flex items-center mt-14">
                {(props.type == 'start')?(
                  <div className={`${props.dropText === 1 ? 'text-[2.5rem] mr-8':'text-xl mr-5 '} text-white font-semibold text-center sm:text-right`}>Drop starts in</div>
                ):(
                  <div className={`${props.dropText === 1 ? 'text-[2.5rem] mr-8':'text-xl mr-5 '} text-white font-semibold text-center sm:text-right`}>Drop ends in</div>
                )}
                <div className={`text-white font-timmerFont font-bold ${props.dropText === 1 ? 'text-5xl':'text-3xl'}`}> 
                {days}d : {hours}h : {minutes}m : {seconds}s
                </div>
            </div>
          );
        }
      };

  return(
    <Countdown date={expireDate} renderer={renderer} />
  ) 
}

export default CountDown;