import React, {useState, useEffect} from 'react';
import {ThemeProvider, Typography, FormControl,OutlinedInput,InputLabel, Container, FormControlLabel, Checkbox} from '@mui/material';
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form";
import axios from 'axios';
import { ThemeCustom} from "../styles/Theme";
import ButtonMain from '../components/button/Button_main';
import SuccessModal from '../components/modal/Modal_success';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo-lg.svg';
import CheckIcon from '../assets/tick-select.svg'; 
import UncheckIcon from '../assets/tick-notselect.svg'; 
import LoadingModal from '../components/modal/Modal_loading';

const SignUp = (props) => {
    const user_id  = localStorage.getItem('nift_user_id');
    const drop_id  = localStorage.getItem('lh_edit_drop');
    const navigate = useNavigate();
    const {register, handleSubmit,formState: { errors }} = useForm({});
    const [openSuccess, setOpenSuccess]   = useState(false);

    const createReward = async (user_email) => {
        const url = 'https://api.drop.fish/api-flow/create-reward';
        //const url = 'http://localhost/lighthouseapi/api-flow/create-reward';
        const formData = new FormData();
        
        //if(localStorage.getItem('lh_edit_drop'))
        formData.append('drop_id',drop_id);

        if(user_email)
            formData.append('user_email',user_email);

        if(user_id)
            formData.append('user_id',user_id);
        else 
        {
            let temp_user_id = localStorage.getItem('lh_trmp_user_id');
            if(temp_user_id)
                formData.append('user_id',temp_user_id);
        }

        formData.append('access_token', localStorage.getItem("lh_access_token"));

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.post(url, formData, config).then(function (response) {
            if(response.data.message == 'success'){
                localStorage.removeItem("lh_unique_csv");
                localStorage.removeItem("lh_setupform_data");
                localStorage.removeItem("lh_logo_image");
                localStorage.removeItem("lh_background_image");
                localStorage.removeItem("lh_emails_csv");
                localStorage.removeItem("lh_form_rewardImage");
                localStorage.removeItem("lh_bg_size");
                localStorage.removeItem("lh_criteriaform_data");
                localStorage.removeItem("lh_bg_name");
                localStorage.removeItem("lh_edit_drop");
                localStorage.removeItem("lh_display_time");
                localStorage.removeItem("lh_countdown_time");
                /* localStorage.removeItem("lh_unique_csv_file"); */
                if(response.data.data.temp_user){
                    localStorage.setItem('lh_trmp_user_id',response.data.data.user_id);
                }

                if(user_id)
                    navigate('/drop');
                else 
                {
                    //navigate('/sign-in');
                    navigate('/sign_in',{state:{'email':user_email,'drop_text':drop_id}});
                }
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    createReward(user_email);
                });
            }
        });
    }

    const signup = (data) =>{
       setOpenSuccess(true);
       createReward(data.email);
    }

    const handleChangeProperty = (e) =>{
        console.log(e);
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <LoadingModal setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} mid={4}/>  
            <Container maxWidth="xl">
                <div className='h-[100vh] flex items-center justify-center flex-col'>
                    <img src={logo} alt='logo' className='w-full max-w-[15rem] mb-20'></img>
                    <Typography variant='header50'>Great Job! Almost Done...</Typography>
                    <Typography variant='text24Gray' className='mt-5 mb-20 text-center'>It'll take a few minutes to generate your campaign. Drop your email below to get notified when it's ready</Typography>
                    <form className='w-full max-w-[27.5rem]' onSubmit={handleSubmit(signup)} >
                        <FormControl variant="standard" fullWidth  margin="normal" className='mb-6 flex flex-col'>
                            <InputLabel shrink htmlFor="email-input">Email</InputLabel>
                            <OutlinedInput 
                                id="email-input"
                                autoComplete="off" 
                                placeholder="Enter email" 
                                type='email'
                                //type='text'
                                {...register("email",{ required: true })} 
                            />
                            {errors?.email?.type === "required" && <p className='text-salsaRed'>Email is required</p>}
                        </FormControl>
                        <FormControl variant="standard" fullWidth  margin="normal" className='mb-10 mt-0'>
                            <FormControlLabel className='text-white font-sans text-base mx-0' 
                            onChange={handleChangeProperty}
                            control={
                                <Checkbox checkedIcon={<img src={CheckIcon}  alt='checked' className='w-8'/> }
                                icon={<img src={UncheckIcon} alt='uncheck' className='w-8'/> } 
                                className='pt-0 w-8 h-8 pl-0 mr-3' disableRipple={true}
                                {...register("agree",{ required: true })}
                                />
                            } 
                            label={<div>I agree to the <a href='#' target='_blank' className='text-white underline'>terms and conditions and API</a>  & <a href='#' target='_blank' className='text-white underline'>SDK License Agreement</a></div>} />
                            {errors?.agree?.type === "required" && <p className='text-salsaRed'>Please check this box if you want to proceed</p>}
                        </FormControl>
                        <ButtonMain type='submit' variant='goGreenBtn' text='FINISH' classes='w-full uppercase' />
                    </form>
                    {/* <Typography variant='text18Gray' className='mt-16'>I already have an account <Link to={'/sign_in'} className='no-underline text-white'>Sign in</Link></Typography> */}
                </div>
            </Container>
        </ThemeProvider>
    )
}

export default SignUp;