import React, {useState, useEffect} from 'react';
import {Container} from '@mui/material';
import { Route, Routes, useLocation, useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import DropCompShare from "../components/common-comp/Drop_comp_share";
import DropNav from "../components/nav/Drop_nav";
import DropFooterNav from '../components/nav/Drop_footer_nav'

const UseLink = (props) => {
    const navigate = useNavigate();
    const location  = useLocation();
    const params    = useParams();
    const drop_text = location && location.state?location.state:'';
    //const drop_text = 7;
    const user_id   = localStorage.getItem('nift_user_id');

    const [isLoading, setIsLoading] = useState(true);
    const [drop, setDrop] = useState(null);

    const getDrops = async () => {
      //const url = 'http://localhost/lighthouseapi/api-flow/get-drops?dt='+drop_text+'&p=use_link&uid='+user_id;
      const access_token = localStorage.getItem("lh_access_token");
      const url = 'https://api.drop.fish/api-flow/get-drops?dt='+drop_text+'&p=use_link&uid='+user_id+'&access_token='+access_token;
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          },
      };
      
      axios.get(url, config).then(function (response) {
          if(response.data.data && response.data.message == 'success' ){
            setDrop(response.data.data[0]);
            document.title = 'Winner! '+response.data.data[0].title+' | Dropfish'; 
            setIsLoading(false);
          }
          else {
            if(response.data.message == 'invalid_token'){
              props.getOAuthToken().then(res => {
                  getDrops();
              });
            }
            else {
              navigate("/info",{
                state: '#4'
              });
            }
          }
      });
    }

    const checkUserDropsCounters = async () => {
      let countSuccess = true;
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          },
      };

      if (drop.serial_number == '...') {
          countSuccess = false;
          try {
              const access_token = localStorage.getItem("lh_access_token");
              await axios.get('https://api.drop.fish/api-flow/get-mint-count?nft='+drop.nft_id+'&access_token='+access_token, config).then(function (response){
                  if(response.data.data && response.data.message == 'success' ){
                    if (response.data.data) {
                        if (response.data.data.serial_number != '...') {
                          getDrops();
                        }
                        else {
                            setTimeout(()=>{
                              checkUserDropsCounters();
                            },5000);
                        }
                    }
                  }
                  else if(response.data.message == 'invalid_token'){
                    props.getOAuthToken().then(res => {
                      checkUserDropsCounters();
                    });
                  }
              });
          } 
          catch (error) {
              console.error('Error fetching data:', error);
          }
      }
   };

    useEffect(() => {
      if(drop) {
        document.body.style.background = `url(${drop.bg_img}), #000 no-repeat`;
        checkUserDropsCounters();
      }
    },[drop]);

    useEffect(()=>{
      if(drop_text)
        getDrops();
      else
        navigate("/info",{state: '#1'});
    },[drop_text]);

    return(
      <>
      {(!isLoading)?(
        <Container maxWidth="xl" className=' min-h-[100vh]'>
          <DropNav navDetails={drop}/>
            <DropCompShare drop={drop}/>
            <div className='mb-12'></div>
        </Container>
      ):(
        ''
      )}

      </>
    )
}

export default UseLink;