import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import {ThemeProvider, Typography, Container} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import NavBar from "../components/nav/Nav";
import CreateCollectionCard from '../components/card/Create-col_card';
import RewardCard from '../components/card/Reward_card';
import moment, { utc } from 'moment';
import { ThemeCustom} from "../styles/Theme";
import { Link } from 'react-router-dom';
import LoadingModal from '../components/modal/Modal_loading';
import EndDropModal from '../components/modal/Modal_enddrop';

const MyRewards = (props) => {
    const user_id                       = localStorage.getItem('nift_user_id');
    const temp_user_id                  = localStorage.getItem('lh_trmp_user_id');
    const [mydrops, setMyDrops]         = useState([]);
    const navigate                      = useNavigate();
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const [endDrop, setEndDrop]         = useState();

    useEffect(()=>{
        if(user_id)
            myDrops(user_id,temp_user_id);
        else if(temp_user_id)
            myDrops(user_id,temp_user_id);
        
    },[user_id,temp_user_id]);

    useEffect(() => {
        localStorage.removeItem("lh_unique_csv");
        localStorage.removeItem("lh_setupform_data");
        localStorage.removeItem("lh_logo_image");
        localStorage.removeItem("lh_background_image");
        localStorage.removeItem("lh_emails_csv");
        localStorage.removeItem("lh_form_rewardImage");
        localStorage.removeItem("lh_bg_size");
        localStorage.removeItem("lh_criteriaform_data");
        localStorage.removeItem("lh_bg_name");
        localStorage.removeItem("lh_edit_drop");
        localStorage.removeItem("lh_display_time");
        localStorage.removeItem("lh_countdown_time");
        
    },[]);

    const myDrops = async (uid,temp_user_id) => {
        const access_token = localStorage.getItem("lh_access_token");
        //const url = 'http://localhost/lighthouseapi/api-flow/my-drops?user_id='+uid+'&temp_user_id='+temp_user_id+'&access_token='+access_token;
        const url = 'https://api.drop.fish/api-flow/my-drops?user_id='+uid+'&temp_user_id='+temp_user_id+'&access_token='+access_token;
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        };
        
        axios.get(url, config).then(function (response) {
            if(response.data.message == 'success'){
                if(response.data.data){
                    setMyDrops(response.data.data);
                }
            }
            else if(response.data.message == 'invalid_token'){
                props.getOAuthToken().then(res => {
                    myDrops(uid,temp_user_id);
                });
            }
        });
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
           <NavBar getOAuthToken={props.getOAuthToken}/>
           <EndDropModal setOpenWarning={setOpenWarning} openWarning={openWarning} endDrop={endDrop}/>  
           <LoadingModal openSuccess={openSuccess} setOpenSuccess={setOpenSuccess} mid={0}/>  
           <Container maxWidth="xl" className="mt-[8.25rem] sm:mt-[8.917rem] md:mt-[7.563rem] mb-10 text-white">
                    <>
                    <Typography component={'div'} variant='header48' align='left' className='py-10'>My Drops</Typography>
                    <Grid container  spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid xs={12} sm={4} lg={3} >
                            <Link to={'/reward'} className='no-underline'>
                                <CreateCollectionCard/>
                            </Link>
                        </Grid>
                        {
                            mydrops.map((drop, index) => (
                                <Grid xs={12} sm={4} lg={3} key={index}>
                                    <RewardCard drop={drop} setOpenSuccess={setOpenSuccess} setOpenWarning={setOpenWarning} setEndDrop={setEndDrop}/>
                                </Grid>
                            ))
                       }

                    </Grid>
                    </>
            </Container>        
        </ThemeProvider>   
    );
}

export default MyRewards;