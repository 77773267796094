import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import {Box, Typography, Modal} from '@mui/material';

import ModalStyle from './Modal_styles.module.scss';
import ButtonMain from '../button/Button_main';
import ButtonMainImg from '../button/Button_main_img';

import animIcon from '../../assets/loading.gif';
import Copy from '../../assets/copy.svg';

const LoadingModal = ({openSuccess = 1, setOpenSuccess,mid=0}) =>{

    const navigate = useNavigate();

    const messagesArr = [
        {1:'Please wait...'},
        {1:'Building your reward...', 2:'Making images pop…',3:'Winding the clock…'},
        {1:'Fetching images…', 2:'Unpacking your reward…',3:'Resetting the clock…'},
        {1:'Connecting to platforms…', 2:'Finding accounts…',3:'Indexing content…'},
        {1:'Deploying your drop…'}
    ];

    const [message, setMessage] = useState(messagesArr[mid][1]);

    useEffect(() => {
        const intervalIds = [];

        Object.entries(messagesArr[mid]).forEach(([key, value], index) => {
            const intervalId = setInterval(() => {
                setMessage(value); 
            }, (index + 1) * 1000);

            intervalIds.push(intervalId);
        });

        return () => {
            intervalIds.forEach(clearInterval);
        };
    }, [mid]); 

    return(
        <div>
            <Modal
                open={openSuccess > 0?true:false}
                aria-labelledby="modal-modal-title"
                id="SuccessModal"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-success']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={animIcon} alt='icon' className={ModalStyle['icon-img']}/>
                            </div>
                            <Typography id="modal-modal-title" className={[ModalStyle['modal-text24'], ModalStyle['modal-mb-4']]} align={'center'} variant="text24" component="h2">
                                {openSuccess!= 2 ? message:'Please wait...'}
                            </Typography>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default LoadingModal;